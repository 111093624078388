import { MjfNotificationService, NotificationData } from "@/types/localtypes";
import Vue from "vue";
let globalBus!: Vue;

export const mjfNotificationService: MjfNotificationService = {
  clearAll(): void {},
  close(id: number): void {
    globalBus.$emit("closeNotification", id);
  },
  error(config: NotificationData): number {
    config = { ...config };
    config.type = "error";
    config.icon = config.icon ? config.icon : "danger.svg";
    config.buttonText = config.buttonText ? config.buttonText : "Back";
    config.id = Math.random();
    globalBus.$emit("notify", config);
    return config.id;
  },
  info(config: NotificationData): number {
    config = { ...config };
    config.type = "info";
    config.icon = config.icon ? config.icon : "info.svg";
    config.buttonText = config.buttonText ? config.buttonText : "Got it";
    config.id = Math.random();
    globalBus.$emit("notify", config);
    return config.id;
  },
  success(config: NotificationData): number {
    config = { ...config };
    config.type = "success";
    config.icon = config.icon ? config.icon : "check-d.svg";
    config.buttonText = config.buttonText ? config.buttonText : "Got it";
    config.id = Math.random();
    globalBus.$emit("notify", config);
    return config.id;
  },
  warn(config: NotificationData): number {
    config = { ...config };
    config.type = "warn";
    config.icon = config.icon ? config.icon : "danger.svg";
    config.buttonText = config.buttonText ? config.buttonText : "Back";
    config.id = Math.random();
    globalBus.$emit("notify", config);
    return config.id;
  },
  popup(config: NotificationData): number {
    config = { ...config };
    config.type = "popup";
    config.icon = config.icon ? config.icon : "danger.svg";
    config.buttonText = config.buttonText ? config.buttonText : "Back";
    config.id = Math.random();
    globalBus.$emit("notify", config);
    return config.id;
  },
  _setGlobalBus(bus: Vue): void {
    globalBus = bus;
  },
};
