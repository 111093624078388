
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import DynamicImage from "@/components/common/DynamicImage.vue";
import { NotificationData } from "@/types/localtypes";

@Component({
  name: "Notification",
  components: { DynamicImage },
})
export default class Notification extends Vue {
  @Inject() readonly globalBus!: Vue;
  notifications: NotificationData[] = [];
  popups: NotificationData[] = [];
  created() {
    this.globalBus.$on("notify", this.notify);
    this.globalBus.$on("closeNotification", this.close);
  }

  cssClasses(data: NotificationData) {
    return `${data.css} ${data.type}`;
  }

  iconHeight(notificationData: NotificationData) {
    return notificationData.iconHeight ? notificationData.iconHeight : 90;
  }
  notify(data: NotificationData) {
    if (data.type === "popup") {
      this.popups = [{ ...data }];
    } else {
      if (!this.notifications) {
        this.notifications = [{ ...data }];
      } else {
        this.notifications.push({ ...data });
      }
    }
  }

  close(id: number | undefined) {
    this.notifications = this.notifications.filter(n => n.id !== id);
    this.popups = this.popups.filter(n => n.id !== id);
  }
}
