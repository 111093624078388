import { BillingAccount } from "@/types/localtypes";
import { BillingAccountsService } from "@/types/services/billingAccounts";
import {
  BillingAccountInfoResponse,
  BillingCreditCardInfoInfoResponse,
  InvoiceDTO,
  InvoiceListResponse,
  SubscriptionDTO,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "../util/api-proxy";

export const billingAccountsService: BillingAccountsService = {
  createFromSubscriptions: (subscriptions: SubscriptionDTO[]): BillingAccount[] => {
    const billingAccounts: BillingAccount[] = [];

    // Create an object of shape { "paymentAccountNumber" : SubscriptionDTO[] }
    // let aggregatedBillingAccountsObject = subscriptions
    //   .filter(subscription => subscription.paymentAccountNumber && subscription.paying)
    //   .reduce(
    //     (acc, subscription) => ({
    //       ...acc,
    //       [subscription.paymentAccountNumber]: [
    //         ...(acc[subscription.paymentAccountNumber] ? acc[subscription.paymentAccountNumber] : []),
    //         subscription,
    //       ],
    //     }),
    //     {} as { [key: string]: SubscriptionDTO[] },
    //   );
    const aggregatedBillingAccountsObject = subscriptions
      .filter(subscription => {
        if (subscription.paying || subscription.accountNumber) {
          return subscription.meta.isAzureMP ? !!subscription.paymentSubscription : !!subscription.paymentAccountNumber;
        }
        return false;
      })
      .reduce(
        (acc, subscription) => ({
          ...acc,
          [subscription.meta.isAzureMP ? subscription.paymentSubscription : subscription.paymentAccountNumber]: [
            ...(acc[subscription.meta.isAzureMP ? subscription.paymentSubscription : subscription.paymentAccountNumber]
              ? acc[subscription.meta.isAzureMP ? subscription.paymentSubscription : subscription.paymentAccountNumber]
              : []),
            subscription,
          ],
        }),
        {} as { [key: string]: SubscriptionDTO[] },
      );

    for (const paymentAccountNumber in aggregatedBillingAccountsObject) {
      if (aggregatedBillingAccountsObject.hasOwnProperty(paymentAccountNumber)) {
        const subscriptions = aggregatedBillingAccountsObject[paymentAccountNumber];
        billingAccounts.push({ paymentAccountNumber, subscriptions });
      }
    }
    return billingAccounts;
  },
  getInvoices: async (paymentAccountNumber, limitMonth): Promise<InvoiceDTO[]> => {
    let url = `billing-accounts/${paymentAccountNumber}/invoices`;
    if (limitMonth) {
      url += `?limitMonth=${limitMonth}`;
    }
    const response: InvoiceListResponse | null = await apiProxy.get(url);
    if (!response) {
      throw new Error("Could not load account invoices");
    }
    return response.invoiceList;
  },
  getInvoice: async (
    paymentAccountNumber: BillingAccount["paymentAccountNumber"],
    fileKey: InvoiceDTO["invoiceFileKey"],
    invoiceNumber: InvoiceDTO["invoiceNumber"],
  ): Promise<any> => {
    const fileName = invoiceNumber + ".pdf";
    const response: any | null = await apiProxy.get(
      `billing-accounts/${paymentAccountNumber}/invoices/${fileKey}?fileName=${fileName}`,
      {
        responseType: "blob",
      },
    );

    if (!response) {
      throw new Error("Could not load account invoice");
    }
    return response;
  },
  getBillingAccountInfo: async (paymentAccountNumber: string): Promise<BillingAccountInfoResponse> => {
    const response: BillingAccountInfoResponse | null = await apiProxy.get(
      `billing-accounts/${paymentAccountNumber}/accountInfo`,
    );
    if (!response) {
      throw new Error("Could not load billing account informations");
    }
    return response;
  },
  getCreditCardInfo: async (paymentAccountNumber: string): Promise<BillingCreditCardInfoInfoResponse> => {
    const response: BillingCreditCardInfoInfoResponse | null = await apiProxy.get(
      `billing-accounts/${paymentAccountNumber}/creditCardInfo`,
    );
    if (!response) {
      throw new Error("Could not load billing credit card informations");
    }
    return response;
  },
};
