import { messagesService } from "@/services/messages";
import store from "@/store/store";
import { FlatSubscription, Region, SubscriptionExplanation } from "@/types/localtypes";
import { MenuItemProps } from "@/types/services/menus";

import { SubscriptionsService } from "@/types/services/subscriptions";
import dateUtils from "@/util/dateUtils";
import {
  CancelSubscriptionRequest,
  ChangeProviderRequest,
  ChangeRegionRequest,
  DebtPaymentFlowRequest,
  DeploymentType,
  DowngradeSubscriptionRequest,
  EffectiveCancelSubscriptionRequest,
  EffectiveDowngradeSubscriptionRequest,
  EnableCdnRequest,
  GeoIpCountries,
  GetAQuoteRequest,
  IsSubscriptionUpgradableResponse,
  JpuDTO,
  LogShippingEnrollmentRequest,
  MfaCancelRequest,
  MfaOtpRequest,
  MfaStartEnrollmentResponse,
  MultipleStatisticsRequest,
  MultipleStatisticsResponse,
  PaymentType,
  PricesTiersRequest,
  PricesTiersResponse,
  ProductActivationRequest,
  QuickGetAQuoteRequest,
  QuickPurchaseCloudRequest,
  QuickPurchaseResponse,
  ReadServerIPMetaResponse,
  ReadServerStatusResponse,
  RegionDTO,
  RegionsListResponse,
  SaasPaymentType,
  StatisticsRequest,
  StatisticsResponse,
  SubscriptionActionRequest,
  SubscriptionDTO,
  SubscriptionMeta,
  SubscriptionResponse,
  SubscriptionsResponse,
  SubscriptionTypes,
  TryXrayRequest,
  UpgradeSubscriptionRequest,
  WhiteListIPSResponse,
  RatePlanSubscription,
} from "@jfrog-ba/myjfrog-common";
import apiProxy from "../util/api-proxy";
import marketoCookie from "../util/marketo-cookie";
import { PaymentTypeBaseName, paymentTypesMetas } from "./subscriptionsMeta";

export const subscriptionsService: SubscriptionsService = {
  purchaseCloud: async (purchaseForm, subscription, newPaymentType, isPipelinesPurchased): Promise<SubscriptionDTO> => {
    const response: SubscriptionResponse | null = await apiProxy.post("subscriptions/purchaseCloud", {
      purchaseForm,
      accountNumber: subscription.accountNumber,
      newPaymentType,
      isPipelinesPurchased,
      serverName: subscription.serverName,
      provider: subscription.cloudProviderCode,
      region: subscription.region,
      marketoCookie: marketoCookie(),
    });

    if (!response) {
      throw new Error("Could not finish purchase process");
    }

    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  quickPurchaseCloud: async (request: QuickPurchaseCloudRequest): Promise<QuickPurchaseResponse> => {
    const response: QuickPurchaseResponse | null = await apiProxy.post("user/quickPurchaseCloud", request);
    if (!response) {
      throw new Error("Could not get quick purchase response");
    }
    return response;
  },
  quickPurchaseCloudPromotion: async (request: QuickPurchaseCloudRequest): Promise<QuickPurchaseResponse> => {
    const response: QuickPurchaseResponse | null = await apiProxy.post("user/quickPurchaseCloudPromotion", request);
    if (!response) {
      throw new Error("Could not get quick purchase promotion response");
    }
    return response;
  },
  updateProducts: async (accountNumber, effectiveDate, optionalProductsToAdd, optionalProductsToRemove, reason) => {
    const response: SubscriptionResponse | null = await apiProxy.post("subscriptions/updateProducts", {
      accountNumber,
      effectiveDate,
      optionalProductsToAdd,
      optionalProductsToRemove,
      reason,
      marketoCookie: marketoCookie(),
    });

    if (!response) {
      throw new Error("Could not finish adding product process");
    }

    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  upgradeSubscription: async (accountNumber, oldPaymentType, newPaymentType, serverName) => {
    const request: UpgradeSubscriptionRequest = {
      accountNumber,
      oldPaymentType,
      newPaymentType,
      serverName,
      marketoCookie: marketoCookie(),
    };

    const response: SubscriptionResponse | null = await apiProxy.post("subscriptions/upgrade/", request);
    if (!response) {
      throw new Error("Could not finish upgrade process");
    }

    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  tryXray: async (accountNumber: number, serverName: string): Promise<SubscriptionDTO> => {
    const request: TryXrayRequest = { accountNumber, serverName, marketoCookie: marketoCookie() };

    const response: SubscriptionResponse | null = await apiProxy.post("subscriptions/tryXray/", request);
    if (!response) {
      throw new Error("Could not finish add trial process");
    }

    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  getStatistics: async (statisticsRequest: StatisticsRequest): Promise<StatisticsResponse> => {
    const response: StatisticsResponse | null = await apiProxy.post("subscriptions/statistics", {
      statisticsRequest,
    });
    if (!response) {
      throw new Error("Could not load statistics");
    }
    return response;
  },
  getMultipleStatistics: async (
    multipleStatisticsRequest: MultipleStatisticsRequest,
  ): Promise<MultipleStatisticsResponse> => {
    const response: MultipleStatisticsResponse | null = await apiProxy.post("subscriptions/multipleStatistics", {
      multipleStatisticsRequest,
    });
    if (!response) {
      throw new Error("Could not load statistics");
    }
    return response;
  },
  getSubscriptionTypes: async (accountNumber: number): Promise<SubscriptionTypes[]> => {
    const response: SubscriptionTypes[] | null = await apiProxy.get(`subscriptions/${accountNumber}/types`);
    if (!response) {
      throw new Error("can't load subscriptions");
    }
    return response;
  },

  getPricesTiers: async (pricesTiersRequest: PricesTiersRequest): Promise<PricesTiersResponse> => {
    const response: PricesTiersResponse | null = await apiProxy.post(`subscriptions/pricesTiers`, pricesTiersRequest);
    if (!response) {
      throw new Error("can't load prices tiers");
    }
    return response;
  },

  getRegionsList: async (): Promise<Region[]> => {
    const response: RegionsListResponse | null = await apiProxy.get("subscriptions/regionsList");
    if (!response) {
      throw new Error("Could not load regions list");
    }
    return transformRegions(response.regions);
  },

  getWhiteListIPs: async (accountNumber, serverName) => {
    const whiteListIPSResponse: WhiteListIPSResponse | null = await apiProxy.get(
      `subscriptions/${accountNumber}/servers/${serverName}/whiteListIPs`,
    );
    if (!whiteListIPSResponse) {
      throw new Error("Could not load Whitelist IPs");
    }
    return whiteListIPSResponse;
  },

  getGeoRestrictionCountries: async (accountNumber, servername) => {
    const geoRestrictionCountries: GeoIpCountries | null = await apiProxy.get(
      `subscriptions/${accountNumber}/servers/${servername}/geoipCountries`,
    );
    if (!geoRestrictionCountries) {
      throw new Error("Could not load Georestriction Countries");
    }
    geoRestrictionCountries["geoIpRestrictionCountriesList"] =
      geoRestrictionCountries.geoIpRestrictionCountriesList || [];
    geoRestrictionCountries["geoIpRestrictionType"] = geoRestrictionCountries.geoIpRestrictionType || "deny";
    return geoRestrictionCountries;
  },

  getServersIpMetaData: async (accountNumber, request) => {
    const response: ReadServerIPMetaResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/getServersMetaData`,
      request,
    );
    if (!response) {
      throw new Error("Could not load server Ip Meta data");
    }
    for (const responseKey in response) {
      response[responseKey].geoIPMeta["geoIpRestrictionCountriesList"] =
        response[responseKey].geoIPMeta.geoIpRestrictionCountriesList || [];
      response[responseKey].geoIPMeta["geoIpRestrictionType"] =
        response[responseKey].geoIPMeta.geoIpRestrictionType || "deny";
    }
    return response;
  },

  updateGeoIPCountries: async (accountNumber, serverName, request): Promise<GeoIpCountries> => {
    const geoIpCountriesResponse: GeoIpCountries | null = await apiProxy.put(
      `subscriptions/${accountNumber}/servers/${serverName}/geoIpCountries`,
      request,
    );
    if (!geoIpCountriesResponse) {
      throw new Error("Could not update geoIp Countries");
    }
    return geoIpCountriesResponse;
  },

  updateWhiteListIPs: async (accountNumber, serverName, request): Promise<WhiteListIPSResponse> => {
    const whiteListIPSResponse: WhiteListIPSResponse | null = await apiProxy.put(
      `subscriptions/${accountNumber}/servers/${serverName}/whiteListIPs`,
      request,
    );
    if (!whiteListIPSResponse) {
      throw new Error("Could not update Whitelist IPs");
    }
    return whiteListIPSResponse;
  },

  manageEndpoint: async (accountNumber, request) => {
    const manageEndpointResponse: boolean | null = await apiProxy.post(
      `subscriptions/${accountNumber}/managePrivateEndpoint`,
      request,
    );
    if (!manageEndpointResponse) {
      throw new Error("Could not update Endpoint");
    }
    return manageEndpointResponse;
  },

  enableCdn: async (request: EnableCdnRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/enableCdn`, request);
  },

  getSubscriptions: async (): Promise<SubscriptionDTO[]> => {
    const cachedSubscriptions = store.getters["subscriptions/subscriptions"];
    // subscriptions already loaded in Vuex
    if (cachedSubscriptions) {
      return Promise.resolve(cachedSubscriptions);
    } else {
      let fetchedSubscriptions = await subscriptionsService.fetchSubscriptions();
      fetchedSubscriptions = fetchedSubscriptions
        .filter(s => subscriptionsService.isSaas(s))
        .map(s => ({ ...s, ...{ meta: getSubscriptionMeta(s) } }));
      await store.dispatch("subscriptions/setSubscriptions", fetchedSubscriptions);
      return Promise.resolve(fetchedSubscriptions);
    }
  },
  getSubscription: async (accountNumber: SubscriptionDTO["accountNumber"]): Promise<SubscriptionDTO> => {
    const subscription = findSubscriptionByAccountNumber(accountNumber, await subscriptionsService.getSubscriptions());
    if (subscription) {
      subscription.meta = getSubscriptionMeta(subscription);
      return Promise.resolve(subscription);
    }
    return Promise.reject(`Unable to find a subscription with account number '${accountNumber}'.`);
  },

  isSubscriptionUpgradable: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    targetPaymentType: PaymentType,
  ): Promise<boolean> => {
    const response: IsSubscriptionUpgradableResponse | null = await apiProxy.get(
      `subscriptions/${accountNumber}/isUpgradable/${targetPaymentType}`,
    );
    if (!response) {
      throw new Error(`Unable to know if the subscription '${accountNumber}' is upgradable to '${targetPaymentType}'.`);
    }
    return response.upgradable;
  },
  fetchSubscriptions: async (): Promise<SubscriptionDTO[]> => {
    const response: SubscriptionsResponse | null = await apiProxy.get("subscriptions");
    if (!response) {
      throw new Error("Could not load subscriptions");
    }
    return response.subscriptions;
  },
  fetchSubscription: async (accountNumber: SubscriptionDTO["accountNumber"]): Promise<SubscriptionDTO> => {
    const response: SubscriptionResponse | null = await apiProxy.get(`subscriptions/${accountNumber}`);
    if (!response) {
      throw new Error(`Could not load subscription ${accountNumber}`);
    }
    return response.subscription;
  },
  cancelSubscription: async (
    accountNumber: SubscriptionDTO["accountNumber"],
    serverName: JpuDTO["serverName"],
    isJCR: boolean,
    reason: string,
  ): Promise<SubscriptionDTO> => {
    const request: EffectiveCancelSubscriptionRequest = {
      reason,
      serverName,
      isJCR,
      marketoCookie: marketoCookie() || null,
    };
    const response: SubscriptionResponse | null = await apiProxy.post(`subscriptions/${accountNumber}/cancel`, request);
    if (!response) {
      throw new Error(`Could not cancel subscription '${accountNumber}'`);
    }
    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  downgradeSubscription: async (accountNumber, reason, newPaymentType): Promise<SubscriptionDTO> => {
    const request: EffectiveDowngradeSubscriptionRequest = {
      reason,
      newPaymentType,
    };
    const response: SubscriptionResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/downgrade`,
      request,
    );
    if (!response) {
      throw new Error(`Could not downgrade subscription '${accountNumber}'`);
    }
    return { ...response.subscription, meta: getSubscriptionMeta(response.subscription) };
  },
  sendDowngradeRequest: async (serverName: string): Promise<null> => {
    return await apiProxy.post(`subscriptions/downgrade/${serverName}`, {
      marketoCookie: marketoCookie(),
    } as DowngradeSubscriptionRequest);
  },

  sendCancellationRequest: async (serverName: string): Promise<null> => {
    return await apiProxy.post(`subscriptions/cancel/${serverName}`, {
      marketoCookie: marketoCookie(),
    } as CancelSubscriptionRequest);
  },

  changeProvider: async (changeProviderRequest: ChangeProviderRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/changeProvider`, changeProviderRequest);
  },
  changeRegion: async (changeRegionRequest: ChangeRegionRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/changeRegion`, changeRegionRequest);
  },

  sendSubscriptionActionRequest: async (request: SubscriptionActionRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/requestAction`, request);
  },

  sendGetAQuote: async (request: GetAQuoteRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/getaquote`, request);
  },
  sendQuickGetAQuote: async (request: QuickGetAQuoteRequest): Promise<null> => {
    return await apiProxy.post(`user/quickgetaquote`, request);
  },

  transformSubscriptions: (subscriptions: SubscriptionDTO[]) => {
    return subscriptions.map((subscription: SubscriptionDTO) => {
      return subscriptionsService.transformSubscription(subscription);
    });
  },

  transformSubscription: (subscription: SubscriptionDTO) => {
    const jpu = subscriptionsService.extractMainServer(subscription.jpus, subscription.paymentType);
    const meta = getSubscriptionMeta(subscription);
    const localSubscription: FlatSubscription = {
      creationDate: jpu.creationDate,
      displayName: meta.displayName || "N/A",
      accountNumber: subscription.accountNumber,
      paymentType: subscription.paymentType,
      serverName: jpu.serverName,
      technicalServerName: jpu.technicalServerName,
      cloudProviderCode: jpu.cloudProvider,
      cloudProvider: subscriptionsService.extractProviderName(jpu.cloudProvider),
      region: jpu.region,
      status: extractStatus(subscription, meta),
      isTrial: subscription.trial,
      isPaying: subscription.paying,
      endOfTrial: subscription.endOfTrial,
      eligibleToChangePlan: subscription.eligibleToChangePlan,
      xrayAvailableInRegion: subscription.xrayAvailableInRegion,
      paymentProvider: subscription.paymentProvider,
      jpus: subscription.jpus.map(jpu => ({
        ...jpu,
        region: jpu.region,
      })),
      nbEdgesConfigurable: subscription.nbEdgesConfigurable,
      nbInstanceConfigurable: subscription.nbInstanceConfigurable,
      meta: meta,
      isEnrolledForLogShipping: subscription.enrolledForLogShipping,
      hasBusinessSecurity: subscription.hasBusinessSecurity,
    };
    return localSubscription;
  },

  getIncludedServices: (meta: SubscriptionMeta) => {
    const servicesArr: string[] = [];
    if (meta.hasXrayPremium) {
      servicesArr.push("Security Pack");
    } else if (meta.hasXray && !meta.isXrayBlocked) {
      servicesArr.push("Xray");
    }
    if (meta.hasPipelines && !meta.isPipelinesBlocked) {
      servicesArr.push("Pipelines");
    }
    const displayName = meta.displayName || "N/A";
    if (displayName.trim() == "Enterprise+ Distribution Edges") {
      servicesArr.push("Artifactory");
    }
    return servicesArr;
  },

  getIncludedServicesLabel: (meta: SubscriptionMeta) => {
    const servicesArr: string[] = subscriptionsService.getIncludedServices(meta);

    if (!servicesArr.length) {
      return "-";
    }

    if (servicesArr.length === 1) {
      return servicesArr[0];
    }
    // building services part like "A, B, C and D"
    const last = servicesArr.pop();
    return servicesArr.join(", ") + ` and ${last}`;
  },

  extractProviderName: (provider: JpuDTO["cloudProvider"]): FlatSubscription["cloudProvider"] => {
    switch (provider) {
      case "amazon":
        return "Amazon Web Services";
      case "google":
        return "Google Cloud Platform";
      case "azure":
        return "Microsoft Azure";
    }
    return null;
  },

  isBuyActionVisible: meta => {
    return !meta.isOSS && !!meta.isTrial && !meta.isMP;
  },
  isBuyPageReachable: meta => {
    return (
      subscriptionsService.isBuyActionVisible(meta) && !subscriptionsService.getBuyPageNotReachableExplanation(meta)
    );
  },
  getBuyPageNotReachableExplanation: meta => {
    if (meta.isEnterprisePlus) {
      return {
        message: messagesService.subscriptions_buy_error_is_enterprise_NEW,
        reason: "want_enterprise_plus",
      };
    }
    return null;
  },
  getIsUnableToAddPipelinesExplanation: meta => {
    return null;
  },
  getBuyNotProcessableExplanation: meta => {
    if (!meta.isXrayAvailableInRegion) {
      return {
        message: messagesService.subscription_buy_provider_not_supported_by_xray_NEW,
        reason: "region_not_supported",
      };
    }
    return null;
  },
  isUpgradeActionVisible: meta => {
    return !meta.isTrial && !meta.isMP && !meta.isInternal;
  },
  isUpgradePageDisabled: meta => {
    return !meta.isFree && !meta.isActive;
  },
  isUpgradePageReachable: meta => {
    return (
      (subscriptionsService.isUpgradeActionVisible(meta) &&
        !subscriptionsService.getUpgradePageNotReachableExplanation(meta)) ||
      false
    );
  },
  getUpgradePageNotReachableExplanation: meta => {
    if (meta.isEnterprisePlus) {
      return {
        message: messagesService.subscriptions_upgrade_error_is_enterprise_plus_NEW,
        reason: "from_enterprise_plus",
      };
    }
    if (meta.isOSS) {
      return { message: messagesService.subscriptions_upgrade_error_is_OSS_NEW, reason: "is_OSS" };
    }
    return null;
  },

  getUpgradePageNotProcessableExplanation: meta => {
    if (meta.isPrepaidPayment) {
      return {
        message: messagesService.subscriptions_upgrade_error_is_prepaid_NEW,
        reason: "account_is_prepaid",
      };
    }
    if (!meta.isXrayAvailableInRegion) {
      return {
        message: messagesService.subscription_upgrade_provider_not_supported_by_xray_NEW,
        reason: "region_not_supported",
      };
    }
    return null;
  },

  isAddXrayPageReachable: meta =>
    false && // currently all subscriptions should not be able to add optional products
    meta.isActive &&
    !meta.hasXray &&
    !meta.isTrial &&
    !meta.isOSS &&
    !meta.isInternal &&
    !meta.isMP &&
    !meta.isFree &&
    !meta.isProTeam &&
    !meta.isEnterpriseTeam,

  isAddPipelinesPageReachable: meta =>
    false && // currently all subscriptions should not be able to add optional products
    meta.isActive &&
    (!meta.hasPipelines || meta.isPipelinesBlocked) &&
    !meta.isTrial &&
    !meta.isOSS &&
    !meta.isInternal &&
    !meta.isMP &&
    !meta.isJCR &&
    meta.isJfrogUnified &&
    meta.isPipelinesAvailableInRegion &&
    !meta.isEnterprisePlus &&
    !meta.isEnterprisePlusTrial &&
    !meta.isFree &&
    !meta.isProTeam &&
    !meta.isEnterpriseTeam,
  isAddSecurityPremiumPageReachable: meta =>
    !!meta.isActive &&
    !meta.isTrial &&
    !meta.isOSS &&
    !meta.isInternal &&
    !meta.isMP &&
    !meta.isJCR &&
    !!meta.isJfrogUnified &&
    !meta.isEnterprisePlus &&
    !meta.hasXrayPremium &&
    (!!meta.isFree || !!meta.isProTeam || !!meta.isEnterpriseTeam),
  isXrayNewForCustomer: (subscriptionMetas: SubscriptionMeta[]) => {
    // return true;
    // no subscriptions with xray in the account && minimum 1 subscription is not : trial, OSS, Internal or MP
    const subscriptionsWithXray = subscriptionMetas.filter(meta => meta.hasXray).length;
    if (subscriptionsWithXray) {
      return false;
    }
    return !!subscriptionMetas.filter(meta => subscriptionsService.isAddXrayPageReachable(meta)).length;
  },
  getSubscriptionRequestReason: (menuId: MenuItemProps["id"], meta: SubscriptionMeta) => {
    let reason: SubscriptionActionRequest["reason"] = "unknown_reason";
    let notReachableExplanation: SubscriptionExplanation | null;
    let notProcessableExplanation: SubscriptionExplanation | null;
    switch (menuId) {
      case "buy":
        notReachableExplanation = subscriptionsService.getBuyPageNotReachableExplanation(meta);
        if (notReachableExplanation) {
          reason = notReachableExplanation.reason;
          break;
        }
        notProcessableExplanation = subscriptionsService.getBuyNotProcessableExplanation(meta);
        if (notProcessableExplanation) {
          reason = notProcessableExplanation.reason;
        }
        break;
      case "upgrade":
        notReachableExplanation = subscriptionsService.getUpgradePageNotReachableExplanation(meta);
        if (notReachableExplanation) {
          reason = notReachableExplanation.reason;
          break;
        }
        notProcessableExplanation = subscriptionsService.getUpgradePageNotProcessableExplanation(meta);
        if (notProcessableExplanation) {
          reason = notProcessableExplanation.reason;
        }
        break;
    }
    return reason;
  },
  getCertificates: async (accountNumber: SubscriptionDTO["accountNumber"]): Promise<any> => {
    const response: any | null = await apiProxy.get(`subscriptions/certificates/${accountNumber}`, {
      responseType: "blob",
    });

    if (!response) {
      throw new Error("Could not load certificates file");
    }
    return response;
  },
  extractMainServer: (jpus: JpuDTO[], paymentType: PaymentType): JpuDTO => {
    if (jpus.length > 1 && paymentType !== "ENTERPRISE_PLUS_HYBRID") {
      const jpu = jpus.find(jpu => {
        return jpu.isMothership || jpu.products.find(p => p.productName === "jfmc");
      });
      if (!jpu) {
        throw new Error("Couldn't find main server with jfmc");
      }
      return jpu;
    }
    return jpus[0];
  },
  startMfaEnrollment: async (accountNumber: SubscriptionDTO["accountNumber"]): Promise<MfaStartEnrollmentResponse> => {
    const response: MfaStartEnrollmentResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/mfa/enrollment/start`,
      {},
    );
    if (!response) {
      throw new Error("Could not start enrollment");
    }
    return response;
  },

  completeMfaEnrollment: async (accountNumber: SubscriptionDTO["accountNumber"], request: MfaOtpRequest) => {
    return await apiProxy.post(`subscriptions/${accountNumber}/mfa/enrollment/complete`, request);
  },
  cancelMfaEnrollment: async (request: MfaCancelRequest) => {
    return await apiProxy.post(`subscriptions/mfa/enrollment/cancel`, request);
  },

  getServersStatus: async (accountNumber, request) => {
    const response: ReadServerStatusResponse | null = await apiProxy.post(
      `subscriptions/${accountNumber}/getServersStatus`,
      request,
    );
    if (!response) {
      throw new Error("Could not load servers status");
    }
    return response;
  },

  addServersToTopology: async request => {
    const response: SubscriptionResponse | null = await apiProxy.post(`subscriptions/topology/addServers`, request);
    if (!response) {
      throw new Error(`Could not add servers to topology`);
    }
    const subscription = response.subscription;
    return { ...subscription, meta: getSubscriptionMeta(subscription) };
  },

  logShippingEnrollment: async (request: LogShippingEnrollmentRequest): Promise<null> => {
    return await apiProxy.post(`subscriptions/logShippingEnrollment`, request);
  },

  productActivation: async (request: ProductActivationRequest): Promise<QuickPurchaseResponse> => {
    const response: QuickPurchaseResponse | null = await apiProxy.post("user/productActivation", request);
    if (!response) {
      throw new Error("Could not get quick purchase response");
    }
    return response;
  },
  debtPaymentFlow: async (request: DebtPaymentFlowRequest): Promise<void> => {
    const response: QuickPurchaseResponse | null = await apiProxy.post("subscriptions/debtPaymentFlow", request);
    if (!response) {
      throw new Error("Error on debtPaymentFlow call");
    }
  },
  isSelfHosted: subscription => {
    const registrationGroups = subscription.registrationGroups;
    if (!registrationGroups.length) {
      return subscription.paymentType === "BOM_BASED";
    }
    return registrationGroups[0].deploymentType === DeploymentType.SELF_HOSTED;
  },
  isSaas: subscription => {
    return !subscriptionsService.isSelfHosted(subscription);
  },
  getRatePlanTiers: async (ratePlanIds: Array<string>): Promise<Array<RatePlanSubscription>> => {
    const response: Array<RatePlanSubscription> | null = await apiProxy.post(`registrations/getRatePlanTiers`, {
      ratePlanIds,
    });
    if (!response) {
      throw new Error("Could not get ratePlan tiers");
    }
    return response;
  },
};

const getSubscriptionMeta = (subscription: SubscriptionDTO): SubscriptionDTO["meta"] => {
  const jpus = subscription.jpus;
  const jpu = subscriptionsService.extractMainServer(jpus, subscription.paymentType);
  const metas = paymentTypesMetas[subscription.paymentType as SaasPaymentType];
  if (!metas) {
    throw new Error("Subscription PaymentType is unknown: " + subscription.paymentType);
  }
  const isTrialXray =
    jpus.filter(
      jpu =>
        jpu.products.filter(
          product =>
            product.productName === "xray" &&
            product.trial &&
            (product.status === "ACTIVE" || product.status === "NEW" || product.status === "FAILED"),
        ).length > 0,
    ).length > 0;

  const hasActiveXray =
    jpus.filter(
      jpu =>
        jpu.products.filter(
          product =>
            product.productName === "xray" &&
            (product.status === "ACTIVE" || product.status === "NEW" || product.status === "FAILED"),
        ).length > 0,
    ).length > 0;

  const isTrialPipelines =
    jpus.filter(
      jpu =>
        jpu.products.filter(
          product =>
            product.productName === "pipelines" &&
            product.trial &&
            (product.status === "ACTIVE" || product.status === "NEW" || product.status === "FAILED"),
        ).length > 0,
    ).length > 0;

  const isXrayBlocked =
    jpus.filter(jpu => jpu.products.some(({ productName, status }) => productName === "xray" && status === "BLOCKED"))
      .length > 0;

  const isPipelinesBlocked =
    jpus.filter(jpu =>
      jpu.products.some(({ productName, status }) => productName === "pipelines" && status === "BLOCKED"),
    ).length > 0;

  const hasPipelines = !!jpus.find(jpu => jpu.products.some(product => product.productName === "pipelines"));

  const isEligibleToAddXrayTrial = !!jpus.find(jpu => jpu.eligibleToAddXrayTrial);
  const isCloudPro = metas.isCloudPro && !isTrialXray;
  const isCloudProX = metas.isCloudProX || (metas.isCloudPro && isTrialXray);
  const isEnterprise = metas.isEnterprise && !isTrialXray;
  const isEnterpriseX = metas.isEnterpriseX || (metas.isEnterprise && isTrialXray);

  const hasXray = metas.hasXray || hasActiveXray || isTrialXray;
  const isActive = subscription.state === "ACTIVE";

  const computeDisplayName = () => {
    const baseName = metas.baseName;
    const xSuffix = hasXray && doesBaseNameCanHaveX(baseName) ? "X" : "";
    const trialSuffix = metas.isTrial || isTrialXray ? "(Trial)" : "";

    return [baseName, xSuffix, trialSuffix].join(" ");
  };

  const hasCdnEnabled = !!jpus.find(jpu => jpu.cdnEnabled);
  const isEligibleToEnableCdn = () => {
    const compatibleSubscriptionType = !metas.isJCR && !metas.isOSS;
    const compatibleTopology = !!jpus.find(jpu => jpu.eligibleToEnableCdn); // at least one server is eligible
    return compatibleSubscriptionType && compatibleTopology;
  };

  return {
    ...metas,
    isActive,
    isTrialXray,
    isEligibleToAddXrayTrial,
    isTrialPipelines,
    isXrayBlocked,
    isPipelinesBlocked,
    isXrayAvailableInRegion: subscription.xrayAvailableInRegion,
    isEligibleToChangePlan: subscription.eligibleToChangePlan,
    isK8s: jpu.k8s,
    isExpiredTrial: metas.isTrial && dateUtils.isBefore(subscription.endOfTrial, new Date(), "day"),
    isEnterprisePlusTrial: metas.isEnterprisePlus && metas.isTrial,
    displayName: renderDisplayName(metas.paymentTypeName, computeDisplayName()),
    isCloudPro,
    isCloudProX,
    isEnterprise,
    isEnterpriseX,
    hasXray,
    hasPipelines,
    isPipelinesAvailableInRegion: subscription.pipelinesAvailableInRegion,
    isJfrogUnified: jpu.jfrogUnified,
    hasCdnEnabled,
    isEligibleToEnableCdn: isEligibleToEnableCdn(),
  };
};

const doesBaseNameCanHaveX = (baseName: PaymentTypeBaseName): boolean => {
  return baseName === PaymentTypeBaseName.CLOUD_PRO;
};

const renderDisplayName = (paymentTypeName: string, displayName: string): string => {
  if (process.env.VUE_APP_DISPLAY_PAYMENT_TYPE_NAME && process.env.VUE_APP_DISPLAY_PAYMENT_TYPE_NAME === "true") {
    return `${paymentTypeName} - ${displayName}`;
  }
  return displayName;
};

const findSubscriptionByAccountNumber = (
  accountNumber: SubscriptionDTO["accountNumber"],
  subscriptions: SubscriptionDTO[],
): SubscriptionDTO | null => {
  const matchedSubscription = subscriptions.find(subscription => {
    return subscription.accountNumber === accountNumber;
  });
  return matchedSubscription || null;
};

const transformRegions = (regions: RegionDTO[]): Region[] => {
  return regions.map(region => {
    return { ...region, jpuProviderCode: extractJPUProviderCodeFromRegion(region.providerCode) };
  });
};

const extractJPUProviderCodeFromRegion = (regionProviderCode: RegionDTO["providerCode"]): JpuDTO["cloudProvider"] => {
  switch (regionProviderCode) {
    case "AWS":
      return "amazon";
    case "AZURE":
      return "azure";
    case "GCP":
      return "google";
  }
};

const extractStatus = (subscription: SubscriptionDTO, meta: SubscriptionMeta): FlatSubscription["status"] => {
  if (meta.isExpiredTrial) {
    return "Expired";
  }
  const jpu = subscriptionsService.extractMainServer(subscription.jpus, subscription.paymentType);
  if (subscription.state === "ACTIVE" && (jpu.status === "NEW" || jpu.status === "FAILED")) {
    return "In Progress";
  }

  return subscription.state === "ACTIVE" ? "Active" : "Cancelled";
};
