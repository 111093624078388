
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "CloudActivationBackground",
  components: {},
})
export default class CloudActivationBackground extends Vue {
  @Prop({ default: false }) private hasConnect!: boolean;
  bgImagePath = require("@/assets/images/platform_login_side.svg");

  get logoImage() {
    return this.$jfImages.get("platform_login_logo.svg");
  }

  get logoStyles() {
    const windowHeight = window.innerHeight;
    const height = this.isMobile ? (windowHeight * 20) / 100 : this.isTablet ? 180 : 210;
    return {
      height: `${height}px`,
    };
  }

  get backgroundStyles() {
    if (this.hasConnect) {
      return {
        background: `linear-gradient(168.88deg, #1945AF 6.39%, #051A40 51.44%)`,
      };
    }
    return this.isMobile
      ? {} // defined as CSS rules
      : {
          backgroundImage: `url("${this.bgImagePath}")`,
          backgroundPosition: "50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        };
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }
}
