
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import MenuItem from "@/components/layout/MenuItem.vue";
import { MenuConfiguration, MenuItemProps } from "@/types/services/menus";
import Footer from "@/components/layout/Footer.vue";

@Component({
  name: "MobileMenu",
  components: { MenuItem, Footer },
})
export default class MobileMenu extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("toggleMobileMenu", { namespace: "application" })
  toggleMobileMenu!: (isOpen: boolean) => void;
  @Getter("paymentAccountNumbers", { namespace: "user" })
  paymentAccountNumbers!: string[];
  @Getter("menuConfiguration", { namespace: "application" })
  menuConfiguration!: MenuConfiguration;
  menuItems: MenuItemProps[] = [];

  @Watch("paymentAccountNumbers")
  onUserChanged() {
    this.loadMenuItems();
  }
  @Watch("menuConfiguration")
  onMenuConfigurationChanged() {
    this.loadMenuItems();
  }
  onMenuClick(menu: MenuItemProps) {
    if (!menu.disabled) {
      this.toggleMobileMenu(false);
    }
  }
  closeMobileMenu() {
    this.toggleMobileMenu(false);
  }

  busEvents: { [key: string]: (...params: any[]) => any } = {
    shouldCloseMobileMenu: this.closeMobileMenu,
  };

  created() {
    for (let busEventsKey in this.busEvents) {
      this.globalBus.$on(busEventsKey, this.busEvents[busEventsKey]);
    }
  }
  beforeDestroy() {
    for (let busEventsKey in this.busEvents) {
      this.globalBus.$off(busEventsKey, this.busEvents[busEventsKey]);
    }
  }

  async mounted() {
    this.loadMenuItems();
  }

  async loadMenuItems() {
    const mainMenusItems = await this.$jfMenus.getMainMenu(this.menuConfiguration);
    this.menuItems = mainMenusItems.concat(this.$jfMenus.getActionMenu(this.menuConfiguration));
  }
}
