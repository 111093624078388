
import { MyJFrogTheme } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";

interface FooterLink {
  label: string;
  href: string;
  target: "_blank" | "_self";
  dataGtmEventTitle?: string;
}

declare const VERSION: string;

@Component({
  name: "Footer",
  components: {},
})
export default class Footer extends Vue {
  @Prop() private onClick!: () => any;
  @Prop({ default: "DEFAULT" })
  private mainTheme!: MyJFrogTheme;
  @Prop({ default: "in-footer" })
  private displayedIn!: "in-footer" | "in-mobile-menu" | "in-mobile-loggedout-pages";
  leftFooterLinks: FooterLink[] = [
    {
      label: "Terms Of Use",
      href: "https://jfrog.com/myjfrog-tou/",
      target: "_blank",
      dataGtmEventTitle: "footer|terms-of-use-link",
    },
    {
      label: "Cookies Policy",
      href: "https://jfrog.com/jfrog-cookies-policy/",
      target: "_blank",
      dataGtmEventTitle: "footer|cookies-policy-link",
    },
    {
      label: "Privacy Policy",
      href: "https://jfrog.com/privacy-policy/",
      target: "_blank",
      dataGtmEventTitle: "footer|privacy-policy-link",
    },
    {
      label: "JFrog Website",
      href: "https://jfrog.com/",
      target: "_blank",
      dataGtmEventTitle: "footer|jfrog-website-link",
    },
    {
      label: "Support Portal",
      href: "https://support.jfrog.com/",
      target: "_blank",
      dataGtmEventTitle: "footer|support-portal-link",
    },
    {
      label: "Contact Us",
      href: "https://jfrog.com/contact-us/",
      target: "_blank",
      dataGtmEventTitle: "footer|contact-us-link",
    },
    {
      label: "Documentation",
      href: "https://www.jfrog.com/confluence/display/JFROG/MyJFrog+Cloud+Portal/",
      target: "_blank",
      dataGtmEventTitle: "footer|myjfrog-documentation-link",
    },
  ];
  rightFooterLinks: FooterLink[] = [];

  version = VERSION;

  get classes() {
    return [this.$mq, this.displayedIn, `theme-${this.mainTheme.toLowerCase()}`];
  }

  onFooterLinkClick(footerLink: FooterLink) {
    if (this.onClick) {
      this.onClick();
    }
  }

  get year() {
    return new Date().getFullYear();
  }

  get isMobile() {
    return this.$mq === "mobile";
  }
  get isTablet() {
    return this.$mq === "tablet";
  }

  get displayVersion() {
    return !this.isMobile && !this.isTablet;
  }
}
