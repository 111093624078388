
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { JFCheckBox, JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { jumpErrorsMapping, MPUnifiedRegistrationRequest } from "@jfrog-ba/myjfrog-common";
import DynamicImage from "@/components/common/DynamicImage.vue";

@Component({
  name: "MPUnifiedServerToken",
  $_veeValidate: { validator: "new" },
  components: { DynamicImage, JFCheckBox, JFFormControl, JFTextField },
})
export default class MPUnifiedServerToken extends Vue {
  @Inject() readonly globalBus!: Vue;
  data: MPUnifiedRegistrationRequest = {
    firstName: "",
    lastName: "",
    userName: "",
    country: "",
    companyName: "",
    phone: "",
    email: "",
    password: "",
    mpToken: "",
    mpOrigin: "",
    mpBuyerId: "",
    forcePublicOffer: false,
    onlyCheckSSO: false,
    servers: [],
    serverName: "",
    regionCode: "",
    serverToken: "",
  };
  canSubmitForm = false;
  isAws = false;
  countries: string[] = [];
  tokenErrorResult = 0;

  async created() {
    this.loadFormDataFromSession();
    this.countries = await this.$jfRegistrations.getZuoraCountries();
    this.globalBus.$on("sendJfrogTokenResult", this.markToken);
  }

  mounted() {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 300);
  }
  @Watch("data", { deep: true })
  onDataChange() {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 300);
  }

  async handleOnDataChange() {
    if (this.errors.any() || (this.isAws && !this.data.mpBuyerId)) {
      this.$emit("serverTokenFilled", "");
      return;
    }
    this.$emit("allFormsData", this.data);
    if (this.data.serverToken && this.data.country) {
      this.$emit("serverTokenFilled", this.data.serverToken);
    }
  }

  get tokenCss() {
    let css = "";
    if (this.tokenErrorResult === jumpErrorsMapping.sendJfrogToken.jfrogTokenIsExpired) {
      css = "error expired";
    }
    if (this.tokenErrorResult === jumpErrorsMapping.sendJfrogToken.jfrogTokenIsInvalid) {
      css = "error invalid";
    }
    return css;
  }
  markToken(errorResult: number) {
    this.tokenErrorResult = errorResult;
  }
  loadFormDataFromSession() {
    let data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }
    this.data = JSON.parse(data);
    this.isAws = this.data.mpOrigin === "AWS_MP";
  }

  sendServerToken() {
    this.$emit("sendServerToken", true);
  }
}
