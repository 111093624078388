import { render, staticRenderFns } from "./FloatingNotificationItem.vue?vue&type=template&id=0ad7a4d2&scoped=true&"
import script from "./FloatingNotificationItem.vue?vue&type=script&lang=ts&"
export * from "./FloatingNotificationItem.vue?vue&type=script&lang=ts&"
import style0 from "./FloatingNotificationItem.vue?vue&type=style&index=0&id=0ad7a4d2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ad7a4d2",
  null
  
)

export default component.exports