import router from "@/router";
import { appService } from "@/services/app";
import store from "@/store/store";
import { SessionData } from "@/types/localtypes";
import { UsersService } from "@/types/services/users";
import apiProxy from "@/util/api-proxy";
import navigationUtil from "@/util/navigationUtil";
import {
  LoginRequest,
  SsoLoginRequest,
  LoginResponse,
  OtpLoginRequest,
  RegularLoginResponse,
  UserDetailsDto,
  UserDTO,
  SsoRegistrationRequest,
  SsoTokenRequest,
} from "@jfrog-ba/myjfrog-common";

import VueCookies from "vue-cookies";
import Vue from "vue";
import { cookiesService } from "@/services/cookies";

Vue.use(VueCookies);

export const usersService: UsersService = {
  logOutUrl: "user/logout",
  login: async ({ userIdentifier, password }: LoginRequest): Promise<LoginResponse | null> =>
    await apiProxy.post("user/login", { userIdentifier, password }),

  loginOtp: async (otpLoginRequest: OtpLoginRequest): Promise<RegularLoginResponse | null> =>
    await apiProxy.post("user/loginOtp", otpLoginRequest),

  loginSso: async (ssoLoginRequest: SsoLoginRequest): Promise<RegularLoginResponse | null> =>
    await apiProxy.post("user/registerAndLoginSsoUser", ssoLoginRequest),

  logout: async isExpired => {
    await apiProxy.post(usersService.logOutUrl, {});
    clearCacheAndLogoutFromUI(isExpired);
  },
  handleUnauthorized: async () => {
    const shouldNotifyExpiredSession = !appService.isFreshInstance();
    if (usersService.getSessionId()) {
      // got 401 during the session. That means the session is expired. We should trigger a classic logout
      usersService.logout(shouldNotifyExpiredSession);
    }
    clearCacheAndLogoutFromUI(shouldNotifyExpiredSession);
  },

  forgotPassword: async forgotPassword => await apiProxy.post(`user/forgotPassword`, forgotPassword),

  registerNow: async (email: string): Promise<null> => await apiProxy.post(`user/registerNow/${email}`, {}),

  resetPassword: async passwordRequest => await apiProxy.post(`user/resetPassword`, passwordRequest),

  changePassword: async changePassword => await apiProxy.post(`user/changePassword`, changePassword),

  setSessionData: (sessionData: SessionData) => {
    Vue.$cookies.set("sessionData", sessionData, cookiesService.getExpiration(), "/", cookiesService.getDomain());
  },
  getSessionData: (): SessionData | null => {
    const sessionData = Vue.$cookies.get("sessionData");
    if (sessionData) {
      return sessionData;
    }
    return null;
  },
  getSessionId() {
    const sessionData = this.getSessionData();
    if (!sessionData) {
      return "";
    }
    return sessionData.sessionId;
  },
  hasUser() {
    return !!store.getters["user/user"];
  },
  isUserSynced() {
    const sessionData = this.getSessionData();
    if (!sessionData) {
      return false;
    }
    const user: UserDTO = store.getters["user/user"];

    return user && user.userName === sessionData.userName;
  },
  redirectIfNotSync() {
    if (!this.isUserSynced()) {
      router.push("/refresh");
      throw new Error("user not synced");
    }
  },
  getUser: async () => await apiProxy.get("user"),

  getVuexUser: () => {
    const user = store.getters["user/user"];
    // user already loaded in Vuex
    if (user) {
      return Promise.resolve(user);
    } else {
      // Watching the vuex store to return the user when loaded
      return new Promise(resolve => {
        store.watch(
          (state, getters) => getters["user/user"],
          (newUser, oldValue) => {
            return resolve(newUser);
          },
        );
      });
    }
  },
  hasMultipleSubscriptions: async () => {
    const user = await usersService.getVuexUser();
    if (!user) {
      return false;
    }
    return user.subscriptionsAccountNumbers && user.subscriptionsAccountNumbers.length > 1;
  },
  hasBillingAccounts: async () => {
    const user = await usersService.getVuexUser();
    if (!user) {
      return false;
    }
    return user.paymentAccountNumbers && user.paymentAccountNumbers.length > 0;
  },
  getFirstSubscriptionAccountNumber: async () => {
    const user = await usersService.getVuexUser();
    if (!user) {
      return null;
    }
    return user.subscriptionsAccountNumbers[0];
  },
  updatePersonalInfo: async (userDetails: UserDetailsDto): Promise<null> => await apiProxy.put(`user`, userDetails),

  reloadUser: async () => store.dispatch("user/setUser", await usersService.getUser()),

  refreshVueXUser: async user => store.dispatch("user/setUser", user),

  getQuickPurchaseData: async request => await apiProxy.post(`user/getQuickPurchaseData`, request),

  getQuickPurchaseDataPromotion: async request => await apiProxy.post(`user/getQuickPurchaseDataPromotion`, request),

  checkRegistrationToken: async token => await apiProxy.get(`user/checkRegistrationToken/${token}`),

  register: async request => await apiProxy.post(`user/register`, request),

  registerAndLoginSsoUser: async (
    ssoRegistrationRequest: SsoRegistrationRequest,
  ): Promise<RegularLoginResponse | null> =>
    await apiProxy.post("user/registerAndLoginSsoUser", ssoRegistrationRequest),

  sendQuickPurchaseLinkViaMail: async serverName =>
    await apiProxy.post(`user/sendQuickPurchaseLinkViaMail/${serverName}`, {}),

  logRequestHeaders: async logPrefix => await apiProxy.post(`user/logRequestHeaders`, { logPrefix }),

  fetchOAuthToken: async (request: SsoTokenRequest) => {
    return await apiProxy.post("user/fetchOAuthToken", request);
  },
};

const clearCacheAndLogoutFromUI = (isExpired?: boolean) => {
  appService.clearCache();
  navigationUtil.logout(isExpired);
};
