
import { Component, Vue } from "vue-property-decorator";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { registrationsService } from "@/services/registrations";
import { storeErrorsMapping } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "RegistrationError",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFTextField },
})
export default class RegistrationError extends Vue {
  token = "";
  errorCode = 0;

  created() {
    this.token = this.$route.query.token as string;
    this.errorCode = parseInt(this.$route.query.errorCode as string);
  }

  get isTokenError() {
    return Object.values(storeErrorsMapping.sfRegistration).includes(this.errorCode);
  }

  async resendRegistrationLink() {
    try {
      await registrationsService.extendSalesforceInvitation(this.token);
      this.$jfNotification.success({ text: "Invitation sent, please check your email" });
    } catch (e) {
      this.errorCode = 1111111;
    }
  }
}
