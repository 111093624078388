import router from "@/router";
import { usersService } from "@/services/user";
import store from "@/store/store";
import { ProductActivationData, QuickPurchaseData } from "@/types/localtypes";
import axios, { AxiosError } from "axios";
import { restClientCreator } from "@jfrog-ba/myjfrog-common";

declare const API_PROXY_URL: string;
import VueCookies from "vue-cookies";
import Vue from "vue";

Vue.use(VueCookies);
const axiosInstance = axios.create({ baseURL: API_PROXY_URL });

axiosInstance.interceptors.request.use(config => {
  const sessionData = Vue.$cookies.get("sessionData");
  const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
  const productActivationData: ProductActivationData = store.getters["productActivation/productActivationData"];

  if (sessionData) {
    config.headers.set("X-Session-ID", sessionData.sessionId);
    if (usersService.hasUser() && !usersService.isUserSynced() && config.url !== usersService.logOutUrl) {
      router.push({ path: "/refresh" });
      throw new Error("Please Refresh Your Browser.");
    }
  } else if (quickPurchaseData && quickPurchaseData.token && quickPurchaseData.serverName) {
    if (quickPurchaseData.promotion) {
      config.headers.set("x-quick-purchase-promotion-token", quickPurchaseData.token);
      config.headers.set("x-quick-purchase-promotion-servername", quickPurchaseData.serverName);
    } else {
      config.headers.set("x-quick-purchase-token", quickPurchaseData.token);
      config.headers.set("x-quick-purchase-servername", quickPurchaseData.serverName);
    }
  } else if (
    productActivationData &&
    productActivationData.token &&
    productActivationData.serverName &&
    productActivationData.product
  ) {
    config.headers.set("x-product-activation-token", productActivationData.token);
    config.headers.set("x-product-activation-servername", productActivationData.serverName);
    config.headers.set("x-product-activation-product", productActivationData.product);
  }

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    // If we receive a 401 with an existing sessionId that means that we have a token expired
    if (error && error.response && error.response.status === 401 && usersService.getSessionId()) {
      usersService.handleUnauthorized();
    }
    return Promise.reject(error);
  },
);

export default restClientCreator(axiosInstance);
