import store from "@/store/store";
import { AppService } from "@/types/services/app";

import VueCookies from "vue-cookies";
import Vue from "vue";
import { cookiesService } from "@/services/cookies";
Vue.use(VueCookies);

export const appService: AppService = {
  setApplicationStartDate: () => {
    // if (window.localStorage.getItem("applicationStartDate") === null) {
    window.localStorage.setItem("applicationStartDate", new Date().toISOString());
  },

  isFreshInstance: () => {
    const applicationStartDateStr = window.localStorage.getItem("applicationStartDate");
    if (applicationStartDateStr === null) {
      return true;
    }
    try {
      const now = new Date();
      const applicationStartDate = new Date(applicationStartDateStr);
      const diffInSeconds = Math.round((now.getTime() - applicationStartDate.getTime()) / 1000);
      return diffInSeconds <= 3;
    } catch (e) {
      return true;
    }
  },

  clearCache: async () => {
    appService.clearSessionCache();
    appService.clearActivationCache();
  },
  clearSessionCache: async () => {
    await Vue.$cookies.remove("sessionData", "/", cookiesService.getDomain());
    store.dispatch("user/clearUser");
    store.dispatch("subscriptions/clearSubscriptions");
    store.dispatch("authentication/clearOtpAuthenticationData");
  },
  clearActivationCache: async () => {
    await window.localStorage.removeItem("activationData");
  },
};
