
import { AnyObject } from "@/types/localtypes";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "CircleSpinnerToCheckmark",
  components: {},
})
export default class CircleSpinnerToCheckmark extends Vue {
  @Prop({ default: 1000 })
  private animationDuration!: number;
  @Prop({ default: 60 })
  private size!: number;
  @Prop({ default: 15 })
  private fullCircleOffset!: number;
  @Prop({ default: true })
  private loading!: boolean;
  @Prop() private borderWidth!: number;
  @Prop({ default: "#ffffff" })
  private color!: string;

  @Watch("loading")
  onLoadingChanged(loading: boolean) {
    if (loading) {
      this.toCheck();
    }
  }

  toCheck() {}

  get spinnerStyle(): AnyObject {
    return {
      height: `${this.size}px`,
      width: `${this.size}px`,
    };
  }

  get computedBorderWidth() {
    return this.borderWidth || this.size / 10;
  }

  get circleStyle(): AnyObject {
    return {
      borderWidth: `${this.computedBorderWidth}px`,
      animationDuration: `${this.animationDuration}ms`,
    };
  }

  get circleFillStyle(): AnyObject {
    const style: AnyObject = {
      borderWidth: `${this.computedBorderWidth}px`,
      height: `${this.size + this.fullCircleOffset}px`,
      width: `${this.size + this.fullCircleOffset}px`,
      borderColor: this.color,
    };

    return style;
  }

  get circleClasses(): string[] {
    const classes = [];
    if (!this.loading) {
      classes.push("checked");
    }
    return classes;
  }
  get circle1Style(): AnyObject {
    return { ...this.circleStyle, borderTopColor: this.color };
  }

  get circle2Style(): AnyObject {
    return { ...this.circleStyle, borderBottomColor: this.color };
  }

  /*
  circle1 size 100 :
  top: 45px;
  left: -10px;
  width: 25px;

  circle2 size 100 :
      top: -33px;
      right: 48px;
      width: 50px;
   */
}
