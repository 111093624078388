import { subscriptionsService } from "@/services/subscriptions";
import { usersService } from "@/services/user";
import store from "@/store/store";
import { FloatingNotificationItemProps } from "@/types/localtypes/";
import { ViewContext } from "@/types/services/app";
import { FloatingNotificationsService } from "@/types/services/floatingNotifications";
import apiProxy from "@/util/api-proxy";
import { UINotification } from "@jfrog-ba/myjfrog-common";

export enum FloatingNotificationTagName {
  PIPELINES_TEASER = "PIPELINES_TEASER",
}

export const floatingNotificationsService: FloatingNotificationsService = {
  buildNotificationsProps: (uiNotifications: UINotification[], viewContext: ViewContext) => {
    const sortOrder: "ASC" | "DESC" = "ASC";
    const notificationsProps: FloatingNotificationItemProps[] = [];
    if (!uiNotifications) {
      return notificationsProps;
    }
    uiNotifications
      .sort((a, b) =>
        sortOrder === "ASC" ? a.startDisplayDate - b.startDisplayDate : b.startDisplayDate - a.startDisplayDate,
      )
      .forEach(uiNotification => {
        const itemProps = buildNotificationProps(uiNotification, viewContext);
        if (itemProps !== null) {
          notificationsProps.push(itemProps);
        }
      });
    return notificationsProps;
  },

  markAsRead: async (tagName: string) => {
    await apiProxy.post(`/notifications/uiNotifications/${tagName}/markAsRead`, {});
    const user = await usersService.getVuexUser();
    if (!user) {
      return;
    }
    user.uiNotifications = user.uiNotifications.filter(n => n.tagName !== tagName);
    await store.dispatch("user/setUser", user);
  },
};

const buildNotificationProps = (
  uiNotification: UINotification,
  viewContext: ViewContext,
): FloatingNotificationItemProps | null => {
  if (!Object.values(FloatingNotificationTagName).includes(uiNotification.tagName as FloatingNotificationTagName)) {
    // if the tagName is unknown by MyJFrog
    return null;
  }
  const tagName = uiNotification.tagName as FloatingNotificationTagName;
  const isOnBillingAccountPage = !!viewContext.billingAccounts;
  switch (tagName) {
    case "PIPELINES_TEASER":
      const triggerId = "pipelines_teaser_trigger";
      const notifiationIsFiltered =
        !!viewContext.filteredNotifications &&
        viewContext.filteredNotifications.includes(FloatingNotificationTagName.PIPELINES_TEASER);
      return {
        tagName,
        content: `<div text-left>Optimize your CI/CD Pipelines<br/><span id="${triggerId}" class="underlined-link" data-gtm-event="floating-notification|pipelines-froggy-teaser">Read more</span></div>`,
        triggerIds: [triggerId],
        iconClass: "icon-mjf-froggy",
        displayed:
          !notifiationIsFiltered &&
          !isOnBillingAccountPage &&
          !!viewContext.currentSubscriptionMeta &&
          subscriptionsService.isAddPipelinesPageReachable(viewContext.currentSubscriptionMeta),
      };
    default:
      return null;
  }
};
