
import ChangePassword from "@/components/modals/ModalChangePassword.vue";
import ModalMfaCancelEnrollment from "@/components/modals/ModalMfaCancelEnrollment.vue";
import ModalMfaEnrollment from "@/components/modals/ModalMfaEnrollment.vue";
import UserInfo from "@/components/modals/ModalUserInfo.vue";
import { JFEssentialsPopupMenuOptions } from "@/types/3rdPartyLibs";
import { JFPopupMenu } from "jfrog-ui-vue-essentials";
import { MyJFrogTheme, UserDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
declare var window: any;
@Component({
  name: "Header",
  components: { JFPopupMenu },
})
export default class Header extends Vue {
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  @Action("clearUser", { namespace: "user" })
  clearUser!: () => void;
  @Action("toggleMobileMenu", { namespace: "application" })
  toggleMobileMenu!: (isOpen: boolean) => void;
  @Getter("isSynced", { namespace: "isSynced" })
  isSynced!: boolean;
  @Prop({ default: "DEFAULT" })
  private mainTheme!: MyJFrogTheme;

  myJfrogDocumentationUrl = "https://www.jfrog.com/confluence/display/JFROG/MyJFrog+Cloud+Portal";
  jfrogContactUsUrl = "https://jfrog.com/contact-us/";
  jfrogWebsiteUrl = "https://jfrog.com/";
  jfrogSupportPortalUrl = "https://support.jfrog.com/";

  get headerMenuProps(): JFEssentialsPopupMenuOptions {
    return {
      placement: "bottom",
      theme: "web",
      title: this.welcome,
      titlePlacement: "left",
      trigger: "click",
      iconName: "icon-arrow",
      iconStyles: ["transform:rotate(90deg) scale(0.8)"],
      items: [
        {
          label: "My Profile",
          click: this.updateUserInfo,
          isDisabled: () => !this.isSynced,
          dataGtmEventTitle: "header-dropdown|my-profile-item",
        },
        {
          label: "Change Password",
          click: this.changePassword,
          isDisabled: () => !this.isSynced,
          dataGtmEventTitle: "header-dropdown|change-password-item",
        },
        {
          label: "Multi-Factor Authentication",
          click: this.startMfaEnrollemnt,
          isDisabled: () => !this.isSynced,
          showCondition: () => this.mfaNotActivated,
          dataGtmEventTitle: "header-dropdown|mfa-enrollment-start",
        },
        {
          label: "Multi-Factor Authentication",
          click: this.cancelMfaEnrollemnt,
          isDisabled: () => !this.isSynced,
          showCondition: () => this.mfaActivated,
          dataGtmEventTitle: "header-dropdown|mfa-enrollment-cancel",
        },
        {
          label: "Go To JFrog Website",
          showCondition: () => this.isMobile,
          click: this.redirectToWebsite,
          topSeparator: true,
          dataGtmEventTitle: "header-dropdown|jfrog-website-item",
        },
        {
          label: "Go To Support Portal",
          showCondition: () => this.isMobile,
          click: this.redirectToSupportPortal,
          dataGtmEventTitle: "header-dropdown|support-portal-item",
        },
        {
          label: "Contact Us",
          showCondition: () => this.isMobile,
          click: this.redirectToContactUs,
          dataGtmEventTitle: "header-dropdown|contact-us-item",
        },
        {
          label: "MyJFrog Documentation",
          showCondition: () => this.isMobile,
          click: this.redirectToDocumentation,
          dataGtmEventTitle: "header-dropdown|myjfrog-documentation-item",
        },

        {
          label: "Sign Out",
          click: this.logout,
          topSeparator: true,
          dataGtmEventTitle: "header-dropdown|sign-out-item",
        },
      ],
    };
  }

  get displayBreakpointName() {
    return (
      process.env.VUE_APP_DISPLAY_HEADER_BREAKPOINT_NAME &&
      process.env.VUE_APP_DISPLAY_HEADER_BREAKPOINT_NAME === "true"
    );
  }
  get headerClasses() {
    return [this.$mq, `theme-${this.mainTheme.toLowerCase()}`];
  }

  redirectToDocumentation() {
    window.open(this.myJfrogDocumentationUrl, "_blank");
  }
  redirectToContactUs() {
    window.open(this.jfrogContactUsUrl, "_blank");
  }
  redirectToSupportPortal() {
    window.open(this.jfrogSupportPortalUrl, "_blank");
  }

  redirectToWebsite() {
    window.open(this.jfrogWebsiteUrl, "_blank");
  }
  logout() {
    this.$jfUsers.logout();
    this.$router.replace({ path: "/login" }).catch(() => {
      // this callback should be defined to remove uncaught error in browser.
    });
    if (window.gapi && window.gapi.auth2) {
      window.gapi.auth2.getAuthInstance().disconnect();
    }
  }

  get welcome(): string {
    const firstName = this.user && this.user.firstName && this.user.firstName.toLowerCase();
    return firstName
      ? `${!this.isMobile && !this.isTablet ? "Welcome " : ""}${this.$utils.capitalize(firstName)}`
      : "Welcome";
  }

  get breakpointName(): string {
    return this.$mq;
  }

  private changePassword() {
    this.$jfModal.showCustom(
      ChangePassword,
      "md",
      {},
      {
        title: "Change Password",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }
  get isTablet() {
    return this.$mq === "tablet";
  }
  get isMobile() {
    return this.$mq === "mobile";
  }

  private updateUserInfo() {
    this.$jfModal.showCustom(
      UserInfo,
      "md",
      { userName: this.user && this.user.userName },
      {
        title: "My Profile",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  private startMfaEnrollemnt() {
    this.$jfModal.showCustom(
      ModalMfaEnrollment,
      "md",
      {},
      {
        title: "Multi-Factor Authentication",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  private cancelMfaEnrollemnt() {
    this.$jfModal.showCustom(
      ModalMfaCancelEnrollment,
      "md",
      {},
      {
        title: "Multi-Factor Authentication",
        forceDisplayCancelIcon: this.isMobile,
        headerBorder: false,
        footerBorder: false,
        clickShouldClose: false,
        shakeIfCantClose: false,
      },
    );
  }

  get mfaNotActivated() {
    return !!this.user && !this.user.mfaActivated;
  }
  get mfaActivated() {
    return !!this.user && this.user.mfaActivated;
  }
}
