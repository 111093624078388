import { PlanModel, PlanOptionsInternalTexts, PlanUsage } from "@/types/localtypes";
import { PlanOptions, PricingService } from "@/types/services/pricing";
import { PaymentType } from "@jfrog-ba/myjfrog-common";

export enum PlanDisclaimerTag {
  CDN_CONTENT = "CDN_CONTENT",
  PROJECT_BASED_MANAGEMENT = "PROJECT_BASED_MANAGEMENT",
  XRAY_PREMIUM = "XRAY_PREMIUM",
  DISTRIBUTION_EDGES = "DISTRIBUTION_EDGES",
  CICD_ORCHESTRATION = "CICD_ORCHESTRATION",
  VULNERABILITY_SCANNING = "VULNERABILITY_SCANNING",
}

export interface PlanDisclaimerConfig {
  legendArr: string[];
}

const getDevopsPlan = (
  options: PlanOptions,
  content: (
    | { texts: PlanOptionsInternalTexts[]; icon?: { name: string; fontSize: number }; title: string }
    | { texts: PlanOptionsInternalTexts[]; icon?: { name: string }; title: string }
    | {
        texts: PlanOptionsInternalTexts[];
        icon?: { name: string };
        tooltip: { number: number; tag: PlanDisclaimerTag };
        title: string;
      }
    | { texts: PlanOptionsInternalTexts[]; icon?: { cssClass: string; name: string; fontSize: number }; title: string }
  )[] = [
    {
      title: "Unlimited Number of Users",
      texts: [],
    },
    {
      title: "Unlimited Repositories",
      texts: [],
    },
    {
      title: "Universal Artifact Management",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
    },
    {
      title: "Container Registry",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
    },
    {
      title: "Vulnerability Scanning",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
      tooltip: {
        number: -1, // will be generated dynamically by "fillTooltipNumbers" method
        tag: PlanDisclaimerTag.VULNERABILITY_SCANNING,
      },
    },
    {
      title: " CI/CD Pipeline Orchestration",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
      tooltip: {
        number: -1, // will be generated dynamically by "fillTooltipNumbers" method
        tag: PlanDisclaimerTag.CICD_ORCHESTRATION,
      },
    },
    {
      title: "Unlimited Docker Hub access",
      texts: [],
    },
  ],
): { [key: string]: PlanModel } => ({
  FREE: {
    calculatorPaymentType: "FREE",
    paymentType: "FREE",
    code: "",
    title: "Free",
    subtitle: `5GB Transfer/month </br> 2GB Storage </br> Up to 5 Users`,
    pipelinesTitle: "",
    core: "DEVOPS",
    planId: "",
    isEnterprisePlan: false,
    titleText: [],
    purchase: { currency: "$", amount: 0, type: "month" },
    actionBtnDisabled: options.actionBtnDisabled,
    content: [
      {
        title: "Artifactory Binary Repository",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>30+ Natively Supported Packages</li>
                    <li>OSS Package Proxying</li>
                    <li>Dependency Management</li>
                    <li>Local, Remote, Virtual Repositories</li>
                    <li>Container Registry</li>
                </ul>`,
      },
      {
        title: "2,000 CI/CD Minutes/month",
        texts: [],
      },
    ],
    usage: [
      {
        label: "Transfer",
        unit: "GB",
        value: "10",
        period: "month",
      },
      {
        label: "Storage",
        unit: "GB",
        value: "2",
      },
      ...(options.isPipelinesAvailable
        ? ([
            {
              label: "CI/CD Minutes",
              value: "2,000",
              period: "month",
            },
          ] as PlanUsage[])
        : []),
    ],
    extra: { creditCardInfoLabel: "No Credit Card Required" },
  },
  // ------------- PRO_TEAM -------------
  PRO_TEAM: {
    calculatorPaymentType: "PRO_TEAM_MONTHLY",
    paymentType: "PRO_TEAM_MONTHLY",
    code: "",
    title: "Pro",
    subtitle: `20GB Transfer/month </br> 4GB Storage </br> Unlimited # of Users`,
    pipelinesTitle: "Pro Team including CI/CD Pipelines",
    planId: "",
    core: "DEVOPS",
    isEnterprisePlan: false,
    titleText: [],
    purchase: { currency: "$", amount: 98, type: "month", startingPrefix: "starting at", forPrefix: "for" },
    content: [
      {
        title: "Artifactory Binary Repository",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>30+ Natively Supported Packages</li>
                    <li>OSS Package Proxying</li>
                    <li>Dependency Management</li>
                    <li>Local, Remote, Virtual Repositories</li>
                    <li>Container Registry</li>
                    <li>Binary Promotion</li>
                    <li>Binary Replication</li>
                </ul>`,
      },
      {
        title: "Open Source Vulnerability Scanning",
        texts: [],
      },
      {
        title: "Cloud Provider of Choice",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>AWS</li>
                    <li>Google Cloud</li>
                    <li>Microsoft Azure</li>
                </ul>`,
      },
      {
        title: "25GB Base Consumption (Storage + Transfer)",
        texts: [],
      },
      {
        title: "10,000 CI/CD Minutes/month",
        texts: [],
      },
    ],
    usage: [
      {
        label: "Transfer",
        unit: "GB",
        value: "20",
        period: "month",
      },
      {
        label: "Storage",
        unit: "GB",
        value: "4",
      },
      {
        label: "CI/CD Minutes",
        value: "10,000",
        period: "month",
      },
    ],
  },
  // ------------- ENT_X -------------
  ENT: {
    calculatorPaymentType: "ENTERPRISE_MONTHLY",
    paymentType: "ENTERPRISE_MONTHLY",
    isMostPopular: true,
    code: "",
    title: "Enterprise X",
    subtitle: `200GB Transfer/month </br> 125GB Storage </br> Unlimited # of Users`,
    pipelinesTitle: "",
    planId: "",
    core: "DEVOPS",
    isEnterprisePlan: true,
    isForcedUpgrade: true,
    displayTrialRibbon: options.displayTrialRibbon,
    titleText: [],
    purchase: { currency: "$", amount: 699, type: "month", startingPrefix: "starting at", forPrefix: "for" },
    content: [
      {
        title: "Artifactory Binary Repository",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>30+ Natively Supported Packages</li>
                    <li>OSS Package Proxying</li>
                    <li>Dependency Management</li>
                    <li>Local, Remote, Virtual Repositories</li>
                    <li>Container Registry</li>
                    <li>Binary Promotion</li>
                    <li>Binary Replication</li>
                </ul>`,
      },
      {
        title: "Premium Vulnerability Database",
        texts: [],
        hover: `Comprehensive vulnerability database integrating data from VulnDB (Risk Based Security), NVD, GitHub, Ubuntu, Debian, Red Hat, PHP and the JFrog security research team.`,
      },
      {
        title: "Container Scanning",
        texts: [],
      },
      {
        title: "Open Source License Compliance",
        texts: [],
      },
      {
        title: "Multisite - Federated Repos",
        texts: [],
      },
      {
        title: "24x7 SLA Customer Support",
        texts: [],
      },
      {
        title: "99.9% Uptime Guarantee",
        texts: [],
      },
      {
        title: "125GB Base Consumption (Storage + Transfer)",
        texts: [],
      },
      {
        title: "25,000 CI/CD Minutes/month",
        texts: [],
      },
    ],
    usage: [
      {
        label: "Transfer",
        unit: "GB",
        value: "200",
        period: "month",
      },
      {
        label: "Storage",
        unit: "GB",
        value: "125",
      },
      {
        label: "CI/CD Minutes",
        value: "25,000",
        period: "month",
      },
    ],
  },
  CLOUD_PRO: {
    calculatorPaymentType: "MONTHLY",
    paymentType: "MONTHLY",
    code: "",
    title: "Cloud Pro",
    pipelinesTitle: "Cloud Pro including CI/CD Pipelines",
    planId: "",
    core: "DEVOPS",
    purchase: { currency: "$", amount: 0, type: "month" },
    isEnterprisePlan: false,
    titleText: options.isUpgrade
      ? // isUpgrade here means its only for jcr for now
        ["Enrich your Docker registry with 25", "package types including:", "RPM, Maven, Debian, Nuget"]
      : ["Universal Artifact Repository Management"],
    content: [
      {
        title: "Base Package",
        texts: ["2GB data storage", "10GB data transfer"],
      },
      {
        title: "Unlimited Number of Users and Repositories",
        texts: [],
      },
      {
        title: "Universal Repository",
        texts: ["All major package types supported"],
      },
      {
        title: "DevOps Ecosystem",
        texts: ["Out-of-the-box integration with major CI/CD solutions", "and comprehensive REST APIs and CLI"],
      },
      ...(options.canPurchasePipelines
        ? [
            {
              title: "CI/CD Platform Tools and Pipelines Orchestration (Optional)",
              texts: [],
            },
          ]
        : []),
      {
        title: "Data Storage and Data Transfer Usage",
        texts: ["Scale as you go", "Volume discount"],
      },
      {
        title: "Supported Configurations",
        texts: ["Hybrid and multi-cloud"],
        hideBorderBottom: true,
      },
    ],
  },
  CLOUD_PRO_X: {
    calculatorPaymentType: "MONTHLY_PROX",
    paymentType: "MONTHLY_PROX",
    code: "",
    title: "Cloud Pro X",
    pipelinesTitle: "Cloud Pro X including CI/CD Pipelines",
    planId: "",
    core: "DEVOPS",
    isEnterprisePlan: false,
    titleText: options.isUpgrade
      ? [
          "Add Security & License Compliance",
          "with 24/7 SLA support",
          ...(options.isPipelinesAvailable
            ? ["to the Cloud Pro package (CI/CD Pipelines Included)"]
            : ["to the Cloud Pro subscription"]),
        ]
      : ["Universal Artifact Repository Management", "Security & License Compliance", "24/7 Support"],
    purchase: { currency: "$", amount: 0, type: "month" },
    content: [
      {
        title: "Base Package",
        texts: ["125GB data storage", "200GB data transfer"],
      },
      {
        title: "Unlimited Number of Users and Repositories",
        texts: [],
      },
      {
        title: "Universal Repository",
        texts: ["All major package types supported"],
      },

      {
        title: "DevOps Ecosystem",
        texts: ["Out-of-the-box integration with major CI/CD solutions", "and comprehensive REST APIs and CLI"],
      },
      {
        id: "security",
        title: "Security & Compliance",
        texts: [
          "Full Artifact security vulnerability scanning",
          "VulnDB vulnerability intelligence",
          "Open-source license compliance",
        ],
      },
      ...(options.canPurchasePipelines
        ? [
            {
              title: `CI/CD Platform and Pipelines Orchestration${
                options.isPipelinesAvailable && options.isUpgrade ? "" : " (Optional)"
              }`,
              texts: [],
            },
          ]
        : []),
      {
        title: "Data Storage and Data Transfer Usage",
        texts: ["Scale as you go", "Volume discount"],
      },
      {
        title: "Supported Configurations",
        texts: ["Hybrid and multi-cloud"],
        hideBorderBottom: true,
      },
    ],
  },
  CLOUD_ENTERPRISE: {
    paymentType: "DEDICATED",
    code: "",
    title: "Cloud Enterprise",
    planId: "",
    core: "DEVOPS",
    isEnterprisePlan: true,
    titleText: [
      "Enterprise Read with High Availability, ",
      "multi-site replication, 24/7 SLA support",
      "and security & compliance",
    ],
    purchase: { currency: "$", amount: 699, type: "month" },
    content: [
      {
        title: "Base Package",
        texts: ["Customized usage package"],
      },
      {
        title: "Unlimited Number of Users and Repositories",
        texts: [],
      },
      {
        title: "Universal Repository",
        texts: ["All major package types supported"],
      },

      {
        title: "DevOps Ecosystem",
        texts: ["Out-of-the-box integration with major CI/CD solutions", "and comprehensive REST APIs and CLI"],
      },
      {
        id: "security",
        title: "Security & Compliance (Optional)",
        texts: [
          "Full Artifact security vulnerability scanning",
          "VulnDB vulnerability intelligence",
          "Open-source license compliance",
        ],
      },
      ...(options.canPurchasePipelines
        ? [
            {
              title: "CI/CD Platform and Pipelines Orchestration (Optional)",
              texts: [],
            },
          ]
        : []),
      {
        title: "IP or Domain Filtering",
        texts: [],
      },
      {
        title: "Virtual/Custom Host Name",
        texts: [],
      },
      {
        title: "Multi-Site and Cross-region Repository Replication",
        texts: [],
      },
      {
        title: "CDN Distribution",
        texts: [],
      },
      {
        title: "Extend Artifactory",
        texts: ["Using Groovy-based plugins"],
      },
      {
        title: "Supported Configurations",
        texts: ["Hybrid and multi-cloud"],
        hideBorderBottom: true,
      },
    ],
  },
  // ------------- ENTERPRISE_PLUS -------------
  CLOUD_ENTERPRISE_PLUS: {
    paymentType: "ENTERPRISE_PLUS",
    code: "",
    title: "Enterprise+",
    subtitle: `Custom Consumption Package </br> Unlimited # of users`,
    planId: "",
    core: "DEVOPS",
    isEnterprisePlan: false,
    isEnterprisePlusPlan: true,
    titleText: ["with High Touch Support"],
    customOffer: "FULL DEVOPS PLATFORM",
    content: [
      {
        title: "Full DevOps Platform",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>Streamline, Secure and Standardize Software Delivery Across Your Organization With Visibilty and Control at Global Scale</li> 
                    <li>JFrog Artifactory Binary Repository</li> 
                    <li>Lightning Fast Global Software Distribution</li> 
                    <li>Holistic, Integrated Application Security</li> 
                    <li>Connect Your Entire Ecosystem</li> 
                    <li>Expansive Metadata Capture and Observability</li> 
                </ul>`,
      },
      {
        title: "Premium Vulnerability Database",
        texts: [],
        hover: `Comprehensive vulnerability database integrating data from VulnDB (Risk Based Security), NVD, GitHub, Ubuntu, Debian, Red Hat, PHP and the JFrog security research team.`,
      },
      {
        title: "Container Scanning",
        texts: [],
      },
      {
        title: "Open Source License Compliance",
        texts: [],
      },
      {
        title: "High Touch Support",
        texts: [],
        hover: `Includes 24x7 SLA Support plus an assigned resource with regular touch-points to ensure success.`,
      },
      {
        title: "99.9% Uptime Guarantee",
        texts: [],
      },
      {
        title: "Binary Distribution",
        texts: [],
      },
      {
        title: "Distribution Edges",
        texts: [],
      },
      {
        title: "Multisite - Access Federation",
        texts: [],
        hover: `Control over access to all, or any subset of your services, from one location by synchronizing all security entities (users, groups, permissions and access tokens) between the federated services.`,
      },
      {
        title: "CI/CD Orchestration",
        texts: [],
        hover: `<ul class="pl-3">
                    <li>Pipeline Orchestration</li>
                    <li>Signed Pipelines</li>
                    <li>Templates</li>
                    <li>Centralized Secrets</li>
                    <li>Pipelines as Code</li>
                    <li>Real-time Workflow Visualization</li>
                    <li>Multi-OS Support</li>
                </ul>`,
      },
    ],
    usage: [
      {
        label: "Transfer",
        value: "Custom",
      },
      {
        label: "Storage",
        value: "Custom",
      },
      {
        label: "CI/CD Minutes",
        value: "Custom",
      },
    ],
    extra: {},
  },
});

const getDevSecOpsPlan = (
  options: PlanOptions,
  content: (
    | { texts: PlanOptionsInternalTexts[]; icon?: { name: string; fontSize: number }; title: string }
    | { texts: PlanOptionsInternalTexts[]; icon?: { name: string }; title: string }
    | {
        texts: PlanOptionsInternalTexts[];
        icon?: { name: string };
        tooltip: { number: number; tag: PlanDisclaimerTag };
        title: string;
      }
    | { texts: PlanOptionsInternalTexts[]; icon?: { cssClass: string; name: string; fontSize: number }; title: string }
  )[] = [
    {
      title: "Unlimited Number of Users",
      texts: [],
    },
    {
      title: "Unlimited Repositories",
      texts: [],
    },
    {
      title: "Universal Artifact Management",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
    },
    {
      title: "Container Registry",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
    },
    {
      title: "Vulnerability Scanning",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
      tooltip: {
        number: -1, // will be generated dynamically by "fillTooltipNumbers" method
        tag: PlanDisclaimerTag.VULNERABILITY_SCANNING,
      },
    },
    {
      title: " CI/CD Pipeline Orchestration",
      texts: [],
      icon: { name: "icon-mjf-curved-check" },
      tooltip: {
        number: -1, // will be generated dynamically by "fillTooltipNumbers" method
        tag: PlanDisclaimerTag.CICD_ORCHESTRATION,
      },
    },
    {
      title: "Unlimited Docker Hub access",
      texts: [],
    },
  ],
): { [key: string]: PlanModel } => ({
  // ------------- ENT_X -------------
  ENT: {
    calculatorPaymentType: "ENTERPRISE_MONTHLY",
    paymentType: "ENTERPRISE_MONTHLY",
    isMostPopular: true,
    code: "",
    title: "Enterprise X",
    subtitle: `200GB Transfer/month </br> 
               125GB Storage </br> 
               4,000 Security Scans/month </br>
               Unlimited # of Users `,
    pipelinesTitle: "",
    planId: "",
    core: "DEVSECOPS",
    isEnterprisePlan: true,
    isForcedUpgrade: true,
    currentTrialPlan: options.currentTrialPlan,
    titleText: [],
    purchase: {
      currency: "$",
      amount: 3400,
      type: "month",
      startingPrefix: "starting at",
      forPrefix: "for",
      hasIntroductoryPrice: true,
    },
    content: [
      {
        title: "Advanced DevSecOps",
        isAdvancedDevSecOps: true,
        texts: [
          {
            text: "Leaked Secrets Detection",
            hover: `Detect secrets left exposed in any containers stored in JFrog Artifactory to prevent any accidental leak of internal tokens or credentials.`,
          },
          {
            text: "Contextual Security Analysis",
            hover: `Identify and prioritize whether OSS vulnerabilities in containers are actually exploitable.`,
          },
          {
            text: "IaC Security",
            hover:
              "Secure IaC files stored in JFrog Artifactory for early detection of cloud and infrastructure misconfigurations that can be exploitable.",
          },
          {
            text: "Insecure Open Source Protection ",
            hover: `Find configuration issues, security malpractices, and insecure usage of popular OSS libraries related to your application framework.`,
          },
          {
            text: "SBOM Build and Exports",
            hover: "",
          },
          {
            text: "Premium Vulnerability Database ",
            hover:
              "Comprehensive vulnerability database integrating data from VulnDB (Risk Based Security), NVD, GitHub, Ubuntu, Debian, Red Hat, PHP and the JFrog security research team.",
          },
          {
            text: "Container Scanning",
            hover: "",
          },
          {
            text: "Open Source License Compliance",
            hover: "",
          },
        ],
      },
      {
        title: "DevOps Platform Package",
        isDevOpsPlatformPackage: true,
        texts: [
          {
            text: "Artifactory Binary Repository",
            hover: `<ul class="pl-3">
                    <li>30+ Natively Supported Packages</li>
                    <li>OSS Package Proxying</li>
                    <li>Dependency Management</li>
                    <li>Local, Remote, Virtual Repositories</li>
                    <li>Container Registry</li>
                    <li>Binary Promotion</li>
                    <li>Binary Replication</li>
                </ul>`,
          },
          {
            text: "24x7 SLA Customer Support",
            hover: "",
          },
          {
            text: "Multisite - Federated Repos",
            hover: "",
          },
          {
            text: "99.9% Uptime Guarantee",
            hover: "",
          },
          {
            text: "125GB Base Consumption (Storage + Transfer)",
            hover: "",
          },
          {
            text: "25,000 CI/CD Minutes/month",
            hover: "",
          },
        ],
      },
    ],
    usage: [
      {
        label: "Transfer",
        unit: "GB",
        value: "200",
        period: "month",
      },
      {
        label: "Storage",
        unit: "GB",
        value: "125",
      },
      {
        label: "CI/CD Minutes",
        value: "25,000",
        period: "month",
      },
    ],
  },
  // ------------- ENTERPRISE_PLUS -------------
  CLOUD_ENTERPRISE_PLUS: {
    paymentType: "ENTERPRISE_PLUS",
    code: "",
    title: "Enterprise+",
    subtitle: `Custom Consumption </br>
               Security Package </br> 
               7,000 Security Scans/month </br>
               Unlimited # of Users `,
    planId: "",
    core: "DEVSECOPS",
    isEnterprisePlan: false,
    isEnterprisePlusPlan: true,
    titleText: ["with full DevOps platform"],
    customOffer: "ADVANCED SECURITY",
    content: [
      {
        title: "Advanced DevSecOps",
        isAdvancedDevSecOps: true,
        texts: [
          {
            text: "Leaked Secrets Detection",
            hover: `Detect secrets left exposed in any containers stored in JFrog Artifactory to prevent any accidental leak of internal tokens or credentials.`,
          },
          {
            text: "Contextual Security Analysis",
            hover: `Identify and prioritize whether OSS vulnerabilities in containers are actually exploitable.`,
          },
          {
            text: "IaC Security",
            hover:
              "Secure IaC files stored in JFrog Artifactory for early detection of cloud and infrastructure misconfigurations that can be exploitable.",
          },
          {
            text: "Insecure Open Source Protection ",
            hover:
              "Find configuration issues, security malpractices, and insecure usage of popular OSS libraries related to your application framework.",
          },
          {
            text: "SBOM Build and Exports",
            hover: "",
          },
          {
            text: "Premium Vulnerability Database ",
            hover: `Comprehensive vulnerability database integrating data from VulnDB (Risk Based Security), NVD, GitHub, Ubuntu, Debian, Red Hat, PHP and the JFrog security research team.`,
          },
          {
            text: "Container Scanning",
            hover: "",
          },
          {
            text: "Open Source License Compliance",
            hover: "",
          },
        ],
      },
      {
        title: "DevOps Platform Package",
        isDevOpsPlatformPackage: true,
        texts: [
          {
            text: "Full DevOps Platform",
            hover: `<ul class="pl-3">
                    <li>Streamline, Secure and Standardize Software Delivery Across Your Organization With Visibilty and Control at Global Scale</li>
                    <li>JFrog Artifactory Binary Repository</li>
                    <li>Lightning Fast Global Software</li>
                    <li>Distribution Holistic, Integrated</li>
                    <li>Application Security Connect Your Entire Ecosystem Expansive Metadata Capture and Observability</li>
                </ul>`,
          },
          {
            text: "Hi Touch Support",
            hover: "Includes 24x7 SLA Support plus an assigned resource with regular touch-points to ensure success.",
          },
          {
            text: "99.9% Uptime Guarantee",
            hover: "",
          },
          {
            text: "Binary Distribution",
            hover: "",
          },
          {
            text: "Distribution Edges",
            hover: "",
          },
          {
            text: "Multisite - Access Federation",
            hover:
              "Control over access to all, or any subset of your services, from one location by synchronizing all security entities (users, groups, permissions and access tokens) between the federated services.",
          },
          {
            text: "CI/CD & Orchestration",
            hover: `<ul class="pl-3">
                    <li>Pipeline Orchestration</li>
                    <li>Signed Pipelines</li>
                    <li>Templates</li>
                    <li>Centralized Secrets</li>
                    <li>Pipelines as Code</li>
                    <li>Real-time Workflow Visualization</li>
                    <li>Multi-OS Support</li>
                </ul>`,
          },
        ],
      },
    ],
    usage: [
      {
        label: "Transfer",
        value: "Custom",
      },
      {
        label: "Storage",
        value: "Custom",
      },
      {
        label: "CI/CD Minutes",
        value: "Custom",
      },
    ],
    extra: {},
  },
});
export const pricingService: PricingService = {
  createPlan: (options, type, additionalTypes, promotionItems) => {
    if (!type) {
      if (!options.subscriptionType) {
        throw new Error("should be subscription type");
      }
      const plan =
        options.core === "DEVOPS"
          ? getDevopsPlan(options)[options.subscriptionType]
          : getDevSecOpsPlan(options)[options.subscriptionType];
      return {
        ...plan,
        ...options,
        additionalTypes: [],
      };
    }

    const selectedPlan: PlanModel =
      options.core === "DEVOPS"
        ? getDevopsPlan(options)[type.eventSubscriptionType]
        : getDevSecOpsPlan(options)[type.eventSubscriptionType];

    const promotionItem = promotionItems && promotionItems.find(item => item.paymentType === selectedPlan.paymentType);

    return {
      ...selectedPlan,
      code: type.paymentType as PaymentType,
      planId: type.ratePlanId,
      ...options,
      additionalTypes,
      purchase: selectedPlan.purchase && {
        ...selectedPlan.purchase,
        amount: parseInt(options.forceAdditionalType && additionalTypes ? additionalTypes[0].price : type.price),
      },
      promotionItem,
    };
  },

  fillTooltipNumbers: plans => {
    const tags = pricingService.extractPlanDisclaimerTags(plans);
    return plans.map(p => {
      return {
        ...p,
        content: p.content.map(c => {
          if (!c.tooltip) {
            return c;
          }
          const tooltipNumber = tags.indexOf(c.tooltip.tag) + 1;
          return {
            ...c,
            tooltip: { ...c.tooltip, number: c.tooltip.number || tooltipNumber },
          };
        }),
      };
    });
  },

  extractPlanDisclaimerTags: plans => {
    const tags: PlanDisclaimerTag[] = [];
    plans.forEach(p => {
      p.content.forEach(c => {
        if (c.tooltip && !tags.includes(c.tooltip.tag)) {
          tags.push(c.tooltip.tag);
        }
      });
    });

    return tags;
  },

  getPlanDisclaimerConfigs: () => {
    return {
      [PlanDisclaimerTag.CDN_CONTENT]: { legendArr: ["CDN capabilities are currently available on AWS only."] },
      [PlanDisclaimerTag.PROJECT_BASED_MANAGEMENT]: {
        legendArr: [
          "JFrog Projects is included in the subscriptions as follows: Pro Team (3 active / no add-ons), Enterprise (30 active / no add-ons), Enterprise + (300 active / add-ons permitted for an additional $2,400 annually per pack of 100 additional projects.",
        ],
      },
      [PlanDisclaimerTag.XRAY_PREMIUM]: {
        legendArr: [
          "The Security Pack is available with Enterprise subscriptions for an additional fee per month. Security Pack functionality is included in Enterprise+ subscriptions.",
        ],
      },
      [PlanDisclaimerTag.DISTRIBUTION_EDGES]: {
        legendArr: [
          "Distribution Edges may be utilized in self-hosted environments or as Cloud SaaS for hybrid distribution.",
        ],
      },
      [PlanDisclaimerTag.CICD_ORCHESTRATION]: {
        legendArr: ["CI/CD with JFrog Pipelines is available on AWS, Azure, and coming soon on GCP."],
      },
      [PlanDisclaimerTag.VULNERABILITY_SCANNING]: {
        legendArr: [
          "JFrog Xray secures your repository through deep, recursive vulnerability scanning, delivering a multilayered impact analysis of your containers and software artifacts. Security Pack (upgrade) features include OSS license compliance and premium vulnerability database access.",
        ],
      },
    };
  },
};
