
import { Component, Prop, Vue } from "vue-property-decorator";
import { Alert, AlertType } from "@/types/layout/contentNode";
import { AnyObject } from "@/types/localtypes";
import InlineSvg from "vue-inline-svg";

@Component({
  name: "SimpleAlert",
  components: { InlineSvg },
})
export default class SimpleAlert extends Vue {
  @Prop({ default: [] }) private data!: Alert;
  @Prop({ default: "" }) private cssClass!: string;
  @Prop({ default: false }) private isTooltip!: boolean;
  @Prop({ default: false }) private isInlineSvg!: boolean;
  borderColor = "green";
  isShowMessage = true;
  timeout = 0;

  mounted() {
    if (this.isTooltip) {
      this.isShowMessage = false;
    }
  }

  get wrapperClasses(): string[] {
    let classes = ["simple-alert"];
    classes.push(this.$mq);
    classes.push(this.cssClass);
    classes.push(this.data.type);
    if (this.isTooltip) {
      classes.push("tooltip-message");
    }
    return classes;
  }

  get messageCss() {
    let classes = ["message"];
    if (this.isTooltip && !this.isShowMessage) {
      classes.push("hidden");
    }
    return classes;
  }

  get wrapperStyles(): {} {
    let styles: AnyObject = {};
    styles["borderColor"] = this.borderColor;
    return styles;
  }

  showMessage() {
    if (!this.isTooltip) return;
    clearTimeout(this.timeout);
    this.isShowMessage = true;
  }

  hideMessage() {
    if (!this.isTooltip) return;
    this.timeout = setTimeout(() => {
      this.isShowMessage = false;
    }, 300);
  }

  iconBorder(): string | null {
    let icon = this.data.icon;
    const type: AlertType = this.data.type;
    if (!icon) {
      switch (type) {
        case "success":
          icon = "info.svg";
          this.borderColor = "green";
          break;
        case "info":
          icon = "info.svg";
          this.borderColor = "blue";
          break;
        case "warn":
          icon = "info.svg";
          this.borderColor = "#ebb855";
          break;
        case "error":
          icon = "info.svg";
          this.borderColor = "red";
          break;
        case "inlineSvg":
          icon = "info2.svg";
          this.borderColor = "#4b4b4b";
          break;
      }
      try {
        return this.$jfImages.get(icon);
      } catch (e) {
        this.$log.error("Failed to retrieve icon : " + icon);
      }
    }
    return null;
  }
}
