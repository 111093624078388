
import LoadingMask from "@/components/common/LoadingMask.vue";
import Footer from "@/components/layout/Footer.vue";
import Header from "@/components/layout/Header.vue";
import Menu from "@/components/layout/Menu.vue";
import MobileMenu from "@/components/layout/MobileMenu.vue";
import FeatureNotifications from "@/components/layout/notifications/feature/FeatureNotifications.vue";
import FloatingNotifications from "@/components/layout/notifications/floating/FloatingNotifications.vue";
import { messagesService } from "@/services/messages";
import { JFEssentialsNotificationConfig } from "@/types/3rdPartyLibs";
import { LoginFlag } from "@/types/localtypes";
import { MenuItemProps, MenuTooltipState } from "@/types/services/menus";
import { JFModal, JFNotification } from "jfrog-ui-vue-essentials";

import each from "lodash/each";
import kebabCase from "lodash/kebabCase";
import {
  JpuDTO,
  MyJFrogTheme,
  RegularLoginResponse,
  SubscriptionActionRequest,
  UserDTO,
} from "@jfrog-ba/myjfrog-common";
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { Action, Getter } from "vuex-class";
import { LoadingMaskProps } from "./types/loadingMask";
import Notification from "@/components/common/Notification.vue";
import { mjfNotificationService } from "@/services/mjfNotificationService";

@Component({
  name: "app",
  components: {
    Notification,
    JFNotification,
    JFModal,
    LoadingMask,
    Header,
    Menu,
    MobileMenu,
    Footer,
    FloatingNotifications,
    FeatureNotifications,
  },
})
export default class App extends Vue {
  @Provide() globalBus = new Vue();
  @Action("setMenuTooltipStates", { namespace: "application" })
  setMenuTooltipStates!: (states: MenuTooltipState[]) => void;
  @Getter("inMainApp", { namespace: "application" })
  inMainApp!: boolean;
  @Getter("lightUiEnabled", { namespace: "application" })
  lightUiEnabled!: boolean;
  @Getter("isAppLoading", { namespace: "application" })
  isAppLoading!: boolean;
  @Getter("isMobileMenuOpen", { namespace: "application" })
  isMobileMenuOpen!: boolean;
  @Getter("lastLoginFlag", { namespace: "application" })
  lastLoginFlag!: LoginFlag;
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  @Action("setUser", { namespace: "user" })
  setUser!: (user: UserDTO) => Promise<any>;
  @Action("setLastLoginFlag", { namespace: "application" })
  setLastLoginFlag!: (flag: LoginFlag) => void;
  @Action("clearLoginHeaderType", { namespace: "dynamicHeader" })
  clearLoginHeaderType!: () => void;
  mainTheme: MyJFrogTheme = "DEFAULT";
  transitionName: string = "";
  errorRecentlyReceived: boolean = false;
  isMobileNavMaskVisible: boolean = false;
  isMobileNavMaskActive: boolean = false;

  get appClasses() {
    const classes: string[] = [this.$mq];

    if (this.inMainApp) {
      classes.push("in-main-app");
    }

    if (this.user) {
      classes.push("logged-in");
    } else {
      classes.push("logged-out");
    }
    return classes;
  }

  get loadingMaskProperties(): LoadingMaskProps {
    return { zIndex: 100000, loading: this.isAppLoading, dark: true, spinnerConfig: { size: "xl", color: "#3c9040" } };
  }

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    const defaultTransitionName = "fade-short-left";
    this.transitionName = defaultTransitionName;
    // We can define a different transition between routes dynamically if needed.
    // For example :
    //   - slide-left or right based on route order properties
    //   - special transition for menu secondary items (in the right)
    //   - ...
  }

  bgImagePath = require("./assets/images/banner.png");
  cssColors = {
    jfColorGreen: "#43a047",
    jfColorRed: "#d73f3f",
  };
  backgroundStyles = {
    backgroundImage: `url("${this.bgImagePath}")`,
    backgroundPosition: "top left",
    backgroundRepeat: "repeat-x",
    backgroundSize: "contain",
  };
  additionalBackgroundStyles = {};

  menuTooltipStates: MenuTooltipState[] = [
    {
      id: "buy",
      open: false,
    },
    {
      id: "add-security",
      open: false,
    },
    {
      id: "add-pipelines",
      open: false,
    },
    {
      id: "add-security-premium",
      open: false,
    },
    {
      id: "upgrade",
      open: false,
    },
  ];
  @Watch("menuTooltipStates")
  onMenuTooltipStateChanged() {
    this.setMenuTooltipStates(this.menuTooltipStates);
  }

  mobileBeforeEnter() {
    this.isMobileNavMaskVisible = true;
    this.isMobileNavMaskActive = true;
  }

  generateCssProps() {
    // cant use css props because MyJFrog should be compatible with IE11
    let cssProps: { [key: string]: string } = {};
    each(this.cssColors, (value, key) => (cssProps["--" + kebabCase(key)] = value));
    each(this.backgroundStyles, (value, key) => (cssProps[kebabCase(key)] = value));
    each(this.additionalBackgroundStyles, (value, key) => (cssProps[kebabCase(key)] = value));

    return cssProps;
  }

  handleMenuTooltipMechanism(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    tooltipContent: string,
    tooltipHasSubmitAction: boolean,
    reason: SubscriptionActionRequest["reason"],
  ) {
    if (this.isMobile) {
      this.$jfModal.confirm({
        body: tooltipContent,
        buttonsText: {
          confirm: tooltipHasSubmitAction ? "Contact Me" : "Close",
        },
        onCancel: () => true,
        onConfirm: () => {
          this.sendSubscriptionRequest(id, serverName, reason);
          this.$jfNotification.success({ text: messagesService.menu_tooltip_action_success });
          return true;
        },
        displayCancelButton: tooltipHasSubmitAction,
        footerBorder: false,
      });
    } else {
      const currentState = this.menuTooltipStates.find(state => state.id === id);
      if (currentState) {
        const currentlyOpened = currentState.open;
        this.closeAllTooltip();
        if (!currentlyOpened) {
          currentState.open = true;
          this.menuTooltipStates = this.menuTooltipStates.map(state => {
            if (state.id !== id) {
              return state;
            }
            return currentState;
          });
          this.addTooltipEvents(id, serverName, reason);
        }
      }
    }
  }

  addTooltipEvents(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    setTimeout(() => {
      this.addTooltipCloseEvents();
      this.addTooltipButtonEvents(id, serverName, reason);
    }, 0);
  }

  addTooltipButtonEvents(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    const validButtons = document.getElementsByClassName("tooltip-footer-button-valid");
    for (let i = 0; i < validButtons.length; i++) {
      let button = validButtons[i] as HTMLButtonElement;
      button.removeEventListener("click", () => this.handleTooltipValidation);
      button.addEventListener("click", () => this.handleTooltipValidation(id, serverName, reason));
    }
  }

  handleTooltipValidation(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    const validationMessage = document.getElementsByClassName("tooltip-footer-message-wrapper")[0] as HTMLDivElement;
    const validationButton = document.getElementsByClassName(" tooltip-footer-button-valid")[0] as HTMLButtonElement;
    validationMessage.classList.remove("hidden");
    validationButton.classList.add("disabled");
    validationButton.setAttribute("disabled", "disabled");
    this.sendSubscriptionRequest(id, serverName, reason);
  }

  async sendSubscriptionRequest(
    id: MenuItemProps["id"],
    serverName: JpuDTO["serverName"],
    reason: SubscriptionActionRequest["reason"],
  ) {
    let requestActionType: SubscriptionActionRequest["actionType"] | null = null;
    switch (id) {
      case "buy":
        requestActionType = "buy";
        break;
      case "upgrade":
        requestActionType = "upgrade";
        break;
    }
    if (requestActionType) {
      await this.$jfSubscriptions.sendSubscriptionActionRequest({
        marketoCookie: this.$jfMarketo.getCookie(),
        actionType: requestActionType,
        serverName: serverName,
        reason: reason,
      });
    }
  }

  addTooltipCloseEvents() {
    const closeIcons = document.getElementsByClassName("tooltip-close-icon");
    for (let i = 0; i < closeIcons.length; i++) {
      let closeIcon = closeIcons[i] as HTMLSpanElement;
      closeIcon.removeEventListener("click", () => this.closeAllTooltip());
      closeIcon.addEventListener("click", () => this.closeAllTooltip());
    }
  }

  closeAllTooltip() {
    this.menuTooltipStates = this.menuTooltipStates.map(state => ({ ...state, open: false }));
  }
  closeMobileMenu() {
    this.globalBus.$emit("shouldCloseMobileMenu");
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  onNotificationSpanRouterLinkClick(path: string) {
    this.$router.push({ path: path });
    this.$jfNotification.clearAll();
  }

  convertNotificationSpanRouterLink() {
    setTimeout(() => {
      const spanRouterLink = document.getElementsByClassName("span-router-link")[0] as HTMLSpanElement;
      if (spanRouterLink) {
        const spanRouterPath = spanRouterLink.getAttribute("to-router-path");
        if (spanRouterPath) {
          spanRouterLink.removeEventListener("click", () => this.onNotificationSpanRouterLinkClick(spanRouterPath));
          spanRouterLink.addEventListener("click", () => this.onNotificationSpanRouterLinkClick(spanRouterPath));
        } else {
          this.$log.warn("The 'span-router-link' element doesn't contain a 'to-router-path' attribute for conversion");
        }
      } else {
        this.$log.warn("No 'span-router-link' elements found for conversion");
      }
    }, 20);
  }

  reloadMainTheme() {
    const sessionData = this.$jfUsers.getSessionData();
    let theme: MyJFrogTheme = "DEFAULT";
    if (sessionData && sessionData.theme) {
      theme = sessionData.theme;
    }
    this.mainTheme = theme;
  }

  async handleLoginSuccess(loginResponse: RegularLoginResponse) {
    this.$jfUsers.setSessionData({
      sessionId: loginResponse.sessionId,
      userName: loginResponse.user.userName,
      theme: loginResponse.theme,
      authenticated: true,
    });
    await this.setUser(loginResponse.user);
    await this.$jfFeatureNotifications.resetAllStates();
    this.$jfNotification.clearAll();
    this.$router.replace(
      {
        path: "/",
      },
      // this callback should be defined to remove uncaught error in browser.
      () => {},
    );
  }

  checkLastLoginFlag() {
    if (this.lastLoginFlag) {
      let message: JFEssentialsNotificationConfig["text"];
      let isError: boolean = true;
      switch (this.lastLoginFlag) {
        case "errorSessionExpired":
          message = this.$jfMessages.app_session_expired;
          break;
        case "errorSomethingWentWrong":
          message = this.$jfMessages.app_something_went_wrong_login;
          break;
      }
      if (isError) {
        this.$jfNotification.error({ text: message, duration: 15000 });
      } else {
        this.$jfNotification.success({ text: message, duration: 6000 });
      }
      this.setLastLoginFlag(null);
    }
  }

  createGlobalEventsHandlers() {
    this.globalBus.$on("notifyError", (errorMessage: string, forceDisplay: boolean = false) => {
      if (forceDisplay || !this.errorRecentlyReceived) {
        this.$jfNotification.error({ text: errorMessage });
        this.errorRecentlyReceived = true;
        setTimeout(() => {
          this.errorRecentlyReceived = false;
        }, 400);
      }
    });

    this.globalBus.$on(
      "onMenuTooltipClick",
      (
        id: MenuItemProps["id"],
        serverName: JpuDTO["serverName"],
        tooltipContent: string,
        tooltipHasSubmitAction: boolean,
        reason: SubscriptionActionRequest["reason"],
      ) => {
        this.handleMenuTooltipMechanism(id, serverName, tooltipContent, tooltipHasSubmitAction, reason);
      },
    );
    this.globalBus.$on("onMenuConfigurationChange", () => this.closeAllTooltip());
    this.globalBus.$on("shouldConvertNotificationSpanRouterLink", () => this.convertNotificationSpanRouterLink());
    this.globalBus.$on("shouldReloadMainTheme", () => this.reloadMainTheme());
    this.globalBus.$on("shouldHandleLoginSuccess", this.handleLoginSuccess);
    this.globalBus.$on("shouldCheckLastLoginFlag", this.checkLastLoginFlag);
  }

  created() {
    mjfNotificationService._setGlobalBus(this.globalBus);
  }
  mounted() {
    this.createGlobalEventsHandlers();
    this.reloadMainTheme();
  }

  beforeCreate() {
    this.$jfApplication.setApplicationStartDate();
  }
}
