import Vue from "vue";
import Router, { RouterOptions } from "vue-router";
import multiguard from "vue-router-multiguard";
import {
  authentication,
  billingAccount,
  cloudActivation,
  configuration,
  quickPurchase,
  quickPurchasePromoPT15Usd,
  redirection,
  registration,
  sandbox,
  subscription,
  synchronization,
  emailVerification,
  productActivation,
  cloudFreeReactivation,
} from "./services/routesMiddlewares";
import ServerReactivation from "@/components/landingPages/cloudActivation/ServerReactivation.vue";
import RegistrationCredentials from "@/components/landingPages/cloudActivation/RegistrationCredentials.vue";
import RegistrationEnvironmentData from "@/components/landingPages/cloudActivation/RegistrationEnvironmentData.vue";
import RegistrationError from "@/components/landingPages/cloudActivation/RegistrationError.vue";
import QuickPurchaseNew from "@/components/landingPages/quickPurchaseNew/QuickPurchaseNew.vue";
import QuickPurchaseNewWrapper from "@/components/landingPages/quickPurchaseNew/QuickPurchaseNewWrapper.vue";
import MPUnifiedLanding from "@/components/landingPages/marketplaceUnified/MPUnifiedLanding.vue";
import MPUnifiedLogin from "@/components/landingPages/marketplaceUnified/MPUnifiedLogin.vue";
import MPUnifiedDetailsForm from "@/components/landingPages/marketplaceUnified/MPUnifiedDetailsForm.vue";
import MPUnifiedEnvironmentData from "@/components/landingPages/marketplaceUnified/MPUnifiedEnvironmentData.vue";
import MPUnifiedWaitingRoom from "@/components/landingPages/marketplaceUnified/MPUnifiedWaitingRoom.vue";
import SSOResponse from "@/components/views/loggedOut/SSOResponse.vue";
import MPUnifiedError from "@/components/landingPages/marketplaceUnified/MPUnifiedError.vue";
import MPUnifiedServerToken from "@/components/landingPages/marketplaceUnified/MPUnifiedServerToken.vue";
import Maintenance from "@/components/landingPages/Maintenance.vue";

const Subscriptions = () => import("./components/views/subscriptions/Subscriptions.vue");
const SubscriptionsList = () => import("./components/views/subscriptions/SubscriptionsList.vue");
const SingleSubscription = () => import("./components/views/subscriptions/SingleSubscription.vue");
const SubscriptionsBuy = () => import("./components/views/subscriptions/SubscriptionsBuy.vue");
const SubscriptionsUpgrade = () => import("./components/views/subscriptions/SubscriptionsUpgrade.vue");
const SubscriptionsAddSecurity = () => import("./components/views/subscriptions/SubscriptionsAddSecurity.vue");
const SubscriptionsAddXrayPremium = () => import("./components/views/subscriptions/SubscriptionsAddXrayPremium.vue");
const SubscriptionsAddPipelines = () => import("./components/views/subscriptions/SubscriptionsAddPipelines.vue");
const SubscriptionsDetail = () => import("./components/views/subscriptions/SubscriptionsDetail.vue");
const BillingAccounts = () => import("./components/views/billingAccounts/BillingAccounts.vue");
const Sandbox = () => import("./components/views/sandbox/Sandbox.vue");
const LoggedOut = () => import("./components/views/loggedOut/LoggedOut.vue");
const Login = () => import("./components/views/loggedOut/Login.vue");
const ForgotPassword = () => import("./components/views/loggedOut/ForgotPassword.vue");
const RegisterNow = () => import("./components/views/loggedOut/RegisterNow.vue");
const LoginAuthenticator = () => import("./components/views/loggedOut/LoginAuthenticator.vue");
const ResetPassword = () => import("./components/views/loggedOut/ResetPassword.vue");
const Registration = () => import("./components/views/loggedOut/Registration.vue");
const SSOLoginFailed = () => import("./components/views/loggedOut/SSOLoginFailed.vue");
const QuickPurchase = () => import("./components/landingPages/quickPurchase/QuickPurchase.vue");
const QuickPurchasePricing = () => import("./components/landingPages/quickPurchase/QuickPurchasePricing.vue");
const QuickPurchaseCheckout = () => import("./components/landingPages/quickPurchase/QuickPurchaseCheckout.vue");
const QuickPurchaseGetAQuote = () => import("./components/landingPages/quickPurchase/QuickPurchaseGetAQuote.vue");
const QuickPurchaseThankYou = () => import("./components/landingPages/quickPurchase/QuickPurchaseThankYou.vue");
const QuickPurchaseAlreadyUpgraded = () =>
  import("./components/landingPages/quickPurchase/QuickPurchaseAlreadyUpgraded.vue");
const QuickPurchaseUpgradeInProgress = () =>
  import("./components/landingPages/quickPurchase/QuickPurchaseUpgradeInProgress.vue");
const QuickPurchaseError = () => import("./components/landingPages/quickPurchase/QuickPurchaseError.vue");
const QuickPurchaseInvalidOrExpiredToken = () =>
  import("./components/landingPages/quickPurchase/QuickPurchaseInvalidOrExpiredToken.vue");

const PromoPT15USD = () => import("./components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USD.vue");
const PromoPT15USDBuyNow = () =>
  import("./components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDBuyNow.vue");
const PromoPT15USDCongratulations = () =>
  import("./components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDCongratulations.vue");
const PromoPT15USDError = () =>
  import("./components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDError.vue");
const PromoPT15USDPromotionExpired = () =>
  import("./components/landingPages/quickPurchase/promotions/proteam15usd/PromoPT15USDPromotionExpired.vue");

const CloudActivation = () => import("./components/landingPages/cloudActivation/CloudActivation.vue");
const Error404 = () => import("./components/views/errors/Error404.vue");
const Error500 = () => import("./components/views/errors/Error500.vue");
const ErrorRefresh = () => import("@/components/views/errors/ErrorRefresh.vue");
const EmailVerification = () => import("./components/landingPages/EmailVerification.vue");
const ProductActivation = () => import("./components/landingPages/ProductActivation/ProductActivation.vue");
const ProductActivationViaCreditThanks = () =>
  import("./components/landingPages/ProductActivation/ProductActivationViaCreditThanks.vue");
const ServerReactivationResult = () => import("./components/landingPages/cloudActivation/ServerReactivationResult.vue");
const CloudCredentials = () => import("./components/landingPages/marketplace/aws/CloudCredentials.vue");
const CloudEnvironmentData = () => import("./components/landingPages/marketplace/aws/CloudEnvironmentData.vue");
const CloudLanding = () => import("./components/landingPages/marketplace/aws/CloudLanding.vue");
const CloudWaitingRoom = () => import("./components/landingPages/marketplace/aws/CloudWaitingRoom.vue");
const CloudAccount = () => import("./components/landingPages/marketplace/aws/CloudAccount.vue");
const MPLandingGCP = () => import("./components/landingPages/marketplace/gcp/MPLanding.vue");
const MPWaitingRoomGCP = () => import("./components/landingPages/marketplace/gcp/MPWaitingRoom.vue");
const CloudTrialActivationViaSuccessMail = () =>
  import("./components/landingPages/cloudActivation/CloudTrialActivationViaSuccessMail.vue");
const CloudServerActivation = () => import("./components/landingPages/cloudActivation/CloudServerActivation.vue");
Vue.use(Router);

export const routerOptions: RouterOptions = {
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "subscriptions",
    },
    {
      path: "/subscriptions",
      beforeEnter: multiguard([
        redirection.toNewMjf,
        configuration.checkLightUi,
        configuration.setInMainApp,
        authentication.required,
        synchronization.isSynced,
      ]),
      component: Subscriptions,
      children: [
        {
          path: "",
          redirect: "list",
        },
        {
          path: "list",
          name: "subscriptions.list",
          beforeEnter: multiguard([subscription.hasMultiple, redirection.toNewMjf]),
          component: SubscriptionsList,
          meta: {
            deep: 1,
          },
        },
        {
          path: "upgrade",
          beforeEnter: redirection.toOrigin,
        },
        {
          path: "buy",
          beforeEnter: redirection.toOrigin,
        },
        {
          path: "add-security",
          beforeEnter: redirection.toOrigin,
        },
        {
          path: "add-security-premium",
          beforeEnter: redirection.toOrigin,
        },
        {
          path: ":accountNumber(\\d+)", // will match only digits (see https://github.com/pillarjs/path-to-regexp)
          component: SingleSubscription,
          beforeEnter: redirection.toNewMjf,
          children: [
            {
              path: "",
              name: "subscriptions.detail",
              component: SubscriptionsDetail,
              meta: {
                deep: 1,
              },
              props: true,
            },
            {
              path: "buy",
              name: "subscriptions.buy",
              component: SubscriptionsBuy,
              meta: {
                skipMjfRedirection: true,
                deep: 2,
              },
            },
            {
              path: "upgrade",
              name: "subscriptions.upgrade",
              component: SubscriptionsUpgrade,
              meta: {
                skipMjfRedirection: true,
                deep: 2,
              },
            },
            {
              path: "add-security",
              name: "subscriptions.add.security",
              component: SubscriptionsAddSecurity,
              meta: {
                deep: 2,
              },
            },
            {
              path: "add-security-premium",
              name: "subscriptions.add.security.premium",
              component: SubscriptionsAddXrayPremium,
              meta: {
                deep: 2,
              },
            },
            {
              path: "add-pipelines",
              name: "subscriptions.add.pipelines",
              component: SubscriptionsAddPipelines,
              meta: {
                deep: 2,
              },
            },
          ],
        },
      ],
    },

    {
      path: "/billing-accounts",
      name: "billing-accounts",
      beforeEnter: multiguard([
        redirection.toNewMjf,
        configuration.setInMainApp,
        authentication.required,
        synchronization.isSynced,
        billingAccount.canAccess,
      ]),
      component: BillingAccounts,
    },
    {
      path: "/upgrade",
      beforeEnter: redirection.toOrigin,
    },
    {
      path: "/buy",
      beforeEnter: redirection.toOrigin,
    },
    {
      path: "/add-security",
      beforeEnter: redirection.toOrigin,
    },
    {
      path: "/add-security-premium",
      beforeEnter: redirection.toOrigin,
    },
    {
      path: "/add-pipelines",
      beforeEnter: redirection.toOrigin,
    },

    {
      path: "/Sandbox",
      name: "sandbox",
      component: Sandbox,
      beforeEnter: multiguard([
        redirection.toNewMjf,
        configuration.setInMainApp,
        authentication.required,
        sandbox.canAccess,
      ]),
    },
    {
      path: "/logged-out",
      component: LoggedOut,
      beforeEnter: multiguard([
        registration.cleanCacheIfNeeded,
        redirection.toNewMjf,
        configuration.setInMainApp,
        authentication.none,
      ]),
      children: [
        {
          path: "login",
          name: "login",
          alias: "/login",
          component: Login,
        },
        {
          path: "forgot-password",
          name: "forgot-password",
          alias: "/forgot-password",
          component: ForgotPassword,
        },
        {
          path: "reset-password",
          name: "reset-password",
          alias: "/reset-password",
          component: ResetPassword,
        },
        {
          path: "register-now",
          name: "register-now",
          alias: "/register-now",
          component: RegisterNow,
        },
        {
          path: "registration",
          name: "registration",
          alias: "/registration",
          component: Registration,
          beforeEnter: registration.canAccess,
        },
        {
          path: "sso-login-failed",
          name: "sso-login-failed",
          alias: "/sso-login-failed",
          component: SSOLoginFailed,
        },
        {
          path: "google-authenticator",
          name: "google-authenticator",
          alias: "/google-authenticator",
          component: LoginAuthenticator,
          beforeEnter: authentication.otpRequired,
        },
      ],
    },
    {
      path: "/pro-team-offer",
      name: "pro-team-offer",
      component: PromoPT15USD,
      beforeEnter: quickPurchasePromoPT15Usd.canAccess,
      children: [
        {
          path: "",
          redirect: { name: "pro-team-offer-buy-now" },
        },
        {
          path: "buy-now",
          name: "pro-team-offer-buy-now",
          component: PromoPT15USDBuyNow,
          beforeEnter: quickPurchasePromoPT15Usd.noErrors,
          props: true,
        },
        {
          path: "congratulations",
          name: "pro-team-offer-congratulations",
          component: PromoPT15USDCongratulations,
          beforeEnter: quickPurchasePromoPT15Usd.noErrors,
          props: true,
        },
        {
          path: "promotion-expired",
          name: "pro-team-offer-promotion-expired",
          component: PromoPT15USDPromotionExpired,
          props: true,
        },
        {
          path: "error",
          name: "pro-team-offer-error",
          component: PromoPT15USDError,
        },
      ],
    },
    {
      path: "/activation",
      name: "activation",
      component: CloudActivation,
      beforeEnter: cloudActivation.bootstrap,
    },

    {
      path: "/sso-login",
      name: "sso-login",
      beforeEnter: multiguard([authentication.isSSO, authentication.none]),
    },
    {
      path: "/to-quick-purchase",
      name: "to-quick-purchase",
      beforeEnter: multiguard([quickPurchase.toQuickPurchase]),
    },
    {
      path: "/to-quick-purchase-error",
      name: "to-quick-purchase-error",
      beforeEnter: multiguard([quickPurchase.toQuickPurchaseError]),
    },
    {
      path: "/to-quick-purchase-already-upgraded",
      name: "to-quick-purchase-already-upgraded",
      beforeEnter: multiguard([quickPurchase.toQuickPurchaseAlreadyUpgraded]),
    },
    {
      path: "/to-quick-purchase-upgrade-in-progress",
      name: "to-quick-purchase-upgrade-in-progress",
      beforeEnter: multiguard([quickPurchase.toQuickPurchaseUpgradeInProgress]),
    },
    {
      path: "/to-quick-purchase-should-login",
      name: "to-quick-purchase-should-login",
      beforeEnter: multiguard([quickPurchase.toQuickPurchaseShouldLogin]),
    },
    {
      path: "/to-quick-purchase-invalid-token",
      name: "/to-quick-purchase-invalid-token",
      beforeEnter: multiguard([quickPurchase.toQuickPurchaseInvalidOrExpiredToken]),
    },
    {
      path: "/to-quick-purchase-pro-team-offer",
      name: "to-quick-purchase-pro-team-offer",
      beforeEnter: multiguard([quickPurchasePromoPT15Usd.toMainView]),
    },
    {
      path: "/to-quick-purchase-pro-team-offer-error",
      name: "to-quick-purchase-pro-team-offer-error",
      beforeEnter: multiguard([quickPurchasePromoPT15Usd.toError]),
    },
    {
      path: "/to-quick-purchase-pro-team-offer-promotion-expired",
      name: "to-quick-purchase-pro-team-offer-promotion-expired",
      beforeEnter: multiguard([quickPurchasePromoPT15Usd.toPromotionExpired]),
    },
    {
      path: "/404",
      name: "404",
      component: Error404,
    },
    {
      path: "/500",
      name: "500",
      component: Error500,
    },
    {
      path: "/refresh",
      name: "refresh",
      beforeEnter: synchronization.isNotSynced,
      component: ErrorRefresh,
    },
    {
      path: "/validate",
      name: "validate",
      component: EmailVerification,
      beforeEnter: emailVerification.bootstrap,
    },
    {
      path: "/activate-product-credit",
      name: "/activate-product-credit",
      component: ProductActivation,
      beforeEnter: productActivation.canAccess,
    },
    {
      path: "/activate-product-thank-you",
      name: "/activate-product-thank-you",
      component: ProductActivationViaCreditThanks,
      beforeEnter: productActivation.canAccessThanks,
      props: true,
    },
    {
      path: "/activate-product-result",
      name: "/activate-product-result",
      component: ProductActivationViaCreditThanks,
      beforeEnter: productActivation.canAccessThanks,
      props: true,
    },
    {
      path: "/reactivate-server/:serverName",
      name: "/reactivate-server",
      component: ServerReactivation,
      props: true,
    },
    {
      path: "/reactivate-server-result",
      name: "/reactivate-server-result",
      component: ServerReactivationResult,
      beforeEnter: cloudFreeReactivation.bootstrap,
      props: true,
    },
    {
      path: "/marketplace",
      name: "marketplace",
      component: CloudLanding,
      meta: {
        steps: ["mp.subscribe", "mp.environment", "mp.waiting"],
        enterpriseSteps: ["mp.ent.account", "mp.ent.subscribe", "mp.ent.environment", "mp.ent.waiting"],
        enterprisePlusSteps: [
          "mp.ent.plus.account",
          "mp.ent.plus.subscribe",
          "mp.ent.plus.environment",
          "mp.ent.plus.waiting",
        ],
      },
      children: [
        {
          path: "aws-pro-team",
          name: "mp.subscribe",
          component: CloudCredentials,
          meta: {
            subscriptionType: "AWS_PRO_TEAM",
            provider: "AWS",
            step: 1,
          },
        },
        {
          path: "aws-pro-team/environment",
          name: "mp.environment",
          component: CloudEnvironmentData,
          meta: {
            subscriptionType: "AWS_PRO_TEAM",
            provider: "AWS",
            step: 2,
          },
        },
        {
          path: "aws-pro-team/waiting",
          name: "mp.waiting",
          component: CloudWaitingRoom,
          meta: {
            subscriptionType: "AWS_PRO_TEAM",
            provider: "AWS",
            step: 3,
          },
        },
        {
          path: "aws-enterprise",
          name: "mp.ent.account",
          component: CloudAccount,
          meta: {
            subscriptionType: "AWS_ENTERPRISE",
            provider: "AWS",
            step: 1,
          },
        },
        {
          path: "aws-enterprise/credentials",
          name: "mp.ent.subscribe",
          component: CloudCredentials,
          meta: {
            subscriptionType: "AWS_ENTERPRISE",
            provider: "AWS",
            step: 2,
          },
        },
        {
          path: "aws-enterprise/environment",
          name: "mp.ent.environment",
          component: CloudEnvironmentData,
          meta: {
            subscriptionType: "AWS_ENTERPRISE",
            provider: "AWS",
            step: 3,
          },
        },
        {
          path: "aws-enterprise/waiting",
          name: "mp.ent.waiting",
          component: CloudWaitingRoom,
          meta: {
            subscriptionType: "AWS_ENTERPRISE",
            provider: "AWS",
            step: 4,
          },
        },
        {
          path: "aws-enterprise-plus",
          name: "mp.ent.plus.account",
          component: CloudAccount,
          meta: {
            subscriptionType: "AWS_ENTERPRISE_PLUS",
            provider: "AWS",
            step: 1,
          },
        },
        {
          path: "aws-enterprise-plus/credentials",
          name: "mp.ent.plus.subscribe",
          component: CloudCredentials,
          meta: {
            subscriptionType: "AWS_ENTERPRISE_PLUS",
            provider: "AWS",
            step: 2,
          },
        },
        {
          path: "aws-enterprise-plus/environment",
          name: "mp.ent.plus.environment",
          component: CloudEnvironmentData,
          meta: {
            subscriptionType: "AWS_ENTERPRISE_PLUS",
            provider: "AWS",
            step: 3,
          },
        },
        {
          path: "aws-enterprise-plus/waiting",
          name: "mp.ent.plus.waiting",
          component: CloudWaitingRoom,
          meta: {
            subscriptionType: "AWS_ENTERPRISE_PLUS",
            provider: "AWS",
            step: 4,
          },
        },
      ],
    },
    {
      path: "/marketplace/gcp",
      name: "marketplaceGCP",
      component: MPLandingGCP,
      meta: {
        steps: ["mp.gcp.waiting"],
        enterpriseSteps: ["mp.gcp.waiting"],
      },
      children: [
        {
          path: "self-hosted",
          name: "mp.gcp.waiting",
          component: MPWaitingRoomGCP,
          meta: {
            subscriptionType: "BOM_BASED",
            provider: "GCP",
            step: 1,
          },
        },
      ],
    },
    {
      path: "/register/trial-enterprise/waiting",
      name: "register",
      component: CloudServerActivation,
      meta: {
        subscriptionType: "TRIAL_ENTERPRISE",
        steps: ["register.waiting"],
      },
    },
    {
      path: "/register/pro",
      name: "register.pro.waiting",
      component: CloudServerActivation,
      meta: {
        subscriptionType: "PRO_TEAM_MONTHLY",
        steps: ["register.pro.waiting"],
      },
    },
    {
      path: "/register/enterprise-x",
      name: "register.enterprise-x.waiting",
      component: CloudServerActivation,
      meta: {
        subscriptionType: "ENTERPRISE_X_MONTHLY",
        steps: ["register.enterprise-x.waiting"],
      },
    },
    {
      path: "/register/trial-enterprise/loadingPage",
      name: "CloudTrialActivationViaSuccessMail",
      component: CloudTrialActivationViaSuccessMail,
    },
    {
      path: "/registration",
      name: "registration",
      component: CloudLanding,
      meta: {
        trialEnterprisePlusSteps: ["trial.ent.plus.subscribe", "trial.ent.plus.environment", "trial.ent.plus.waiting"],
      },
      children: [
        {
          path: "trial-ent-plus",
          name: "trial.ent.plus.subscribe",
          component: RegistrationCredentials,
          meta: {
            subscriptionType: "TRIAL_ENTERPRISE_PLUS",
            provider: "SF",
            step: 1,
          },
        },
        {
          path: "trial-ent-plus/environment",
          name: "trial.ent.plus.environment",
          component: RegistrationEnvironmentData,
          meta: {
            subscriptionType: "TRIAL_ENTERPRISE_PLUS",
            provider: "SF",
            step: 2,
          },
        },
        {
          path: "trial-ent-plus/waiting",
          name: "trial.ent.plus.waiting",
          component: CloudWaitingRoom,
          meta: {
            subscriptionType: "TRIAL_ENTERPRISE_PLUS",
            provider: "SF",
            step: 3,
          },
        },
        {
          path: "trial-ent-plus/error",
          name: "trial.ent.plus.error",
          component: RegistrationError,
          meta: {
            subscriptionType: "TRIAL_ENTERPRISE_PLUS",
            provider: "SF",
            step: 0,
            pageType: "ERROR",
          },
        },
      ],
    },
    {
      path: "/quick-purchase",
      name: "quick-purchase",
      component: QuickPurchaseNewWrapper,
      beforeEnter: multiguard([quickPurchase.canAccess]),
      children: [
        {
          path: "",
          redirect: { name: "quick-purchase-pricing" },
        },
        {
          path: "pricing",
          name: "quick-purchase-pricing",
          component: QuickPurchaseNew,
          beforeEnter: multiguard([quickPurchase.noErrors, quickPurchase.stillNotUpgraded]),
        },
        {
          path: "checkout",
          name: "quick-purchase-checkout",
          component: QuickPurchaseCheckout,
          beforeEnter: multiguard([
            quickPurchase.noErrors,
            quickPurchase.stillNotUpgraded,
            quickPurchase.canAccessCheckout,
          ]),
          props: true,
        },
        {
          path: "get-a-quote",
          name: "quick-purchase-get-a-quote",
          component: QuickPurchaseGetAQuote,
          beforeEnter: multiguard([
            quickPurchase.noErrors,
            quickPurchase.stillNotUpgraded,
            quickPurchase.canAccessGetAQuote,
          ]),
          props: true,
        },
        {
          path: "thank-you",
          name: "quick-purchase-thank-you",
          component: QuickPurchaseThankYou,
          beforeEnter: multiguard([quickPurchase.noErrors, quickPurchase.canAccessThankYou]),
          props: true,
        },
        {
          path: "already-upgraded",
          name: "quick-purchase-already-upgraded",
          component: QuickPurchaseAlreadyUpgraded,
          beforeEnter: quickPurchase.canAccessAlreadyUpgraded,
          props: true,
        },
        {
          path: "upgrade-in-progress",
          name: "quick-purchase-upgrade-in-progress",
          component: QuickPurchaseUpgradeInProgress,
          beforeEnter: quickPurchase.canAccessUpgradeInProgress,
          props: true,
        },
        {
          path: "error",
          name: "quick-purchase-error",
          component: QuickPurchaseError,
          beforeEnter: quickPurchase.canAccessError,
        },
        {
          path: "invalid-or-expired-token",
          name: "quick-purchase-invalid-or-expired-token",
          component: QuickPurchaseInvalidOrExpiredToken,
          beforeEnter: quickPurchase.canAccessError,
        },
      ],
    },
    {
      path: "/mp-unified",
      name: "mp-unified",
      component: MPUnifiedLanding,
      meta: {
        steps: ["mp.unified.login", "mp.unified.details", "mp.unified.environment", "mp.unified.waiting"],
        tokenSteps: ["mp.unified.login", "mp.unified.server.token", "mp.unified.done"],
      },
      children: [
        {
          path: "login",
          name: "mp.unified.login",
          component: MPUnifiedLogin,
          meta: {
            step: 1,
          },
        },
        {
          path: "token",
          name: "mp.unified.server.token",
          component: MPUnifiedServerToken,
          meta: {
            step: 2,
          },
        },
        {
          path: "details",
          name: "mp.unified.details",
          component: MPUnifiedDetailsForm,
          meta: {
            step: 2,
          },
        },
        {
          path: "environment",
          name: "mp.unified.environment",
          component: MPUnifiedEnvironmentData,
          meta: {
            step: 3,
          },
        },
        {
          path: "waiting",
          name: "mp.unified.waiting",
          component: MPUnifiedWaitingRoom,
          meta: {
            step: 4,
          },
        },
        {
          path: "error",
          name: "mp.unified.error",
          component: MPUnifiedError,
          meta: { step: 4 },
        },
        {
          path: "done",
          name: "mp.unified.done",
          component: MPUnifiedError,
          meta: { step: 3 },
        },
      ],
    },
    {
      path: "/register/sso",
      name: "register.sso.response",
      component: SSOResponse,
      meta: {
        step: 1,
      },
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance,
      meta: {
        step: 1,
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
};

export default new Router(routerOptions);
