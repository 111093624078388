import { RegistrationsService } from "@/types/services/registrations";
import {
  MPSelfHostedResponse,
  MPSelfHostedRequest,
  MarketplaceRegistrationRequest,
  MPTokenRequestSubmitRequest,
  MPVerifyTokenResponse,
  RegionDTO,
  RegionsListResponse,
  ServerNameValidationRequest,
  SubmitNewServerResponse,
  CloudProviderCode,
  MarketoSubmitWithServerRequest,
  ServerActivationResponse,
  EnterprisePlusMultiServersCommercialRequest,
  PricingComparisonDataRow,
  MPUnifiedRegistrationResponse,
  MPUnifiedRegistrationRequest,
  MPUnifiedVerifyTokenRequest,
  MPUnifiedVerifyTokenResponse,
  JfrogTokenRequest,
  JumpBasicResponse,
  UpdateSubscriptionRequest,
  UpdateSubscriptionResponse,
} from "@jfrog-ba/myjfrog-common";

export interface ServiceError {
  errorCode?: number;
  message?: string;
  errorMessage: string;
}

export interface ServiceResponse<T> {
  body?: T | ServiceError;
  status: number;
}
import apiProxy from "../util/api-proxy";
import apiProxyV2 from "../util/api-proxy-v2";

import logger from "@/util/logger";
import { SsoLoginInfo, SsoOAuthRequest } from "@jfrog-ba/myjfrog-common";

export const registrationsService: RegistrationsService = {
  checkServerName: async (request: ServerNameValidationRequest) => {
    return apiProxy.post(`registrations/checkServerName`, request);
  },
  getRegionsList: async (cloudProviderCode): Promise<RegionsListResponse> => {
    const response: RegionsListResponse | null = await apiProxy.get("registrations/regionsList");
    if (!response) {
      throw new Error("Could not load regions list");
    }
    const regions = transformCloudRegions(response.regions, cloudProviderCode);
    return { regions };
  },
  marketplaceSubmit: async (request: MarketplaceRegistrationRequest): Promise<SubmitNewServerResponse> => {
    const response: SubmitNewServerResponse | null = await apiProxy.post(`registrations/marketplaceSubmit`, request);

    if (!response) {
      throw new Error("Could not submit new marketplace server purchase");
    }
    return response;
  },
  marketplaceSHSubmit: async (request: MPSelfHostedRequest): Promise<ServiceResponse<MPSelfHostedResponse>> => {
    const response: ServiceResponse<MPSelfHostedResponse> | null = await apiProxy.post(
      `registrations/marketplaceSHSubmit`,
      request,
    );
    if (response == null) {
      throw new Error("POST registrations/marketplaceSHSubmit return empty response");
    }
    return response;
  },
  registerSelfHostedStatus: async (request: MPSelfHostedRequest): Promise<ServiceResponse<MPSelfHostedResponse>> => {
    logger.info("start registerSelfHostedStatus");
    const response: ServiceResponse<MPSelfHostedResponse> | null = await apiProxy.post(
      `registrations/registerSelfHostedStatus`,
      request,
    );
    if (response == null) {
      throw new Error("POST registrations/registerSelfHostedStatus return empty response");
    }
    return response;
  },
  marketplaceEnterprisePlusSubmit: async (
    request: MarketplaceRegistrationRequest,
  ): Promise<SubmitNewServerResponse> => {
    const response: SubmitNewServerResponse | null = await apiProxy.post(
      `registrations/marketplaceEPlusSubmit`,
      request,
    );

    if (!response) {
      throw new Error("Could not submit new marketplace E+ server purchase");
    }
    return response;
  },
  trialEnterprisePlusSubmit: async (
    request: EnterprisePlusMultiServersCommercialRequest,
  ): Promise<SubmitNewServerResponse> => {
    const response: SubmitNewServerResponse | null = await apiProxy.post(`registrations/trialEPlusSubmit`, request);

    if (!response) {
      throw new Error("Could not submit new trial E+ server order");
    }
    return response;
  },
  verifyMarketplaceToken: async (request: MPTokenRequestSubmitRequest): Promise<MPVerifyTokenResponse> => {
    const response: MPVerifyTokenResponse | null = await apiProxy.post(`mp/verify-token`, request);
    if (!response) {
      throw new Error("Could not verify marketplace token");
    }
    return response;
  },
  marketplaceMarketo: async (request: MarketplaceRegistrationRequest): Promise<void> => {
    await apiProxy.post(`registrations/mp-marketo`, request);
  },
  enterpriseTrialMarketo: async (request: MarketoSubmitWithServerRequest): Promise<void> => {
    await apiProxy.post(`registrations/enterprise-trial-marketo`, request);
  },
  trialEnterpriseActivation: async (activationCode: string): Promise<ServerActivationResponse> => {
    const response: ServerActivationResponse | null = await apiProxy.post(`registrations/enterprise-trial-activation`, {
      activationCode,
    });
    if (!response) {
      throw new Error(`Could not activate server with activationCode: '${activationCode}'`);
    }
    return response;
  },
  priceComparisonData: async (): Promise<PricingComparisonDataRow[]> => {
    const response: PricingComparisonDataRow[] | null = await apiProxy.get(`registrations/shared/pricing-list`, {});
    if (!response) {
      throw new Error(`Could not get pricing comparison json data`);
    }
    return response;
  },
  quickPurchasePageContent: async (): Promise<any> => {
    const response: any = await apiProxy.get(`registrations/shared/quick-purchase-content`, {});
    if (!response) {
      throw new Error(`Could not get quick purchase page content`);
    }
    return response;
  },
  extendSalesforceInvitation: async (token: string): Promise<void> => {
    await apiProxy.post(`sf/eplus-trial/extendInvitation`, { token });
  },
  directPurchaseNewServerSubmit: async (
    request: MPUnifiedRegistrationRequest,
  ): Promise<MPUnifiedRegistrationResponse> => {
    const response: MPUnifiedRegistrationResponse | null = await apiProxy.post(
      `registrations/mp-unified-submit`,
      request,
    );

    if (!response) {
      throw new Error("Could not submit new direct pro server purchase");
    }
    return response;
  },
  getOAuthAccessToken: async (request: SsoOAuthRequest): Promise<SsoLoginInfo> => {
    const response: SsoLoginInfo | null = await apiProxy.post(`registrations/getOAuthAccessToken`, { ...request });
    if (!response) {
      throw new Error("Could not get OAuth access token from gitHub");
    }
    return response;
  },
  marketplaceUnifiedSubmit: async (request: MPUnifiedRegistrationRequest): Promise<MPUnifiedRegistrationResponse> => {
    const response: MPUnifiedRegistrationResponse | null = await apiProxyV2.post(`mp/submit`, { ...request });
    if (!response) {
      throw new Error(`Could not submit new marketplace unified request for user with email '${request.email}'`);
    }
    return response;
  },
  verifyToken: async (request: MPUnifiedVerifyTokenRequest): Promise<MPUnifiedVerifyTokenResponse> => {
    const response: MPUnifiedVerifyTokenResponse | null = await apiProxyV2.post(`mp/verify-token`, { ...request });
    if (!response) {
      throw new Error(`Could not verify token for mp token '${request.mpToken}'`);
    }
    return response;
  },
  getZuoraCountries: async (): Promise<string[]> => {
    const response: string[] | null = await apiProxy.get(`registrations/countries`);
    if (!response) {
      throw new Error(`Could not get countries list`);
    }
    return response;
  },
  sendUpdateSubscriptionToken: async (request: JfrogTokenRequest): Promise<JumpBasicResponse> => {
    const response: JumpBasicResponse | null = await apiProxyV2.post(`mp/send-jfrog-token`, { ...request });
    if (!response) {
      throw new Error(`Error on sending jfrog token '${request.updateSubscriptionModel?.serverName}'`);
    }
    return response;
  },
  updateSubscription: async (request: UpdateSubscriptionRequest): Promise<UpdateSubscriptionResponse> => {
    const response: UpdateSubscriptionResponse | null = await apiProxyV2.post(`mp/update-subscription`, {
      ...request,
    });
    if (!response) {
      throw new Error(`Error on sending jfrog token '${request.updateSubscriptionModel?.serverName}'`);
    }
    return response;
  },
};

const transformCloudRegions = (regions: RegionDTO[], providerCode: CloudProviderCode): RegionDTO[] => {
  return regions.filter(region => region.providerCode === providerCode);
};
