import { render, staticRenderFns } from "./FadeSpinner.vue?vue&type=template&id=25da1270&scoped=true&"
import script from "./FadeSpinner.vue?vue&type=script&lang=ts&"
export * from "./FadeSpinner.vue?vue&type=script&lang=ts&"
import style0 from "./FadeSpinner.vue?vue&type=style&index=0&id=25da1270&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25da1270",
  null
  
)

export default component.exports