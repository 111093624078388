import Vue from "vue";
import Vuex from "vuex";
import application from "./modules/application";
import authentication from "./modules/authentication";

import counter from "./modules/counter";
import dynamicHeader from "./modules/dynamicHeader";
import quickPurchase from "./modules/quickPurchase";
import subscriptions from "./modules/subscriptions";
import isSynced from "./modules/sync";
import user from "./modules/user";
import featureNotification from "./modules/featureNotification";
import productActivation from "./modules/productActivation";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    counter,
    application,
    user,
    subscriptions,
    isSynced,
    authentication,
    quickPurchase,
    dynamicHeader,
    featureNotification,
    productActivation,
  },
});
