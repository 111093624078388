const marketoCookieName = "_mkto_trk";

let marketoCookie: string;

export default (): string | undefined => {
  if (marketoCookie) {
    return marketoCookie;
  }

  const cookies = document.cookie.split("; ");

  const prefix = `${marketoCookieName}=`;

  for (const cookieStr of cookies) {
    if (cookieStr.startsWith(prefix)) {
      marketoCookie = cookieStr.substring(prefix.length);

      return marketoCookie;
    }
  }
};
