import { subscriptionsService } from "@/services/subscriptions";
import { usersService } from "@/services/user";
import store from "@/store/store";
import { FeatureNotificationsService } from "@/types/services/featureNotifications";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";

export const featureNotificationService: FeatureNotificationsService = {
  resetAllStates: async () => {
    await store.dispatch("featureNotification/ePlusConfigNewServerNotificationClosedOnPurpose", false);
  },
  getEPlusConfigurationNotificationConfig: async () => {
    const user = await usersService.getVuexUser();
    if (!user) {
      return { canDisplay: false };
    }
    const subscriptions: SubscriptionDTO[] = await subscriptionsService.getSubscriptions();
    const getEnterprisePlusAccountNumberToConfigure: SubscriptionDTO[] = subscriptions.filter(s =>
      featureNotificationService.subscriptionCanConfigureNewInstances(s),
    );
    const nbSubscriptionWithServersToConfigure = getEnterprisePlusAccountNumberToConfigure.length;
    const canDisplay = nbSubscriptionWithServersToConfigure > 0;
    const canDisplayCta = canDisplay && nbSubscriptionWithServersToConfigure === 1;
    const enterprisePlusAccount = (canDisplayCta && getEnterprisePlusAccountNumberToConfigure[0]) || null;
    const enterprisePlusAccountNumber = (enterprisePlusAccount && enterprisePlusAccount.accountNumber) || undefined;
    const html = "<span bold>Your purchased instances are ready to set up!</span>";
    const ctaBtnText = "Get Started";
    return {
      canDisplay,
      data: {
        canDisplayCta,
        enterprisePlusAccountNumber,
        nbSubscriptionWithServersToConfigure,
        html,
        ctaBtnText,
      },
    };
  },
  subscriptionCanConfigureNewInstances: subscription => {
    const meta = subscription.meta;

    return (
      !!meta.isEnterprisePlus &&
      !meta.isTrial &&
      (subscription.nbEdgesConfigurable > 0 || subscription.nbInstanceConfigurable > 0)
    );
  },
};
