
import { Component, Prop, Vue } from "vue-property-decorator";
import DynamicImage from "@/components/common/DynamicImage.vue";
import { AolPurchaseSubmitNewServerRequest, SsoLoginInfo } from "@jfrog-ba/myjfrog-common";

declare var GITHUB_CLIENT_ID: any;
declare var GITHUB_SSO_REDIRECT_URL: any;
declare var window: any;
@Component({
  name: "GitHubLogin",
  components: { DynamicImage },
})
export default class GitHubLogin extends Vue {
  //instructions https://docs.github.com/en/developers/apps/building-oauth-apps/authorizing-oauth-apps
  ssoLoginSuccess = false;
  data!: AolPurchaseSubmitNewServerRequest;

  mounted() {
    this.fillDataFromSSOResponse();
  }

  private fillDataFromSSOResponse() {
    const ssoLoginInfo: SsoLoginInfo = JSON.parse(sessionStorage.getItem("ssoLoginInfo") as string);
    if (!ssoLoginInfo || !ssoLoginInfo.userIdentifier) {
      this.$emit("onSsoFailure");
      return;
    }
    this.$emit("onSsoSignIn", ssoLoginInfo);
    this.ssoLoginSuccess = true;
  }

  get buttonText() {
    return this.ssoLoginSuccess ? "Signed in with GitHuB" : "GitHuB";
  }

  redirectToGitHub() {
    if (this.ssoLoginSuccess) {
      return;
    }
    sessionStorage.setItem("ssoRedirectAfterSuccess", window.location.href);
    const clientId = GITHUB_CLIENT_ID;
    const redirectUri = GITHUB_SSO_REDIRECT_URL.replace("STEP_PLACEHOLDER", "sso");
    const state = "randomString_" + new Date().getTime();
    window.location.href = `https://github.com/login/oauth/authorize/?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=user:email`;
  }
}
