
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { UserDTO, isRestClientError, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "ModalMfaCancelEnrollment",
  components: {
    LoadingMask,
    JFCheckBox,
  },
})
export default class ModalMfaCancelEnrollment extends Vue {
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  isLoading = false;
  cbChecked = true;

  mounted() {
    this.$jfModal.childComponentIsSet();
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get checkboxLabel() {
    return "Enable Multi-Factor Authentication";
  }

  get sendButtonDisabled() {
    return this.isLoading || this.cbChecked;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  get userAccountNumber() {
    return this.user && this.user.subscriptionsAccountNumbers[0];
  }

  async cancelEnrollment() {
    if (!this.user) {
      return;
    }
    try {
      this.isLoading = true;
      let userAccountNumber = this.userAccountNumber;
      if (!userAccountNumber) {
        throw new Error("Unable to define user account number");
      }
      await this.$jfSubscriptions.cancelMfaEnrollment({ reason: null, username: this.user.userName });

      this.$jfNotification.success({
        text: this.$jfMessages.mfa_enrollment_cancellation_success,
      });
      this.$jfWebeyez.send({ goal_key: "mfa_cancel", isSuccess: true });
      this.user.mfaActivated = false;
      this.$jfUsers.refreshVueXUser(this.user);
      this.dismiss();
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      let errorMessage = this.$jfMessages.mfa_enrollment_cancellation_error;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.mfa.enrollment.cancel.userNotEnrolledToMfa) {
          errorMessage = this.$jfMessages.mfa_enrollment_cancellation_error_already_disabled;
        }
      }
      this.$jfNotification.error({ text: errorMessage });
      this.$jfWebeyez.send({ goal_key: "mfa_cancel", isSuccess: false, errorMessage: errorMessage });
    }
  }
}
