
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { JFCheckBox, JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { MPUnifiedRegistrationRequest } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "MPUnifiedDetailsForm",
  $_veeValidate: { validator: "new" },
  components: { JFCheckBox, JFFormControl, JFTextField },
})
export default class MPUnifiedDetailsForm extends Vue {
  @Inject() readonly globalBus!: Vue;
  data: MPUnifiedRegistrationRequest = {
    firstName: "",
    lastName: "",
    userName: "",
    country: "",
    companyName: "",
    phone: "",
    email: "",
    password: "",
    mpToken: "",
    mpOrigin: "",
    mpBuyerId: "",
    forcePublicOffer: false,
    onlyCheckSSO: false,
    servers: [],
    serverName: "",
    regionCode: "",
  };
  details: any;
  canSubmitForm = false;
  isAws = false;
  countries: string[] = [];

  async created() {
    this.loadFormDataFromSession();
    this.countries = await this.$jfRegistrations.getZuoraCountries();
  }

  @Watch("data", { deep: true })
  onDataChange() {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 300);
  }

  async handleOnDataChange() {
    if (this.errors.any() || (this.isAws && !this.data.mpBuyerId)) {
      this.$emit("customerDetailsFilled", false);
      return;
    }
    sessionStorage.setItem("registrationFormData", JSON.stringify(this.data));
    if (this.data.firstName && this.data.lastName && this.data.companyName && this.data.phone && this.data.country) {
      this.$emit("customerDetailsFilled", true);
    }
  }
  loadFormDataFromSession() {
    let data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }
    this.data = JSON.parse(data);
    this.isAws = this.data.mpOrigin === "AWS_MP";
  }
}
