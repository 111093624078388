import { QuickPurchaseData } from "@/types/localtypes";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class QuickPurchase extends VuexModule {
  _quickPurchaseData: QuickPurchaseData | null = null;

  @Mutation
  _setQuickPurchaseData(quickPurchaseData: QuickPurchaseData) {
    this._quickPurchaseData = quickPurchaseData;
  }

  @Action({ commit: "_setQuickPurchaseData" })
  setQuickPurchaseData(quickPurchaseData: QuickPurchaseData) {
    return quickPurchaseData;
  }

  @Action({ commit: "_setQuickPurchaseData" })
  setHasUpgraded(hasUpgraded: boolean) {
    return { ...this._quickPurchaseData, hasUpgraded } as QuickPurchaseData;
  }

  @Action({ commit: "_setQuickPurchaseData" })
  clearQuickPurchaseData() {
    return null;
  }

  get quickPurchaseData() {
    return this._quickPurchaseData;
  }
}
