
import { Component, Vue } from "vue-property-decorator";
import { CloudActivationStatus } from "@jfrog-ba/myjfrog-common";
import { registrationsService } from "@/services/registrations";
import MPUnifiedActivationFlow from "@/components/landingPages/marketplaceUnified/MPUnifiedActivationFlow.vue";
declare var window: any;

@Component({
  name: "MPUnifiedWaitingRoom",
  components: { MPUnifiedActivationFlow },
})
export default class MPUnifiedWaitingRoom extends Vue {
  hasInternalError = false;
  intervalCheckStatus!: any;
  currentStatus: CloudActivationStatus = "IN_PROGRESS";
  isDelayed: boolean = false;
  isReady: boolean = false;
  internalElapsedTime = 0;
  delayBetweenCheck = 7;
  waitingRoomData = {
    token: "",
    serverName: "",
    regionName: "",
    instanceUrl: "",
    cloudProvider: "",
  };

  created() {
    this.loadPreviousFormData();
  }

  mounted() {
    this.checkStatus();
    this.initStatusCheck();
    this.sendToMarketo();
  }

  loadPreviousFormData() {
    const waitingRoomData = sessionStorage.getItem("waitingRoomData");
    if (waitingRoomData) {
      this.waitingRoomData = JSON.parse(waitingRoomData);
    }
  }

  async sendToMarketo() {
    const data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }
    const allFormsData = JSON.parse(data);
    allFormsData.googleClientId = this.$jfMarketo.getCookie();
    allFormsData.cloudProvider = this.waitingRoomData.cloudProvider.toLowerCase();
    allFormsData.uiReferralLandingPage = sessionStorage.getItem("uiReferralLandingPage");
    allFormsData.marketoCookie = this.$jfMarketo.getCookie();
    if (!allFormsData.subscriptionType) {
      const verifyTokenResponse: string = sessionStorage.getItem("verifyTokenResponse") as string;
      allFormsData.subscriptionType = JSON.parse(verifyTokenResponse).paymentType;
    }
    try {
      await registrationsService.marketplaceMarketo(allFormsData);
    } catch (e) {}
  }

  initStatusCheck() {
    this.handleNewStatus(this.currentStatus);
    if (this.canCheckStatus()) {
      this.refreshIntervalCheck();
    }
  }

  refreshIntervalCheck() {
    if (this.intervalCheckStatus) {
      clearInterval(this.intervalCheckStatus);
    }
    this.intervalCheckStatus = setInterval(() => {
      this.checkStatus();
    }, this.delayBetweenCheck * 1000);
  }

  async checkStatus() {
    if (!this.canCheckStatus()) {
      return;
    }
    try {
      this.incrementElapsedTime();
      const statusResponse = await this.$jfCloudActivations.getStatus({
        serverName: this.waitingRoomData.serverName,
        token: this.waitingRoomData.token,
      });
      this.handleNewStatus(statusResponse.activationStatus);
    } catch (e) {
      this.$log.error(e);
      this.hasInternalError = true;
    }
  }

  canCheckStatus() {
    switch (this.currentStatus) {
      case "IN_PROGRESS":
      case "DELAYED":
        return true;
      case "READY":
      default:
        return false;
    }
  }

  handleNewStatus(newStatus: CloudActivationStatus) {
    this.currentStatus = newStatus;
    switch (newStatus) {
      case "READY":
        clearInterval(this.intervalCheckStatus);
        this.isReady = true;
        this.isDelayed = false;
        break;
      case "DELAYED":
        this.isDelayed = true;
        break;
    }
  }

  incrementElapsedTime() {
    this.$jfCloudActivations.incrementElapsedTime(this.delayBetweenCheck);
    this.internalElapsedTime += this.delayBetweenCheck;
  }
}
