import { CookiesService } from "@/types/services/cookies";
import { Vue } from "vue-property-decorator";

declare const COOKIES_DOMAIN: string;
const expireInHours = 24;
export const cookiesService: CookiesService = {
  getExpiration: () => {
    const now = new Date();
    now.setTime(now.getTime() + expireInHours * 3600 * 1000);
    return now;
  },

  getDomain: () => COOKIES_DOMAIN,

  setLightUpgradeInSuccess: accountNumber => {
    Vue.$cookies.set(
      "__MJF_LIGHT_UPGRADE_IN_SUCCESS",
      accountNumber,
      cookiesService.getExpiration(),
      "/",
      cookiesService.getDomain(),
    );
  },
};
