
import { AnyObject } from "@/types/localtypes";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "FadeSpinner",
  components: {},
})
export default class FadeSpinner extends Vue {
  @Prop({ default: true })
  private loading!: boolean;
  @Prop({ default: false })
  private reverseAnimation!: boolean;
  @Prop({ default: "#000000" })
  private color!: string;
  @Prop({ default: "100" })
  private containerSize!: number;
  @Prop({ default: 5 })
  private dotWidth!: number;
  @Prop({ default: 15 })
  private dotHeight!: number;
  @Prop({ default: "2px" })
  private margin!: string;
  @Prop({ default: 20 })
  private radius!: number;
  @Prop({ default: "20px" })
  private borderRadius!: string;

  dotStyle: AnyObject = {
    backgroundColor: this.color,
    height: this.dotHeight + "px",
    width: this.dotWidth + "px",
    margin: this.margin + "px",
    borderRadius: this.borderRadius,
  };

  nbDots = 8;

  get wrapperStyle() {
    return {
      position: "relative",
      fontSize: 0,
      width: `${this.containerSize}px`,
      height: `${this.containerSize}px`,
    };
  }

  get quarter() {
    return this.radius / 2 + this.radius / 5.5;
  }

  get topCenter() {
    return this.containerSize / 2 - this.dotHeight / 2;
  }
  get leftCenter() {
    return this.containerSize / 2 - this.dotWidth / 2;
  }

  animationDelay(index: number) {
    index = this.reverseAnimation ? this.nbDots - index : index;
    return `${(index - 1) * 0.12}s`;
  }
  get animationStyle1() {
    return {
      top: `${this.topCenter - this.radius}px`,
      left: `${this.leftCenter}px`,
      animationDelay: this.animationDelay(1),
    };
  }

  get animationStyle2() {
    return {
      top: `${this.topCenter - this.quarter}px`,
      left: `${this.leftCenter + this.quarter}px`,
      animationDelay: this.animationDelay(2),
      transform: "rotate(45deg)",
    };
  }

  get animationStyle3() {
    return {
      top: `${this.topCenter}px`,
      left: `${this.leftCenter + this.radius}px`,
      animationDelay: this.animationDelay(3),
      transform: "rotate(90deg)",
    };
  }

  get animationStyle4() {
    return {
      top: `${this.topCenter + this.quarter}px`,
      left: `${this.leftCenter + this.quarter}px`,
      animationDelay: this.animationDelay(4),
      transform: "rotate(-45deg)",
    };
  }

  get animationStyle5() {
    return {
      top: `${this.topCenter + this.radius}px`,
      left: `${this.leftCenter}px`,
      animationDelay: this.animationDelay(5),
    };
  }

  get animationStyle6() {
    return {
      top: `${this.topCenter + this.quarter}px`,
      left: `${this.leftCenter - this.quarter}px`,
      animationDelay: this.animationDelay(6),
      transform: "rotate(45deg)",
    };
  }

  get animationStyle7() {
    return {
      top: `${this.topCenter}px`,
      left: `${this.leftCenter - this.radius}px`,
      animationDelay: this.animationDelay(7),
      transform: "rotate(90deg)",
    };
  }

  get animationStyle8() {
    return {
      top: `${this.topCenter - this.quarter}px`,
      left: `${this.leftCenter - this.quarter}px`,
      animationDelay: this.animationDelay(8),
      transform: "rotate(-45deg)",
    };
  }
}
