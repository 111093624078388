
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SpinnerProperties } from "@/types/spinners";
import DefaultSpinner from "@/components/spinners/DefaultSpinner.vue";

@Component({
  name: "LoadingMask",
  components: { DefaultSpinner },
})
export default class LoadingMask extends Vue {
  @Prop({ default: false })
  private loading!: boolean;
  @Prop({ default: false })
  private dark!: boolean;
  @Prop({ default: false })
  private thick!: boolean;
  @Prop({ default: false })
  private flex!: boolean;
  @Prop({ default: true })
  private spinnerFlex!: boolean;
  @Prop({ default: false })
  private autoHeight!: boolean;
  @Prop({ default: 1000 })
  private zIndex!: number;
  @Prop({ default: () => DefaultSpinner })
  private spinnerComponent!: object;
  @Prop({
    default: () => {
      return {
        size: "l",
        color: "#373737",
      };
    },
  })
  private spinnerConfig!: SpinnerProperties;

  get loadingMaskBgClass() {
    let cls = [];
    if (this.thick) {
      cls.push("thick");
    }
    cls.push(this.dark ? "loading-mask-dark " : "loading-mask-light ");
    if (this.spinnerFlex) {
      cls.push("loading-mask-flex");
    }
    return cls;
  }

  get loadingMaskStyles() {
    return {
      zIndex: this.zIndex,
    };
  }

  mounted() {}
}
