
import { Vue, Component } from "vue-property-decorator";
import FeatureNotificationsEPlusAddServers from "@/components/layout/notifications/feature/FeatureNotificationsEPlusAddServers.vue";
@Component({
  name: "FeatureNotifications",
  components: { FeatureNotificationsEPlusAddServers },
})
export default class FeatureNotifications extends Vue {
  destroyed() {
    this.$jfNotification.clearAll("feature");
  }
}
