
import MenuItem from "@/components/layout/MenuItem.vue";
import { MenuConfiguration, MenuItemProps } from "@/types/services/menus";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "Menu",
  components: { MenuItem },
})
export default class Menu extends Vue {
  @Getter("paymentAccountNumbers", { namespace: "user" })
  paymentAccountNumbers!: string[];
  @Getter("menuConfiguration", { namespace: "application" })
  menuConfiguration!: MenuConfiguration;
  mainMenuItems: MenuItemProps[] = [];
  secondaryMenuItems: MenuItemProps[] = [];

  @Watch("paymentAccountNumbers")
  onUserChanged() {
    this.loadMainMenuItems();
  }
  @Watch("menuConfiguration")
  onMenuConfigurationChanged() {
    this.loadMainMenuItems();
    this.loadSecondaryMenuItems();
  }

  mounted() {
    this.loadMainMenuItems();
    this.loadSecondaryMenuItems();
  }

  MenuItemStyle(index: number, numOfItems: number) {
    const classes = [];
    if (numOfItems !== 0) {
      classes.push("pr-3");
    }
    if (index === 0) {
      classes.push("border-none");
    }
    return classes;
  }

  async loadMainMenuItems() {
    this.mainMenuItems = await this.$jfMenus.getMainMenu(this.menuConfiguration);
  }
  loadSecondaryMenuItems() {
    this.secondaryMenuItems = this.$jfMenus.getActionMenu(this.menuConfiguration);
  }

  get hasSecondaryMenuItems() {
    return !!this.secondaryMenuItems && this.secondaryMenuItems.length;
  }
}
