import { render, staticRenderFns } from "./RegistrationEnvironmentData.vue?vue&type=template&id=8629da00&scoped=true&"
import script from "./RegistrationEnvironmentData.vue?vue&type=script&lang=ts&"
export * from "./RegistrationEnvironmentData.vue?vue&type=script&lang=ts&"
import style0 from "./RegistrationEnvironmentData.vue?vue&type=style&index=0&id=8629da00&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8629da00",
  null
  
)

export default component.exports