/**
 * This file shows the 'TypeScript way' to create a plugin for Vue.
 */
import _Vue from "vue";
import myJFrogUtils from "@/util/myJFrogUtils";
import dateUtils from "@/util/dateUtils";
export function myJFrogUtilsPlugin<MyJFrogUtilsPluginOptions>(
  Vue: typeof _Vue,
  options?: MyJFrogUtilsPluginOptions,
): void {
  // do stuff with options

  // The code above make a 'Vue augmentation' and insert some helpers in our custom Vue.$utils
  // but TypeScript will NOT reconize it without a extra type definitions.
  // see the type definition of myJFrogUtils in src/types/MyJFrogUtils.ts
  // see the augmentation types declaration in src/types/vueAugmentation.ts
  Vue.prototype.$utils = myJFrogUtils;
  Vue.prototype.$dateUtils = dateUtils;
}

export class MyJFrogUtilsPluginOptions {
  // add stuff
}
