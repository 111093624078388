
import LoadingMask from "@/components/common/LoadingMask.vue";
import { QRCodeVueProps } from "@/types/3rdPartyLibs";
import { LoadingMaskProps } from "@/types/loadingMask";
import { JFCheckBox, JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, storeErrorsMapping, UserDTO } from "@jfrog-ba/myjfrog-common";
import QRCodeVue from "qrcode.vue";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "ModalMfaEnrollment",
  $_veeValidate: { validator: "new" },
  components: {
    JFFormControl,
    JFTextField,
    LoadingMask,
    JFCheckBox,
    QRCodeVue,
  },
})
export default class ModalMfaEnrollment extends Vue {
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  cbChecked = false;
  isLoading = true;
  barCodeUrl: string | null = null;
  otp: string = "";
  step: 1 | 2 = 1;

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.cbChecked && this.isLoading,
    };
  }

  get qrCodeProps(): QRCodeVueProps | null {
    return this.barCodeUrl ? { value: this.barCodeUrl, size: 120 } : null;
  }

  mounted() {
    this.$jfModal.childComponentIsSet();
    this.startEnrollment();
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get sendButtonDataGtmEvent() {
    const context = "mfa-enrollment-activation";
    return this.isStep1 ? `${context}|next-button` : `${context}|confirm-button`;
  }

  get sendButtonLabel() {
    return this.isStep1 ? "Next" : "Confirm";
  }

  get isStep1() {
    return this.step === 1;
  }

  get isStep2() {
    return this.step === 2;
  }

  get checkboxLabel() {
    return "Enable Multi-Factor Authentication";
  }

  get sendButtonDisabled() {
    return this.isLoading || !this.cbChecked || (this.isStep2 && this.errors.any());
  }

  get backButtonDisabled() {
    return this.isLoading || !this.cbChecked;
  }

  onCbChanged() {
    if (!this.cbChecked) {
      this.step = 1;
    }
  }

  get userAccountNumber() {
    return this.user && this.user.subscriptionsAccountNumbers[0];
  }

  onBack() {
    this.otp = "";
    this.step = 1;
    this.errors.clear();
  }

  nextStep() {
    if (this.isStep1) {
      this.step = 2;
      setTimeout(() => {
        ((this.$refs["otp-input"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
      }, 0);
    } else {
      this.completeEnrollment();
    }
  }

  async startEnrollment() {
    if (!this.user) {
      return;
    }
    try {
      this.isLoading = true;
      let userAccountNumber = this.userAccountNumber;
      if (!userAccountNumber) {
        throw new Error("Unable to define user account number");
      }
      let enrollmentResponse = await this.$jfSubscriptions.startMfaEnrollment(userAccountNumber);
      this.barCodeUrl = enrollmentResponse.barcode;
      this.isLoading = false;
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      this.$jfNotification.error({
        title: "Error",
        text: this.$jfMessages.mfa_enrollment_activation_error,
      });
    }
  }

  async completeEnrollment() {
    if (!this.user) {
      return;
    }
    if (!(await this.$validator.validateAll())) {
      return;
    }
    try {
      this.isLoading = true;

      let userAccountNumber = this.userAccountNumber;
      if (!userAccountNumber) {
        throw new Error("Unable to define user account number");
      }
      await this.$jfSubscriptions.completeMfaEnrollment(userAccountNumber, {
        otp: this.otp,
      });
      this.isLoading = false;
      this.$jfNotification.success({
        text: this.$jfMessages.mfa_enrollment_activation_success,
      });
      this.$jfWebeyez.send({ goal_key: "mfa_enrollment", isSuccess: true });
      this.user.mfaActivated = true;
      this.$jfUsers.refreshVueXUser(this.user);
      this.dismiss();
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      let errorMessage = this.$jfMessages.mfa_enrollment_activation_error;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.mfa.enrollment.complete.notMatchingOTPCode) {
          errorMessage = this.$jfMessages.mfa_error_otp_not_match;
        }
      }

      this.$jfNotification.error({ text: errorMessage });
      this.$jfWebeyez.send({ goal_key: "mfa_enrollment", isSuccess: false, errorMessage: errorMessage });
    }
  }
}
