
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { MPUnifiedRouteName, marketplaceRouteMeta } from "@/types/localtypes";
import { Route } from "vue-router";
import { registrationsService } from "@/services/registrations";
import {
  isRestClientError,
  JumpBasicResponse,
  jumpErrorsMapping,
  MPOrigin,
  MPUnifiedRegistrationRequest,
  MPUnifiedVerifyTokenResponse,
  UpdateSubscriptionModel,
  UpdateSubscriptionResponse,
} from "@jfrog-ba/myjfrog-common";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import SvgInline from "@/components/common/SvgInline.vue";
import DynamicImage from "@/components/common/DynamicImage.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import { Alert } from "@/types/layout/contentNode";
import InlineSvg from "vue-inline-svg";
import Notification from "@/components/common/Notification.vue";
declare var window: any;

@Component({
  name: "MPUnifiedLanding",
  components: { Notification, InlineSvg, SimpleAlert, DynamicImage, LoadingMask, SvgInline },
})
export default class MPUnifiedLanding extends Vue {
  @Inject() readonly globalBus!: Vue;
  routeParentChildMeta: marketplaceRouteMeta = {};
  showProceed = true;
  isProceedEnabled = false;
  proceedClicked = new Date(2035, 1, 1).getTime();
  credentialsFilled = false;
  allFormsData!: MPUnifiedRegistrationRequest;
  password = "";
  currentRouteName!: MPUnifiedRouteName;
  isLoading = false;
  environmentDataFilled = false;
  customerDetailsFilled = false;
  verifyTokenTries = 0;
  showAwsWaitingPopup = 0;
  awsAccountIdFilled = false;
  isEnterprise = false;
  isEnterprisePlus = false;
  showBackButton = false;
  showWaitWarning = false;
  showSpinner = false;
  isLoginByServerName = false;
  serverTokenFilled = false;
  currentServerName = "";
  marketplace = "";
  step = 1;
  isDoneStep = false;
  verifyTokenResponse!: MPUnifiedVerifyTokenResponse;
  errorCodesForRedirectToErrorPage = [1006, 1007, 1010, 1011, 2005, 2006, 2007, 4008, 4009, 4010, 4011, 4025, 4026];
  errorCodesForPopup = [4059, 4060, 4061, 4062, 4063];
  errorCodesIgnored = [0, 1009];
  errorResults: number[] = [];
  errorsObj: any[] = [];
  async created() {
    this.getRouteParentChildMeta();
    this.globalBus.$on("submitFormData", () => this.submitFormData());
    this.isLoading = this.$route.name === "mp.unified.login";
  }

  mounted() {
    this.showBackButton = this.isShowBackButton();
    this.setIsProceedEnabled();
  }

  get btnCss() {
    let classes = ["btn"];
    if (
      this.isProceedEnabled ||
      (this.step === 1 && this.credentialsFilled) ||
      (this.step === 1 && this.isLoginByServerName && this.currentServerName)
    ) {
      classes.push("btn-mjf-green");
    } else {
      classes.push("btn-gray");
    }
    return classes;
  }

  setCustomMessages() {
    this.errorsObj.forEach(err => {
      switch (err.code) {
        case 1008:
          err.message = `A communication error occurred while processing your request. Please retry in a few minutes.`;
          break;
        case 4059:
          err.message = `<p>Your server status is inactive.</p> <p>Please provide an active server or sign up to create a new one.</p>`;
          break;
        case 4060:
          err.message = `<p>The provider you are trying to register to is not the provider associated with your JFrog server.</p> <p>Contact <a href="mailto:service@jfrog.com">service@jfrog.com</a> your account manager to migrate provider.</p>`;
          break;
        case 4061:
          err.message = `<p>We noticed your current amount of servers does not match the amount purchased in your new subscription.</p><p> Contact your account manager to adjust your number of servers.</p>`;
          break;
        case 4062:
          err.message = `<p>We noticed your current amount of servers does not match the amount purchased in your new subscription.</p> <p>Contact your account manager to adjust your number of servers.</p>`;
          break;
        case 4063:
          err.message = `<p>We noticed your current server type does not match the new server type you have purchased.</p> <p>Contact your account manager for support.</p>`;
          break;
      }
    });
  }

  getStepText(num: number) {
    switch (num) {
      case 1:
        return this.isLoginByServerName ? "Enter Server name" : "Set credentials";
      case 2:
        return this.isLoginByServerName ? "Enter JFrog token" : "Enter user details";
      case 3:
        return "Create environment";
    }
  }

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    this.getRouteParentChildMeta();
    this.showBackButton = this.isShowBackButton();
    this.setIsProceedEnabled();
  }

  get routeParentChildMetaSteps() {
    if (this.isLoginByServerName) {
      return this.routeParentChildMeta.tokenSteps;
    } else {
      return this.routeParentChildMeta.steps;
    }
  }

  get isFinalPage() {
    return ["mp.unified.done", "mp.unified.error"].includes(this.$route.name as string);
  }
  getRouteParentChildMeta() {
    this.isLoginByServerName = sessionStorage.getItem("isLoginByServerName") === "true";
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });
    this.step = Number(this.routeParentChildMeta.step);

    this.currentRouteName = this.routeParentChildMetaSteps[this.step - 1] as MPUnifiedRouteName;
    const newRoute = this.$route.name as string;
    this.showProceed = this.step !== this.routeParentChildMetaSteps.length && !this.isFinalPage;
    if (newRoute === "mp.unified.done") {
      this.isDoneStep = true;
    }
    const dataStr = sessionStorage.getItem("registrationFormData") as string;
    let data: any = {};
    if (dataStr) {
      data = JSON.parse(dataStr);
    }
    data.subscriptionType = this.routeParentChildMeta.subscriptionType;
    this.allFormsData = data;
    sessionStorage.setItem("registrationFormData", JSON.stringify(data));
  }

  get isErrorStep() {
    return this.$route.name === "mp.unified.error";
  }

  get bgCssClass() {
    const css = [];
    if (this.isDoneStep) css.push("done");
    if (this.isErrorStep) css.push("error");
    if (this.step === 4) css.push("waiting");
    return css.join(" ");
  }

  isActivePage(page: number) {
    if (this.step === page) {
      return "active";
    }
    if (this.step > page) {
      return "passed";
    }
  }

  nextPage() {
    this.showSpinner = this.step === 1 && this.credentialsFilled && !this.verifyTokenResponse?.paymentType;
    if (!this.isProceedEnabled) {
      this.proceedClicked = new Date().getTime();
      return;
    }
    if (this.step === 1) {
      sessionStorage.setItem("uiReferralLandingPage", window.location.origin + window.location.pathname);
    }
    const routeName = this.routeParentChildMetaSteps[this.step];
    this.updateHeapParams("Pending");
    if (routeName === "mp.unified.waiting") {
      this.submitFormData();
    } else if (this.step === 1 && this.isLoginByServerName) {
      this.sendUpdateSubscriptionToken();
    } else if (this.step === 2 && this.isLoginByServerName) {
      this.submitUpdateSubscription();
    } else {
      this.$router.push({ name: routeName });
    }
  }

  previousPage() {
    this.$router.back();
  }

  updateHeapParams(status: string) {
    if (!window.heap) {
      this.$log.info("Heap is not available");
      return;
    }
    const landingTime = Number(sessionStorage.getItem("landingTime"));
    const sessionTimeSeconds = Math.round((new Date().getTime() - landingTime) / 1000);
    const eventArriveTime = Number(sessionStorage.getItem("eventArriveTime"));
    const eventArriveSeconds = Math.round((new Date().getTime() - eventArriveTime) / 1000);
    const requestId = sessionStorage.getItem("requestId");

    window.heap?.addEventProperties({
      mpOrigin: this.allFormsData.mpOrigin,
      mpBuyerId: this.allFormsData.mpBuyerId,
      mpAccountId: this.allFormsData.mpAccountId,
      forcePublicOffer: this.allFormsData.forcePublicOffer,
      onlyCheckSSO: this.allFormsData.onlyCheckSSO,
      ssoType: this.allFormsData.ssoType,
      ssoId: this.allFormsData.ssoId,
      subscriptionType: this.allFormsData.subscriptionType,
      userName: this.allFormsData.userName,
      serverName: this.allFormsData.serverName,
      regionCode: this.allFormsData.regionCode,
      firstName: this.allFormsData.firstName,
      lastName: this.allFormsData.lastName,
      companyName: this.allFormsData.companyName,
      email: this.allFormsData.email,
      phone: this.allFormsData.phone,
      country: this.allFormsData.country,
      pageRoute: this.$route.name,
      eventArriveSeconds,
      sessionTimeSeconds,
      requestId,
      status,
    });
  }
  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  onLoading(value: boolean) {
    this.isLoading = value;
  }

  setCredentialsFilled(value: boolean) {
    this.credentialsFilled = value;
    this.setIsProceedEnabled();
  }

  onEnvironmentDataFilled(value: boolean) {
    this.environmentDataFilled = value;
    this.setIsProceedEnabled();
  }

  onCustomerDetailsFilled(value: boolean) {
    this.customerDetailsFilled = value;
    this.setIsProceedEnabled();
  }

  onServerTokenFilled(value: string) {
    this.serverTokenFilled = !!value;
    this.setIsProceedEnabled();
  }
  setIsProceedEnabled() {
    switch (this.currentRouteName) {
      case "mp.unified.login":
        this.isProceedEnabled = this.credentialsFilled && !!this.verifyTokenResponse?.paymentType;
        break;
      case "mp.unified.server.token":
        this.isProceedEnabled = this.serverTokenFilled;
        break;
      case "mp.unified.details":
        this.isProceedEnabled = this.customerDetailsFilled && !this.isLoading;
        break;
      case "mp.unified.environment":
        this.isProceedEnabled = this.environmentDataFilled;
        break;
      default:
        this.isProceedEnabled = false;
        break;
    }
    this.updateHeapParams("Pending");
  }

  isShowBackButton() {
    const step = parseInt(this.routeParentChildMeta["step"]);
    return [2, 3].includes(step) && !(this.$route.name === "mp.unified.done");
  }

  onPasswordChange(password: string) {
    this.password = password;
  }

  setAllFormsData(data: MPUnifiedRegistrationRequest) {
    this.allFormsData = data;
    sessionStorage.setItem("registrationFormData", JSON.stringify(data));
  }

  async verifyToken(popupId: number) {
    const mpOrigin = sessionStorage.getItem("mpOrigin") as MPOrigin;
    const mpToken = sessionStorage.getItem("mpToken") as string;
    switch (mpOrigin) {
      case "AWS_MP":
        this.showAwsWaitingPopup = popupId;
        this.marketplace = "AWS";
        break;
      case "AZURE_MP":
        this.marketplace = "Azure";
        break;
      case "GCP_MP":
        this.marketplace = "GCP";
        break;
    }
    this.$jfNotification.clearAll();

    try {
      const response: MPUnifiedVerifyTokenResponse = await registrationsService.verifyToken({ mpToken, mpOrigin });
      this.isLoading = false;
      if (response.paymentType) {
        this.$mjfNotification.close(this.showAwsWaitingPopup);
        this.verifyTokenResponse = response;
        this.allFormsData.subscriptionType = response.paymentType;
        sessionStorage.setItem("verifyTokenResponse", JSON.stringify(response));
        sessionStorage.setItem("eventArriveTime", new Date().getTime().toString());
        this.$jfModal.close();
        if (response.paymentType === "BOM_BASED") {
          await this.submitFormData();
          return;
        }
      } else if (response.redirectUrl) {
        this.$mjfNotification.close(this.showAwsWaitingPopup);
        window.location.href = response.redirectUrl;
      }

      this.setIsProceedEnabled();
      this.$log.info(`VERIFY_TOKEN: no response, ${JSON.stringify(this.verifyTokenResponse)}`);
      if (!this.verifyTokenResponse?.paymentType) {
        await this.retryVerifyToken();
      }
    } catch (e) {
      this.isLoading = false;
      if (isRestClientError(e)) {
        const result = e.httpBody && e.httpBody.result;
        const message = e.httpBody && e.httpBody.message;
        const requestId = e.httpBody && e.httpBody.requestId;
        sessionStorage.setItem("requestId", requestId as string);
        if (result && result === jumpErrorsMapping.verifyToken.successEventDidntArrived) {
          await this.retryVerifyToken();
        } else if (result && (await this.isRedirectToErrorPage(result, message as string, requestId as string))) {
        } else {
          await this.retryVerifyToken();
        }
      } else {
        await this.retryVerifyToken();
      }
    }
  }

  async isRedirectToErrorPage(result: number, message: string, requestId: string) {
    this.errorResults = [];
    const skipResults: number[] = [];
    this.errorsObj = [];
    if (result === 2222) {
      this.errorsObj = JSON.parse(message) as any[];
      this.errorResults.push(...this.errorsObj.map(err => err.code));
    } else {
      this.errorResults = [result];
    }
    this.setCustomMessages();
    for (let i = 0; i < this.errorResults.length; i++) {
      if (this.errorCodesForRedirectToErrorPage.includes(Number(this.errorResults[i]))) {
        this.$mjfNotification.close(this.showAwsWaitingPopup);
        await this.$router.push({
          name: "mp.unified.error",
          query: { requestId, result: (result as unknown) as string },
        });
        return true;
      }
    }
    let texts = "";
    for (let i = 0; i < this.errorResults.length; i++) {
      if (this.errorCodesForPopup.includes(Number(this.errorResults[i]))) {
        const err = this.errorsObj.filter(err => err.code === this.errorResults[i]);
        if (err?.length) {
          this.$mjfNotification.popup({ title: "Oops!", text: err[0].message + `<div>requestId: ${requestId}</div>` });
          this.updateHeapParams("Error");
        }
      } else if (
        !this.errorCodesIgnored.includes(Number(this.errorResults[i])) &&
        !this.errorCodesForRedirectToErrorPage.includes(Number(this.errorResults[i]))
      ) {
        let err = this.errorsObj.filter(error => Number(error.code) === Number(this.errorResults[i]));
        err = err.filter(error => !this.errorCodesForPopup.includes(Number(error.code)));
        if (err?.length) {
          texts += `<li>${err[0].message}</li>`;
        }
      }
    }
    if (texts) {
      texts = `<ol style="list-style-type: none">${texts}, requestId: ${requestId}</ol>`;
    } else {
      texts += `<ol><li>Sorry, General Error occured (${result}), requestId: ${requestId}</li></ol>`;
    }

    if (texts) {
      this.updateHeapParams("Error");
      this.$mjfNotification.close(this.showAwsWaitingPopup);
      await this.$jfNotification.error({ text: texts, duration: 30000 });
    }
    return false;
  }

  async retryVerifyToken() {
    this.verifyTokenTries++;
    this.$log.info(`VERIFY_TOKEN: try: ${this.verifyTokenTries}`);
    const retries = this.verifyTokenTries;
    const maxMinutesBeforeRedirect = this.marketplace === "GCP" ? 20 : 5;
    const landingTime = Number(sessionStorage.getItem("landingTime"));
    if (landingTime + maxMinutesBeforeRedirect * 60 * 1000 < new Date().getTime()) {
      this.$log.info(`VERIFY_TOKEN: redirect to error page because elapsed ${maxMinutesBeforeRedirect} minutes`);
      this.$mjfNotification.close(this.showAwsWaitingPopup);
      await this.$router.push({ name: "mp.unified.error", query: { result: "9999" } });
      return;
    }
    if (retries >= 2 && new Date().getTime() > this.proceedClicked + 7000) {
      this.$log.info("VERIFY_TOKEN: showing warning");
      this.showWaitWarning = true;
    } else {
      this.$log.info(`VERIFY_TOKEN: not showing warning because retries ${retries} < 2 or proceed not clicked yet`);
    }
    this.$log.info(`VERIFY_TOKEN: retrying`);
    setTimeout(() => {
      this.verifyToken(this.showAwsWaitingPopup);
    }, 10000);
  }

  get alertData(): Alert {
    if (this.marketplace === "GCP") {
      return {
        message: `This process can take <b>up to 15 </b> minutes,<br/> feel free to come back later`,
        type: "warn",
      };
    }
    return {
      message: `Hang on while we are retrieving your data from ${this.marketplace} Marketplace`,
      type: "warn",
    };
  }

  async submitFormData() {
    this.isLoading = true;
    const hasPassword = this.password || this.allFormsData?.ssoId;
    const isValidToken = this.verifyTokenResponse?.paymentType;
    const isSelfHosted = this.verifyTokenResponse?.paymentType === "BOM_BASED";

    if (!hasPassword && !isSelfHosted) {
      this.$jfNotification.error({ text: "Please enter password" });
      this.isLoading = false;
      await this.$router.push({ name: "mp.unified.login" });
      return;
    }

    if (!isValidToken) {
      this.$jfNotification.error({ text: "sorry, can't proceed because of invalid token" });
      this.isLoading = false;
      return;
    }
    try {
      this.allFormsData.userName = this.allFormsData.email as string;
      this.allFormsData.password = this.password;
      const response = await registrationsService.marketplaceUnifiedSubmit(this.allFormsData);
      this.isLoading = false;
      this.updateHeapParams("Completed");
      if (isSelfHosted && response?.email) {
        sessionStorage.setItem("ownerEmail", response.email);
        await this.$router.push({ name: "mp.unified.done", query: { result: "22222222" } });
        return;
      }

      let cloudProvider: string;
      switch (this.allFormsData.mpOrigin) {
        case "GCP_MP":
          cloudProvider = "Google";
          break;
        case "AZURE_MP":
          cloudProvider = "Azure";
          break;
        default:
          cloudProvider = "Amazon";
      }
      if (response.instanceUrl) {
        const waitingRoomData = {
          token: response.token,
          serverName: this.allFormsData.serverName,
          regionName: this.allFormsData.regionName,
          instanceUrl: response.instanceUrl,
          cloudProvider,
        };
        sessionStorage.setItem("waitingRoomData", JSON.stringify(waitingRoomData));
        const routeName = "mp.unified.waiting";
        await this.$router.push({ name: routeName });
      }
    } catch (e) {
      this.isLoading = false;
      if (isRestClientError(e)) {
        const result = e.httpBody && e.httpBody.result;
        const message = e.httpBody && e.httpBody.message;
        const requestId = e.httpBody && e.httpBody.requestId;
        sessionStorage.setItem("requestId", requestId as string);
        if (result && (await this.isRedirectToErrorPage(result, message as string, requestId as string))) {
        }
      }
    }
  }

  loginByServerName(value: boolean) {
    this.isLoginByServerName = value;
    sessionStorage.setItem("isLoginByServerName", value.toString());
  }

  async sendUpdateSubscriptionToken() {
    try {
      this.isLoading = true;
      const mpOrigin = sessionStorage.getItem("mpOrigin") as MPOrigin;
      const mpToken = sessionStorage.getItem("mpToken") as string;
      const updateSubscriptionModel: UpdateSubscriptionModel = {
        serverName: this.allFormsData.serverName,
      };

      const response: JumpBasicResponse = await registrationsService.sendUpdateSubscriptionToken({
        mpToken,
        mpOrigin,
        updateSubscriptionModel: updateSubscriptionModel,
      });
      if (response.status === 200) {
        this.notifyTokenSent();
        await this.$router.push({ name: "mp.unified.server.token" });
      } else {
        this.notifyTokenSendingFailure();
      }
      this.setIsProceedEnabled();
    } catch (e) {
      if (isRestClientError(e)) {
        const result = e.httpBody && e.httpBody.result;
        const message = e.httpBody && e.httpBody.message;
        const requestId = e.httpBody && e.httpBody.requestId;
        sessionStorage.setItem("requestId", requestId as string);
        if (result && Object.values(jumpErrorsMapping.sendJfrogToken).includes(result)) {
          this.globalBus.$emit("sendJfrogTokenResult", result);
          this.notifyTokenSendingFailure();
        } else if (result && (await this.isRedirectToErrorPage(result, message as string, requestId as string))) {
        }
      }
    }
    this.isLoading = false;
  }

  notifyTokenSendingFailure() {
    this.$mjfNotification.popup({
      text: `<p>Something went wrong while sending your registration token.</p> Click on Resend Token or contact <a href="mailto:service@jfrog.com">service@jfrog.com</a> if the issue persists.`,
    });
  }
  notifyTokenSent() {
    this.$mjfNotification.success({
      title: "Token Sent",
      text: "Check your account owner’s email",
    });
  }

  async submitUpdateSubscription() {
    try {
      this.isLoading = true;
      const mpOrigin = sessionStorage.getItem("mpOrigin") as MPOrigin;
      const mpToken = sessionStorage.getItem("mpToken") as string;
      const updateSubscriptionModel: UpdateSubscriptionModel = {
        serverName: this.allFormsData.serverName,
        jfrogToken: this.allFormsData.serverToken as string,
      };
      const response: UpdateSubscriptionResponse = await registrationsService.updateSubscription({
        mpToken,
        mpOrigin,
        mpBuyerId: this.allFormsData.mpBuyerId,
        country: this.allFormsData.country as string,
        updateSubscriptionModel: updateSubscriptionModel,
      });
      if (response.needRedeploy) {
        await this.$router.push({ name: "mp.unified.done", query: { result: "1111111" } });
      } else if (response.instanceUrl) {
        location.replace(response.instanceUrl);
      } else {
        this.$jfNotification.error({ text: "unexpected response on submit" });
      }
    } catch (e) {
      if (isRestClientError(e)) {
        const result = e.httpBody && e.httpBody.result;
        const message = e.httpBody && e.httpBody.message;
        const requestId = e.httpBody && e.httpBody.requestId;
        sessionStorage.setItem("requestId", requestId as string);
        if (result && (await this.isRedirectToErrorPage(result, message as string, requestId as string))) {
        }
      } else {
        this.$jfNotification.error({ text: e.text });
      }
    }
    this.isLoading = false;
  }
}
