
import { Component, Prop, Vue } from "vue-property-decorator";
import { ActionData } from "@/types/localtypes/quickPurchase";

@Component({
  name: "QuickPurchaseActionBar",
  components: {},
})
export default class QuickPurchaseActionBar extends Vue {
  @Prop() data!: ActionData;

  handleBuyAction() {
    this.$emit("handleBuyAction", true);
  }
  showTiers() {
    this.$emit("showTiers", true);
  }
}
