import { appService } from "@/services/app";
import { cloudActivationsService } from "@/services/cloudActivation";
import { marketoService } from "@/services/marketo";
import { sessionsService } from "@/services/sessions";
import {
  CloudActivationData,
  LoginFlag,
  LoginHeaderType,
  OtpAuthenticationData,
  QuickPurchaseData,
  RegisterNowHeaderType,
  RegistrationHeaderType,
} from "@/types/localtypes";
import logger from "@/util/logger";
import {
  CloudActivationMktoParams,
  CloudValidationResponse,
  isRestClientError,
  LinkedSessionRequest,
  MyJFrogTheme,
  NeedOtpLoginResponse,
  storeErrorsMapping,
} from "@jfrog-ba/myjfrog-common";
import { NavigationGuard } from "vue-router";
import store from "../store/store";
import { usersService } from "./user";

declare const MYJFROG_UI_URL: string;

interface Configuration {
  setInMainApp: NavigationGuard;
  checkLightUi: NavigationGuard;
}

interface Authentication {
  none: NavigationGuard;
  isSSO: NavigationGuard;
  required: NavigationGuard;
  otpRequired: NavigationGuard;
}

interface Registration {
  cleanCacheIfNeeded: NavigationGuard;
  canAccess: NavigationGuard;
}

interface Subscription {
  hasMultiple: NavigationGuard;
}

interface BillingAccount {
  canAccess: NavigationGuard;
}

interface Sandbox {
  canAccess: NavigationGuard;
}

interface Synchronization {
  isSynced: NavigationGuard;
  isNotSynced: NavigationGuard;
}

interface Redirection {
  toOrigin: NavigationGuard;
  toNewMjf: NavigationGuard;
}

interface QuickPurchase {
  toQuickPurchaseError: NavigationGuard;
  toQuickPurchaseAlreadyUpgraded: NavigationGuard;
  toQuickPurchaseUpgradeInProgress: NavigationGuard;
  toQuickPurchaseShouldLogin: NavigationGuard;
  toQuickPurchase: NavigationGuard;
  toQuickPurchaseInvalidOrExpiredToken: NavigationGuard;
  canAccess: NavigationGuard;
  noErrors: NavigationGuard;
  stillNotUpgraded: NavigationGuard;
  canAccessCheckout: NavigationGuard;
  canAccessGetAQuote: NavigationGuard;
  canAccessThankYou: NavigationGuard;
  canAccessError: NavigationGuard;
  canAccessAlreadyUpgraded: NavigationGuard;
  canAccessUpgradeInProgress: NavigationGuard;
}

interface QuickPurchasePromoPT15USD {
  toError: NavigationGuard;
  toPromotionExpired: NavigationGuard;
  toMainView: NavigationGuard;
  canAccess: NavigationGuard;
  noErrors: NavigationGuard;
}

interface ProductActivation {
  canAccess: NavigationGuard;
  canAccessThanks: NavigationGuard;
}

interface CloudActivation {
  bootstrap: NavigationGuard;
}

interface CloudFreeReactivation {
  bootstrap: NavigationGuard;
}

interface EmailVerification {
  bootstrap: NavigationGuard;
  getResponse: (token: string, doValidation: boolean) => Promise<any>;
}

const otpSSOGuard: NavigationGuard = async (to, from, next) => {
  const otpTokenKey = to.query.otpTokenKey as string;
  const authenticator = to.query.authenticator as NeedOtpLoginResponse["authenticator"];

  if (otpTokenKey && authenticator) {
    const otpAuthenticationData: OtpAuthenticationData = { otpTokenKeyLink: otpTokenKey, authenticator, fromSSO: true };
    await store.dispatch("authentication/setOtpAuthenticationData", otpAuthenticationData);
    return next({ path: "/google-authenticator" });
  }

  logger.warn(`Missing SSO OTP login mandatory parameters.`);
  return next({ path: "/login" });
};

const regularSSOGuard: NavigationGuard = async (to, from, next) => {
  const sessionId = to.query.sessionId as string;
  const userName = to.query.userName as string;
  let theme = to.query.theme as MyJFrogTheme;
  if (sessionId && userName) {
    if (!theme) {
      logger.warn(`Unknown theme '${theme}'. Fallback to default one.`);
      theme = "DEFAULT";
    }
    const linkedSessionRequest: LinkedSessionRequest = { linkSessionId: sessionId };
    try {
      usersService.setSessionData({ sessionId: "Receiving...", userName, theme, authenticated: false });
      const linkedSessionResponse = await sessionsService.getLinkedSession(linkedSessionRequest);
      if (linkedSessionResponse && linkedSessionResponse.linkedSessionId) {
        usersService.setSessionData({
          sessionId: linkedSessionResponse.linkedSessionId,
          userName,
          theme,
          authenticated: true,
        });
        return next({ path: "/" });
      }
      throw new Error("received bad or null linkedSession response");
    } catch (e) {
      logger.warn(`Unable to retrieve the linked sessionId. ${e.message}`);
      return next({ path: "/login" });
    }
  }
  logger.warn(`Missing SSO login mandatory parameters.`);
  return next({ path: "/login" });
};

export const configuration: Configuration = {
  async setInMainApp(to, from, next) {
    appService.clearActivationCache();
    await store.dispatch("application/setInMainApp", true);
    return next();
  },
  async checkLightUi(to, from, next) {
    if (to.query.light === "true") {
      await store.dispatch("application/setLightUiEnabled", true);
    }
    return next();
  },
};

export const authentication: Authentication = {
  isSSO(to, from, next) {
    const otpRequired = to.query.otpRequired !== undefined; // query param without value
    if (otpRequired) {
      return otpSSOGuard(to, from, next);
    }
    return regularSSOGuard(to, from, next);
  },
  none(to, from, next) {
    const sessionData = usersService.getSessionData();
    if (sessionData && sessionData.authenticated) {
      next({ path: "/" });
      return;
    }
    next();
  },
  async required(to, from, next) {
    if (!usersService.getSessionId()) {
      appService.clearCache();
      return next({ path: "/login" });
    }

    if (store.getters["user/user"]) {
      return next();
    }

    let loginFlag: LoginFlag = null;
    try {
      const user = await usersService.getUser();
      if (user) {
        await store.dispatch("user/setUser", user);
        return next();
      }
      appService.clearCache();
      loginFlag = "errorSomethingWentWrong";
      await store.dispatch("application/setLastLoginFlag", loginFlag);
      return next({ path: "/login" });
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        loginFlag = appService.isFreshInstance() ? null : "errorSessionExpired";
      } else {
        loginFlag = "errorSomethingWentWrong";
      }
      appService.clearCache();
      await store.dispatch("application/setLastLoginFlag", loginFlag);
      return next({ path: "/login" });
    }
  },
  otpRequired(to, from, next) {
    const otpAuthenticationData: OtpAuthenticationData = store.getters["authentication/otpAuthenticationData"];
    if (!otpAuthenticationData) {
      return next({ path: "/" });
    }
    return next();
  },
};

export const subscription: Subscription = {
  async hasMultiple(to, from, next) {
    if (await usersService.hasMultipleSubscriptions()) {
      return next();
    }
    const accountNumber = await usersService.getFirstSubscriptionAccountNumber();
    if (accountNumber === null) {
      return next({ path: "/500" });
    }
    return next({
      name: "subscriptions.detail",
      params: { accountNumber: accountNumber.toString() },
    });
  },
};
export const synchronization: Synchronization = {
  async isSynced(to, from, next) {
    if (store.getters["user/user"] && usersService.isUserSynced()) {
      return next();
    }
    next({ path: "/refresh" });
  },
  async isNotSynced(to, from, next) {
    if (store.getters["user/user"] && !usersService.isUserSynced()) {
      next();
    }
    return next({ path: "/login" });
  },
};

export const billingAccount: BillingAccount = {
  async canAccess(to, from, next) {
    if (await usersService.hasBillingAccounts()) {
      return next();
    }
    return next({ path: "/" });
  },
};

export const redirection: Redirection = {
  toOrigin(to, from, next) {
    next(from.fullPath);
  },
  toNewMjf(to, from, next) {
    const redirectionIsDisabled = "true" === process.env.VUE_APP_SKIP_REDIRECTION_TO_NEW_MJF;
    if (!redirectionIsDisabled && !to.meta?.skipMjfRedirection) {
      location.href = MYJFROG_UI_URL;
      return;
    }
    return next();
  },
};

const isValidServerName = (serverName: string) => {
  return serverName && serverName.match(/^[a-z0-9]+$/i);
};

const sanitiseServerName = (serverName: string) => {
  return isValidServerName(serverName) ? serverName : "";
};

export const quickPurchase: QuickPurchase = {
  async toQuickPurchaseError(to, from, next) {
    const serverName = to.query.serverName as string;
    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: sanitiseServerName(serverName),
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "quick-purchase-error" });
  },
  async toQuickPurchaseAlreadyUpgraded(to, from, next) {
    const serverName = to.query.serverName as string;

    if (!isValidServerName(serverName)) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }

    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: serverName,
      hasUpgraded: true,
      upgradeInProgress: false,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "quick-purchase-already-upgraded" });
  },
  async toQuickPurchaseUpgradeInProgress(to, from, next) {
    const serverName = to.query.serverName as string;

    if (!isValidServerName(serverName)) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }

    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: serverName,
      hasUpgraded: false,
      upgradeInProgress: true,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "quick-purchase-upgrade-in-progress" });
  },
  async toQuickPurchaseShouldLogin(to, from, next) {
    const headerType: LoginHeaderType = "loginToUpgrade";
    await store.dispatch("dynamicHeader/setLoginHeaderType", headerType);
    return next({ path: "/login" });
  },
  async toQuickPurchaseInvalidOrExpiredToken(to, from, next) {
    const serverName = to.query.serverName as string;

    if (!isValidServerName(serverName)) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }

    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: sanitiseServerName(serverName),
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "quick-purchase-invalid-or-expired-token" });
  },
  async toQuickPurchase(to, from, next) {
    const serverName = to.query.serverName as string;
    const token = to.query.token as string;

    const quickPurchaseDataResponse = await usersService.getQuickPurchaseData({
      token,
      serverName,
      paymentTypes: [], // not needed anymore
    });

    if (!quickPurchaseDataResponse) {
      logger.error(`unable to get quick purchase Data from the server.`);
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }

    const quickPurchaseData: QuickPurchaseData = {
      token,
      serverName,
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: false,
      subscriptionTypes: quickPurchaseDataResponse.subscriptionTypes,
      currentPaymentType: quickPurchaseDataResponse.currentPaymentType,
      hasPipelines: quickPurchaseDataResponse.hasPipelines,
      promotionItems: quickPurchaseDataResponse.promotions,
    };

    // cleaning existing login session
    await appService.clearCache();

    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);

    return next({ path: "/quick-purchase" });
  },
  canAccess(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData) {
      return next({ path: "/" });
    }
    appService.clearActivationCache();
    return next();
  },
  noErrors(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (quickPurchaseData.hasError || !quickPurchaseData.serverName || !quickPurchaseData.token) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }
    return next();
  },
  stillNotUpgraded(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (quickPurchaseData.hasUpgraded) {
      if (quickPurchaseData.hasError) {
        return next({ name: "quick-purchase-already-upgraded" });
      }
      return next({ name: "quick-purchase-thank-you" });
    }
    return next();
  },
  canAccessCheckout(to, from, next) {
    if (to.params["planName"]) {
      return next();
    }
    return next({ path: "/quick-purchase" });
  },
  canAccessGetAQuote(to, from, next) {
    if (to.params["planName"]) {
      return next();
    }
    return next({ path: "/quick-purchase" });
  },
  canAccessThankYou(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData.hasUpgraded) {
      return next({ name: "quick-purchase-pricing" });
    }
    return next();
  },
  canAccessError(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData.hasError) {
      return next({ path: "/quick-purchase" });
    }
    if (quickPurchaseData.hasUpgraded) {
      return next({ name: "quick-purchase-already-upgraded" });
    }
    if (quickPurchaseData.upgradeInProgress) {
      return next({ name: "quick-purchase-upgrade-in-progress" });
    }
    return next();
  },
  canAccessAlreadyUpgraded(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData.hasError || !quickPurchaseData.hasUpgraded) {
      if (quickPurchaseData.upgradeInProgress) {
        return next({ name: "quick-purchase-upgrade-in-progress" });
      }
      return next({ path: "/quick-purchase" });
    }
    return next();
  },
  canAccessUpgradeInProgress(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData.hasError || !quickPurchaseData.upgradeInProgress) {
      if (quickPurchaseData.hasUpgraded) {
        return next({ name: "quick-purchase-already-upgraded" });
      }
      return next({ path: "/quick-purchase" });
    }
    return next();
  },
};

export const quickPurchasePromoPT15Usd: QuickPurchasePromoPT15USD = {
  async toError(to, from, next) {
    const serverName = to.query.serverName as string;
    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: sanitiseServerName(serverName),
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "pro-team-offer-error" });
  },
  async toPromotionExpired(to, from, next) {
    const serverName = to.query.serverName as string;

    if (!isValidServerName(serverName)) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }

    const quickPurchaseData: QuickPurchaseData = {
      token: "",
      serverName: serverName,
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: true,
      hasPipelines: false,
    };
    await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    return next({ name: "pro-team-offer-promotion-expired" });
  },
  async toMainView(to, from, next) {
    const serverName = to.query.serverName as string;
    const token = to.query.token as string;
    if (!serverName || !token) {
      return next({ path: "/login" });
    }

    try {
      const quickPurchaseDataResponse = await usersService.getQuickPurchaseDataPromotion({
        token,
        serverName,
        paymentTypes: [], // not needed anymore
      });

      if (!quickPurchaseDataResponse) {
        logger.error(`unable to get quick purchase promotion data from the server.`);
        return quickPurchasePromoPT15Usd.toError(to, from, next);
      }

      const quickPurchaseData: QuickPurchaseData = {
        token,
        serverName,
        hasUpgraded: false,
        upgradeInProgress: false,
        hasError: false,
        hasPipelines: false,
        promotion: true,
        subscriptionTypes: quickPurchaseDataResponse.subscriptionTypes,
      };

      // cleaning existing login session
      await appService.clearCache();

      await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);

      return next({ path: "/pro-team-offer" });
    } catch (e) {
      logger.error(`handling exception on toMainView middleware.`);
      return quickPurchasePromoPT15Usd.toError(to, from, next);
    }
  },
  async canAccess(to, from, next) {
    // TODO: [18/08/2021] [kevinz] -> dummy

    // const quickPurchaseData: QuickPurchaseData = {
    //   token:
    //     "eyJ2ZXIiOiIyIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYiLCJraWQiOiJaNjdjTFFSRTBuZUt6SkpVZ0pCVTV3VS1HWjVaMms2QlJ1R1JtSFNZWkNjIn0.eyJleHQiOiJ7XCJhY2NvdW50TnVtYmVyXCI6XCI1NjkyOTczMTYtcHJvbW9Qcm9UZWFtMTV1c2RcIn0iLCJzdWIiOiJqcGF5Iiwic2NwIjoicHJvbW9Qcm9UZWFtMTV1c2QiLCJhdWQiOiJqcGF5QGJiZjdhNGEwLTI4MzYtNDVkOC04MTIyLTc3ZTVkNWNiYzEzZiIsImlzcyI6ImpwYXlAYmJmN2E0YTAtMjgzNi00NWQ4LTgxMjItNzdlNWQ1Y2JjMTNmIiwiZXhwIjoxNjYwODM3NTEyLCJpYXQiOjE2MjkzMDE1MTIsImp0aSI6IjkwMDkzZTM2LTQzZTQtNGYxZC05MGUzLTJiYzlhOTc3YmY4OCJ9.UZdFs8SYlixbdvw3_IoRlWupWfS2h9guRahmJ3zJRStPfp9-e988L-tW7q26rwPp6bYy3oZieKX32nxFMl_E08_lBAzVcbwYxIXSeJ9Q4y9IWWYrj2ElrXJEon18Ajr8N0oC4QrtUWI4ejTEyJOxKpO8KHqmEq8iZ_t6IAFQZn5VDj5Bd2O0zeIIi-nqD1yAxn1ob71F2Bmwsw8WJiUQS54aaHKF5E0Zie7QHVC1XNT8cf-b1u_9JuRPadfENeHbIEdUvCxf_NAGvN6EgTU3AtNOQzlxc0_qVczUREi_cg2QUA0UNXUprPpPRchsqvCm--F1uEYiDGzwvy852L69mQ",
    //   serverName: "wsanitykevinzwarm82",
    //   hasUpgraded: false,
    //   upgradeInProgress: false,
    //   hasError: false,
    //   hasPipelines: false,
    //   promotion: true,
    //   subscriptionTypes: [
    //     {
    //       subscriptionType: "PRO_TEAM_MONTHLY",
    //       eventPaymentType: "MONTHLY",
    //       eventSubscriptionType: "PRO_TEAM",
    //       ratePlanId: "2c92c0f973248c010173253ec12507f7",
    //       price: "15.00",
    //     },
    //   ],
    // };
    // // cleaning existing login session
    // appService.clearCache();
    //
    // await store.dispatch("quickPurchase/setQuickPurchaseData", quickPurchaseData);
    // return next();

    // TODO: [18/08/2021] [kevinz] -> good

    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (!quickPurchaseData) {
      return next({ path: "/" });
    }
    appService.clearActivationCache();
    return next();
  },
  noErrors(to, from, next) {
    const quickPurchaseData: QuickPurchaseData = store.getters["quickPurchase/quickPurchaseData"];
    if (quickPurchaseData.hasError || !quickPurchaseData.serverName || !quickPurchaseData.token) {
      return quickPurchase.toQuickPurchaseError(to, from, next);
    }
    return next();
  },
};

export const registration: Registration = {
  async cleanCacheIfNeeded(to, from, next) {
    if (to.name === "registration") {
      appService.clearCache();
    }
    return next();
  },
  async canAccess(to, from, next) {
    const token = to.query.token as string;
    const origin = to.query.origin as "self" | "purchase";
    appService.clearCache();
    const registerNowHeaderType: RegisterNowHeaderType = "linkNotActive";
    if (!token) {
      await store.dispatch("dynamicHeader/setRegisterNowHeaderType", registerNowHeaderType);
      return next({ path: "/register-now" });
    }
    try {
      await usersService.checkRegistrationToken(token);
    } catch (e) {
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.registration.userAlreadyRegistered) {
          return next({ path: "/login" });
        }
      }
      await store.dispatch("dynamicHeader/setRegisterNowHeaderType", registerNowHeaderType);
      return next({ path: "/register-now" });
    }

    const registrationHeaderType: RegistrationHeaderType = origin === "self" ? "fromStep1" : "regular";
    await store.dispatch("dynamicHeader/setRegistrationHeaderType", registrationHeaderType);
    return next();
  },
};

export const cloudActivation: CloudActivation = {
  async bootstrap(to, from, next) {
    const activationCode = to.query.ac as string;
    const mktoParamOss = to.query.oss as string;
    const mktoParamChalenge = to.query.cha as string;
    const mktoParamJcr = to.query.jcr as string;
    const mktoParamConan = to.query.conan as string;
    const mktoParamSu21 = to.query.su21 as string;
    const mktoParamConnect = to.query.connect as string;

    if (!activationCode) {
      return next({ path: "/login" });
    }
    appService.clearSessionCache();
    try {
      let mktoParam: CloudActivationMktoParams = null;
      if (!!mktoParamOss) {
        mktoParam = "oss";
      } else if (!!mktoParamChalenge) {
        mktoParam = "cha";
      } else if (!!mktoParamJcr) {
        mktoParam = "jcr";
      } else if (!!mktoParamConan) {
        mktoParam = "conan";
      } else if (!!mktoParamSu21) {
        mktoParam = "su21";
      } else if (!!mktoParamConnect) {
        mktoParam = "connect";
      }

      const response = await cloudActivationsService.activate({
        activationCode,
        marketoCookie: marketoService.getCookie(),
        urlPath: to.fullPath,
        mktoParam,
      });
      const {
        serverName,
        token,
        activationCodeAlreadyUsed,
        activationStatus,
        instanceUrl,
        cloudProviderCode,
        regionName,
        activationType,
        products,
        ssoType,
      } = response;

      const activationData: CloudActivationData = {
        hasError: false,
        data: {
          activationCode,
          elapsedTime: cloudActivationsService.getElapsedTimeForActivationCode(activationCode),
          serverName,
          instanceUrl,
          token,
          activationStatus,
          codeAlreadyUsed: activationCodeAlreadyUsed,
          cloudProviderCode,
          regionName,
          activationType,
          products,
          ssoType,
        },
      };
      cloudActivationsService.setCloudActivationData(activationData);
      return next();
    } catch (e) {
      cloudActivationsService.setCloudActivationData({ hasError: true });
      return next();
    }
  },
};

export const emailVerification: EmailVerification = {
  async getResponse(token: string, doValidation: boolean) {
    try {
      const response: CloudValidationResponse = await cloudActivationsService.validate({
        token,
        doValidation,
      });
      cloudActivationsService.setValidationData(response);
    } catch (e) {
      cloudActivationsService.setValidationData({
        status: e.status as number,
        message: e.message,
        instanceUrl: "",
        region: "",
        result: -1,
        serverName: "",
        products: "",
      });
    }
  },
  async bootstrap(to, from, next) {
    const token = to.query.token as string;
    window.sessionStorage.setItem("cloudValidationToken", token);
    return next();
  },
};

export const productActivation: ProductActivation = {
  async canAccess(to, from, next) {
    const request = {
      serverName: to.query.serverName as string,
      token: to.query.token as string,
      product: to.query.product as string,
    };
    await cloudActivationsService.getProductActivationData(request);
    await store.dispatch("productActivation/setProductActivationData", request);
    return next();
  },
  async canAccessThanks(to, from, next) {
    return next();
  },
};

export const cloudFreeReactivation: CloudFreeReactivation = {
  async bootstrap(to, from, next) {
    const token = to.query.token as string;

    if (!token) {
      return next({ path: "/login" });
    }
    appService.clearSessionCache();
    try {
      await cloudActivationsService.reactivate({
        token,
      });
      return next();
    } catch (e) {
      cloudActivationsService.setCloudFreeReactivationData({});
      return next();
    }
  },
};

export const sandbox: Sandbox = {
  async canAccess(to, from, next) {
    const canAccess = process.env.NODE_ENV !== "production";
    if (canAccess) {
      return next();
    }
    return next({ path: "/404" });
  },
};
