import { WebeyezService } from "@/types/services/webeyez";
import { WebeyezObject, FlatSubscription } from "@/types/localtypes/index";
import { subscriptionsService } from "@/services/subscriptions";
import logger from "@/util/logger";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { goalKeysFactory } from "@/services/webeyez/factory/webeyezGoalKeysFactory";

let webeyezEventRecentlyPublished: boolean = false;
export const webeyezService: WebeyezService = {
  async publishEvent(webeyezObject: WebeyezObject): Promise<void> {
    if (webeyezEventRecentlyPublished) {
      return;
    }
    webeyezEventRecentlyPublished = true;
    const accountNumbers = await this.getAccountNumbers();
    const customData = this.getCustomData(webeyezObject, accountNumbers);
    (window as any).wzTag("goal", {
      goal_key: webeyezObject.goal_key,
      isSuccess: webeyezObject.isSuccess,
      errorMessage: webeyezObject.errorMessage,
      statusCode: webeyezObject.statusCode,
      customData: customData,
    });
    setTimeout(() => {
      webeyezEventRecentlyPublished = false;
    }, 400);
  },
  async getAccountNumbers(): Promise<string> {
    try {
      const accountNumbers: string[] = [];
      const subscriptions = await subscriptionsService.getSubscriptions();
      subscriptions.forEach(subscription => accountNumbers.push(String(subscription.accountNumber)));
      return accountNumbers.join();
    } catch (e) {
      logger.error("cant get account numbers");
      return "";
    }
  },

  getCustomData(webeyezObject: WebeyezObject, accountNumbers: string): Array<{ key: string; value: any }> {
    const customData = accountNumbers === "" ? [] : [{ key: "accountNumber", value: accountNumbers }];
    if (webeyezObject.customData) {
      customData.push(...webeyezObject.customData);
    }
    return customData;
  },

  send(webeyezObject: WebeyezObject): void {
    goalKeysFactory.getInstance(webeyezObject.goal_key).send(webeyezObject);
  },
};
