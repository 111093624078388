import { render, staticRenderFns } from "./MenuItem.vue?vue&type=template&id=859e3aec&scoped=true&"
import script from "./MenuItem.vue?vue&type=script&lang=ts&"
export * from "./MenuItem.vue?vue&type=script&lang=ts&"
import style0 from "./MenuItem.vue?vue&type=style&index=0&id=859e3aec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "859e3aec",
  null
  
)

export default component.exports