
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tier } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "PricingTiersInfo",
  $_veeValidate: { validator: "new" },
  components: {},
})
export default class PricingTiersInfo extends Vue {
  @Prop() ratePlanTiers!: Array<Tier>;
  @Prop() subscriptionType!: string;

  mounted() {
    this.isActive = true;
  }

  isActive = false;

  get cssClass() {
    let classes = [this.$mq, "pricing-tiers-info"];
    if (this.isActive) {
      classes.push("active");
    }
    return classes;
  }

  hide() {
    this.isActive = false;
    setTimeout(() => {
      this.$emit("showTiers", false);
    }, 500);
  }

  get tierData() {
    return this.subscriptionType === "ENTERPRISE_X_MONTHLY" ? this.pricingTiersDataEntX() : this.pricingTiersDataPro();
  }

  pricingTiersDataPro() {
    return {
      title: "pro",
      intro: `Pro Subscription includes 25GB starting consumption.<br/>
Additional monthly usage priced as follows:`,
      tiers: this.ratePlanTiers,
      footer: `Additional CI/CD minutes are priced at $0.007 per minute over
      the base 10,000 minutes/month.
`,
      footerNotes: [
        { text: `Storage and data transfer counts towards total monthly consumption` },
        {
          text: `Tier discount applied only to consumption within that tier (e.g. 1060 Total Monthly GB consumed = 25GB included, $1.25/GB for 999GB, $1.00/GB for 36GB)`,
        },
      ],
    };
  }

  pricingTiersDataEntX() {
    return {
      title: "enterprise x",
      intro: `Enterprise X Subscription includes 125GB starting consumption.
Additional monthly usage priced as follows:`,
      tiers: this.ratePlanTiers,
      footer: `Additional CI/CD minutes are priced at $0.007 per minute over the base 25,000 minutes/month.`,
      footerNotes: [
        { text: `Storage and data transfer counts towards total monthly consumption` },
        {
          text: `Tier discount applied only to consumption within that tier (e.g. 1160 Total Monthly GB consumed = 125GB included, $1.25/GB for 999GB, $1.00/GB for 36GB)`,
        },
        { text: `Discounts available for annual contracts <i>(require minimum commitment)</i>` },
      ],
    };
  }
}
