import { LoginHeaderType, RegisterNowHeaderType, RegistrationHeaderType } from "@/types/localtypes";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class DynamicHeader extends VuexModule {
  _loginHeaderType: LoginHeaderType = "regular";
  _registerNowHeaderType: RegisterNowHeaderType = "regular";
  _registrationHeaderType: RegistrationHeaderType = "regular";

  @Mutation
  _setLoginHeaderType(type: LoginHeaderType) {
    this._loginHeaderType = type;
  }

  @Mutation
  _setRegistrationHeaderType(type: RegistrationHeaderType) {
    this._registrationHeaderType = type;
  }

  @Mutation
  _setRegisterNowHeaderType(type: RegisterNowHeaderType) {
    this._registerNowHeaderType = type;
  }

  @Action({ commit: "_setLoginHeaderType" })
  setLoginHeaderType(type: LoginHeaderType) {
    return type;
  }

  @Action({ commit: "_setLoginHeaderType" })
  clearLoginHeaderType() {
    return "regular" as LoginHeaderType;
  }

  @Action({ commit: "_setRegisterNowHeaderType" })
  setRegisterNowHeaderType(type: RegisterNowHeaderType) {
    return type;
  }

  @Action({ commit: "_setRegisterNowHeaderType" })
  clearRegisterNowHeaderType() {
    return "regular" as RegisterNowHeaderType;
  }

  @Action({ commit: "_setRegistrationHeaderType" })
  setRegistrationHeaderType(type: RegistrationHeaderType) {
    return type;
  }

  @Action({ commit: "_setRegistrationHeaderType" })
  clearRegistrationHeaderType() {
    return "regular" as RegistrationHeaderType;
  }

  get loginHeaderType() {
    return this._loginHeaderType;
  }

  get registerNowHeaderType() {
    return this._registerNowHeaderType;
  }

  get registrationHeaderType() {
    return this._registrationHeaderType;
  }
}
