import { LoginFlag } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { MenuConfiguration, MenuTooltipState } from "@/types/services/menus";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Application extends VuexModule {
  _inMainApp: boolean = false;
  _lightUiEnabled: boolean = false;
  appIsLoading: boolean = false;
  mobileMenuIsOpen: boolean = false;
  _viewContext: ViewContext = {};
  _menuConfiguration: MenuConfiguration = {};
  _menuTooltipStates: MenuTooltipState[] = [];
  _lastLoginFlag: LoginFlag = null;

  @Mutation
  _setInMainApp(_inMainApp: boolean) {
    this._inMainApp = _inMainApp;
  }
  @Mutation
  _setLightUiEnabled(_lightUiEnabled: boolean) {
    this._lightUiEnabled = _lightUiEnabled;
  }
  @Mutation
  setAppIsLoading(isLoading: boolean) {
    this.appIsLoading = isLoading;
  }
  @Mutation
  setMobileMenuIsOpen(isOpen: boolean) {
    this.mobileMenuIsOpen = isOpen;
  }

  @Mutation
  _setViewContext(viewContext: ViewContext) {
    this._viewContext = viewContext;
  }

  @Mutation
  _setMenuConfiguration(menuConfiguration: MenuConfiguration) {
    this._menuConfiguration = menuConfiguration;
  }

  @Mutation
  _setMenuTooltipStates(states: MenuTooltipState[]) {
    this._menuTooltipStates = states;
  }

  @Mutation
  _setLastLoginFlag(flag: LoginFlag) {
    this._lastLoginFlag = flag;
  }

  @Action({ commit: "_setInMainApp" })
  setInMainApp(inMainApp: boolean) {
    return inMainApp;
  }
  @Action({ commit: "_setLightUiEnabled" })
  setLightUiEnabled(lightUiEnabled: boolean) {
    return lightUiEnabled;
  }

  @Action({ commit: "setAppIsLoading" })
  showAppLoadingMask() {
    return true;
  }
  @Action({ commit: "setAppIsLoading" })
  hideAppLoadingMask() {
    return false;
  }
  @Action({ commit: "setMobileMenuIsOpen" })
  toggleMobileMenu(isOpen: boolean) {
    return isOpen;
  }
  @Action({ commit: "_setMenuConfiguration" })
  setMenuConfiguration(menuConfiguration: MenuConfiguration) {
    return menuConfiguration;
  }

  @Action({ commit: "_setMenuConfiguration" })
  clearMenuConfiguration() {
    return {};
  }

  @Action({ commit: "_setViewContext" })
  setViewContext(viewContext: ViewContext) {
    return viewContext;
  }

  @Action({ commit: "_setViewContext" })
  clearViewContext(viewContext: ViewContext) {
    return {};
  }

  @Action({ commit: "_setMenuTooltipStates" })
  setMenuTooltipStates(states: MenuTooltipState[]) {
    return states;
  }

  @Action({ commit: "_setLastLoginFlag" })
  setLastLoginFlag(flag: LoginFlag) {
    return flag;
  }

  get inMainApp() {
    return this._inMainApp;
  }
  get lightUiEnabled() {
    return this._lightUiEnabled;
  }
  get isAppLoading() {
    return this.appIsLoading;
  }
  get isMobileMenuOpen() {
    return this.mobileMenuIsOpen;
  }
  get viewContext() {
    return this._viewContext;
  }
  get menuConfiguration() {
    return this._menuConfiguration;
  }
  get menuTooltipStates() {
    return this._menuTooltipStates;
  }
  get lastLoginFlag() {
    return this._lastLoginFlag;
  }
}
