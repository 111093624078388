import { ProductActivationData } from "@/types/localtypes";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class ProductActivation extends VuexModule {
  _productActivationData: ProductActivationData | null = null;

  @Mutation
  _setProductActivationData(productActivationData: ProductActivationData) {
    this._productActivationData = productActivationData;
  }

  @Action({ commit: "_setProductActivationData" })
  setProductActivationData(productActivationData: ProductActivationData) {
    return productActivationData;
  }

  @Action({ commit: "_setProductActivationData" })
  clearProductActivationData() {
    return null;
  }

  get productActivationData() {
    return this._productActivationData;
  }
}
