
import { JFEssentialsNotificationConfig } from "@/types/3rdPartyLibs";
import { ComputeEPlusConfigurationNotificationConfig } from "@/types/services/featureNotifications";
import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { FlatSubscription } from "@/types/localtypes";

const bellColoredIcon = require("@/assets/images/bell-colored.svg");
@Component({
  name: "FeatureNotificationsEPlusAddServers.vue",
  components: {},
})
export default class FeatureNotificationsEPlusAddServers extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("ePlusConfigNewServerNotificationClosedOnPurpose", { namespace: "featureNotification" })
  ePlusConfigNewServerNotificationClosedOnPurpose!: (ePlusConfigNewServerNotificationClosedOnPurpose: boolean) => void;
  @Action("subscriptionDetailsToggleTab", { namespace: "featureNotification" })
  setVuexActiveTab!: (tabId: string) => void;
  @Getter("isEPlusConfigNewServerNotificationClosedOnPurpose", { namespace: "featureNotification" })
  isEPlusConfigNewServerNotificationClosedOnPurpose!: boolean;
  @Getter("subscriptionDetailsToggleTabValue", { namespace: "featureNotification" })
  subscriptionDetailsToggleTabValue!: string;
  currentNotificationId: string | undefined = undefined;
  originShouldDisplayNotification: boolean = true;
  configurationNotificationConfig!: ComputeEPlusConfigurationNotificationConfig;

  async mounted() {
    this.createGlobalEventsHandlers();
    this.showNotification();
  }

  async createGlobalEventsHandlers() {
    this.globalBus.$on("relevantViewChangedForFeatureNotification", this.updateNotificationState);
  }

  updateNotificationState(origin: string, subscription: SubscriptionDTO | FlatSubscription) {
    switch (origin) {
      case "_tabTopology":
        this.originShouldDisplayNotification = !!subscription
          ? !this.$jfFeatureNotifications.subscriptionCanConfigureNewInstances(subscription)
          : false;
        break;
      case "_tabUsage":
        this.originShouldDisplayNotification = true;
        break;
      default:
        this.originShouldDisplayNotification = true;
    }

    this.manageDisplayNotification();
  }

  manageDisplayNotification() {
    if (this.originShouldDisplayNotification) {
      this.showNotification();
      return;
    }

    if (this.currentNotificationId !== undefined) {
      this.$jfNotification.close(this.currentNotificationId);
      this.clearCurrentNotificationId();
    }
  }

  async loadConfigurationNotificationConfig() {
    this.configurationNotificationConfig = await this.$jfFeatureNotifications.getEPlusConfigurationNotificationConfig();
  }

  get configData() {
    return this.configurationNotificationConfig && this.configurationNotificationConfig.data;
  }

  async showNotification() {
    await this.loadConfigurationNotificationConfig();
    if (this.shouldShowNotification()) {
      this.createAndShowNotification();
    }
  }

  async createAndShowNotification() {
    this.currentNotificationId = "feature_" + Date.now();
    let config: JFEssentialsNotificationConfig = {
      id: this.currentNotificationId,
      ignoreDuplicates: true,
      duration: -1,
      group: "feature",
      text: this.configData && this.configData.html,
      data: {
        icon: {
          type: "image",
          source: bellColoredIcon,
        },
        onClose: (close: Function) => {
          this.clearCurrentNotificationId();
          this.ePlusConfigNewServerNotificationClosedOnPurpose(true);
          close();
        },
        cta: {
          display: !!this.configData && this.configData.canDisplayCta,
          text: (this.configData && this.configData.ctaBtnText) || "",
          classes: ["btn-white"],
          onClick: async (close: Function) => {
            const accountNumber = this.configData && this.configData.enterprisePlusAccountNumber;
            if (!accountNumber) {
              return;
            }
            await this.setVuexActiveTab("_tabTopology");
            if (this.$route.path === `/subscriptions/${accountNumber}/`) {
              return;
            }
            await this.$router.push(
              {
                name: "subscriptions.detail",
                params: { accountNumber: accountNumber.toString() },
              },
              () => {
                this.clearCurrentNotificationId();
                close();
              },
            );
          },
        },
      },
    };

    this.$jfNotification.success(config);
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isTablet() {
    return this.$mq === "tablet";
  }

  shouldShowNotification() {
    return (
      !this.isMobile &&
      !this.isTablet &&
      this.configurationNotificationConfig.canDisplay &&
      this.currentNotificationId === undefined &&
      !this.isEPlusConfigNewServerNotificationClosedOnPurpose &&
      this.originShouldDisplayNotification
    );
  }

  clearCurrentNotificationId() {
    this.currentNotificationId = undefined;
  }

  destroyed() {
    this.$jfNotification.clearAll("feature");
  }
}
