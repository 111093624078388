
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import PageHeader from "@/components/landingPages/quickPurchaseNew/PageHeader.vue";
import {
  FeatureName,
  FeatureSelection,
  PageContent,
  PricingCard,
  QuickPurchasePaymentType,
} from "@/types/localtypes/quickPurchase";
import QuickPurchaseCard from "@/components/landingPages/quickPurchaseNew/QuickPurchaseCard.vue";
import QuickPurchaseFeatureGroup from "@/components/landingPages/quickPurchaseNew/QuickPurchaseFeatureGroup.vue";
import QuickPurchaseActionBar from "@/components/landingPages/quickPurchaseNew/QuickPurchaseActionBar.vue";
import { Getter } from "vuex-class";
import { QuickPurchaseData } from "@/types/localtypes";
import { usersService } from "@/services/user";
import logger from "@/util/logger";
import { Tier, SubscriptionTypes, RatePlanSubscription } from "@jfrog-ba/myjfrog-common";
import ComparisonTableNew from "@/components/pricingTable/ComparisonTableNew.vue";
import PricingTiersInfo from "@/components/landingPages/quickPurchaseNew/PricingTiersInfo.vue";

declare var window: any;

@Component({
  name: "QuickPurchaseNew",
  components: {
    PageHeader,
    QuickPurchaseCard,
    QuickPurchaseFeatureGroup,
    QuickPurchaseActionBar,
    ComparisonTableNew,
    PricingTiersInfo,
  },
})
export default class QuickPurchaseNew extends Vue {
  @Inject() readonly quickPurchaseBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  isLoading = true;
  @Prop() pageContent!: PageContent;
  actionData = {
    plan: "",
    price: 0,
    buttonText: "",
    isCustomOffer: false,
  };
  prevScrollPos = 0;
  hasFree = true;
  isPageScrolled = false;
  selection: FeatureSelection = {
    basePlan: "FREE",
    planByFeatures: "FREE",
    baseFeatures: [] as Array<FeatureName>,
    extraFeatures: [] as Array<FeatureName>,
    iotFeatures: [] as Array<FeatureName>,
    currentPaymentType: "",
  };
  ratePlanTiers: Array<Tier> | null = null;
  ratePlanTiersAll: Array<RatePlanSubscription> | null = null;
  ratePlanLoaded = false;
  showTiers = false;
  async created() {
    this.isLoading = false;
    this.selectCurrentPlanFeatures();
    this.listenToEvents();
  }

  mounted() {
    this.prevScrollPos = window.pageYOffset;
    window.addEventListener("scroll", this.initStickyHeader);
    window.addEventListener("wheel", this.initStickyHeader);
    this.getAllRatePlanTiers();
  }

  selectCurrentPlanFeatures() {
    if (!this.quickPurchaseData) return;
    if (this.quickPurchaseData.currentPaymentType === "FREE") {
      const plan = this.selectPlan("FREE");
      plan.active = false;
      this.selection.basePlan = "FREE";
      this.selection.currentPaymentType = this.quickPurchaseData.currentPaymentType;
    } else if (["TRIAL_ENTERPRISE", "TRIAL"].includes(this.quickPurchaseData.currentPaymentType as string)) {
      const plan = this.selectPlan("ENTERPRISE_X_MONTHLY");
      plan.trial = true;
      this.hasFree = false;
      this.selection.basePlan = "ENTERPRISE_X_MONTHLY";
      this.selection.currentPaymentType = this.quickPurchaseData.currentPaymentType as string;
      this.pageContent.cards.plans = this.pageContent.cards.plans.filter(p => p.paymentType !== "FREE");
    }
    this.pageContent.cards.plans.forEach(plan => {
      const subscriptionType = this.quickPurchaseData?.subscriptionTypes?.filter(
        s => s.paymentType === plan.paymentType,
      );
      if (subscriptionType?.length) {
        plan.price = Number(subscriptionType[0].price);
      }
    });

    // setTimeout(() => {
    //   this.changeBasePlan(this.selection.basePlan);
    // }, 300);
  }
  async getQuickPurchaseData() {
    const serverName = this.$route.query.serverName as string;
    const token = this.$route.query.token as string;

    const quickPurchaseDataResponse = await usersService.getQuickPurchaseData({
      token,
      serverName,
      paymentTypes: [], // not needed anymore
    });

    if (!quickPurchaseDataResponse) {
      logger.error(`unable to get quick purchase Data from the server.`);
      return;
    }

    const quickPurchaseData: QuickPurchaseData = {
      token,
      serverName,
      hasUpgraded: false,
      upgradeInProgress: false,
      hasError: false,
      subscriptionTypes: quickPurchaseDataResponse.subscriptionTypes,
      currentPaymentType: quickPurchaseDataResponse.currentPaymentType,
      hasPipelines: quickPurchaseDataResponse.hasPipelines,
      promotionItems: quickPurchaseDataResponse.promotions,
    };

    return quickPurchaseData;
  }

  private listenToEvents() {
    this.quickPurchaseBus.$on("changeBasePlan", this.changeBasePlan);
    this.quickPurchaseBus.$on("featureAdded", this.onFeatureAdded);
    this.quickPurchaseBus.$on("featureRemoved", this.onFeatureRemoved);
  }

  changeBasePlan(paymentType: QuickPurchasePaymentType) {
    const plan = this.selectPlan(paymentType);
    this.selection.basePlan = plan.paymentType;
    this.selection.baseFeatures = plan.baseFeatures;
    this.selection.planByFeatures = plan.paymentType;
    this.selection.extraFeatures = [];
    this.quickPurchaseBus.$emit("featureChange", this.selection);
    this.setActionValues();
  }
  selectPlan(plan: QuickPurchasePaymentType) {
    return this.pageContent.cards.plans.filter(p => p.paymentType === plan)[0];
  }
  onFeatureAdded(value: FeatureName) {
    if (value.startsWith("iot_")) {
      this.selection.iotFeatures = [value];
    } else {
      if (value === "JAS") {
        if (this.selection.planByFeatures === "PRO_TEAM_MONTHLY") {
          const plan = this.selectPlan("ENTERPRISE_X_MONTHLY");
          this.selection.planByFeatures = plan.paymentType;
          this.selection.extraFeatures = [...plan.baseFeatures];
        }
      }
      if (["Artifactory", "ArtifactoryEnt", "FullScc", "Xray"].includes(value)) {
        //upgrade plan
        const matchedPlan = this.getMatchedPlan(value);
        this.selection.planByFeatures = matchedPlan.paymentType;
        const extraFeatures: Array<FeatureName> = [];
        for (let feature of matchedPlan.baseFeatures) {
          if (!this.selection.baseFeatures.includes(feature as FeatureName)) {
            extraFeatures.push(feature as FeatureName);
          }
        }
        this.selection.extraFeatures = extraFeatures;
      } else {
        this.selection.extraFeatures.push(value);
      }
    }

    this.quickPurchaseBus.$emit("featureChange", this.selection);
    this.setActionValues();
  }
  onFeatureRemoved(value: FeatureName) {
    if (value.startsWith("iot_")) {
      this.selection.iotFeatures = [];
      this.quickPurchaseBus.$emit("featureChange", this.selection);
      this.setActionValues();
      return;
    }
    if (this.selection.basePlan === this.selection.planByFeatures || value === "JAS") {
      this.selection.extraFeatures = this.selection.extraFeatures.filter(f => f !== value);
    } else {
      const upgradedPlan = this.selectPlan(this.selection.planByFeatures);
      for (let feature of upgradedPlan.baseFeatures) {
        this.selection.extraFeatures = this.selection.extraFeatures.filter(f => f !== feature);
      }
      let downgradedPlan = this.pageContent.cards.plans.filter(p => p.id === upgradedPlan.id - 1)[0];
      if (downgradedPlan.baseFeatures.includes(value)) {
        downgradedPlan = this.pageContent.cards.plans.filter(p => p.id === downgradedPlan.id - 1)[0];
      }
      this.selection.planByFeatures = downgradedPlan.paymentType;
      this.selection.extraFeatures = downgradedPlan.baseFeatures;
    }
    this.quickPurchaseBus.$emit("featureChange", this.selection);
    this.setActionValues();
  }

  getMatchedPlan(feature: FeatureName): PricingCard {
    const plans: PricingCard[] = this.pageContent.cards.plans; //.filter(p => p.paymentType !== "FREE");
    for (let i = plans.length - 1; i >= 0; i--) {
      const plan: PricingCard = plans[i];
      const featureExists = plan.baseFeatures.filter((f: FeatureName) => f === feature);
      if (featureExists.length === 0 && i < plans.length - 1) {
        return plans[i + 1];
      }
    }
    return this.pageContent.cards.plans.filter(p => p.paymentType === "FREE")[0];
  }
  unmounted() {
    window.removeEventListener("scroll", this.initStickyHeader);
  }

  setActionValues() {
    let isCustom = false;
    if (
      this.selection.iotFeatures.length ||
      this.selection.basePlan === "ENTERPRISE_PLUS" ||
      this.selection.planByFeatures === "ENTERPRISE_PLUS" ||
      this.selection.extraFeatures.includes("JAS")
    ) {
      isCustom = true;
    }
    const plan = this.selectPlan(this.selection.planByFeatures);
    let price = plan.price;
    if (isCustom) {
      price = 0;
    }
    if (
      this.selection.extraFeatures.includes("JAS") &&
      this.selection.planByFeatures === "ENTERPRISE_X_MONTHLY" &&
      !this.selection.iotFeatures.length
    ) {
      price = plan.price + 2250;
    }
    this.actionData = {
      plan: plan.title === "FREE" ? "" : plan.title,
      price,
      buttonText: isCustom ? "Get a Quote" : "Buy Now",
      isCustomOffer: isCustom,
    };
    this.setPlanRatePlanTiers();
  }

  setPlanRatePlanTiers() {
    const plan = this.selectPlan(this.selection.planByFeatures);
    let ratePlanId = "";
    if (this.quickPurchaseData && this.quickPurchaseData.subscriptionTypes) {
      ratePlanId = this.quickPurchaseData.subscriptionTypes.filter(s => s.paymentType === plan.paymentType)[0]
        .ratePlanId;
    }
    this.ratePlanTiers = this.ratePlanTiersAll?.filter(p => p.ratePlanId === ratePlanId)[0].tiers[
      "Consumption"
    ] as Array<Tier>;
  }
  onShowTiers(value: boolean) {
    this.showTiers = value;
  }
  initStickyHeader() {
    const scrollOffset = 775;

    if (window.pageYOffset > 0 && window.pageYOffset > scrollOffset) {
      this.isPageScrolled = true;
    } else {
      this.isPageScrolled = false;
    }
  }

  handleBuyAction() {
    const plan = this.selectPlan(this.selection.planByFeatures);

    if (this.actionData.isCustomOffer) {
      const extraFeatures = "extra:" + [...this.selection.extraFeatures, ...this.selection.iotFeatures].join(",");
      this.toGetAQuote(this.actionData.plan, plan.paymentType, "DEVOPS", extraFeatures);
    } else {
      let ratePlanId = "";
      if (this.quickPurchaseData && this.quickPurchaseData.subscriptionTypes) {
        ratePlanId = this.quickPurchaseData.subscriptionTypes.filter(s => s.paymentType === plan.paymentType)[0]
          .ratePlanId;
      }
      const promotion = this.quickPurchaseData.promotionItems?.filter(p => p.paymentType === plan.paymentType);
      const isInPromotion = promotion && promotion[0];

      this.toCheckout(this.actionData.plan, plan.paymentType, ratePlanId, [], plan.price, !!isInPromotion);
    }
  }

  toCheckout(
    planName: string,
    paymentType: QuickPurchasePaymentType,
    planId: string,
    additionalTypes: SubscriptionTypes[],
    price: number,
    isInPromotion: boolean,
  ) {
    const currency = "$";
    this.$router.push({
      name: "quick-purchase-checkout",
      params: {
        planName,
        paymentType: paymentType,
        planId: planId,
        additionalPlanId: "",
        additionalPaymentType: paymentType,
        purchaseType: "month",
        purchaseCurrency: currency,
        purchaseAmount: price + "",
        isInPromotionStr: isInPromotion + "",
      },
    });
  }

  toGetAQuote(planName: string, paymentType: string, core: string, extraFeatures: string) {
    this.$router.push({
      name: "quick-purchase-get-a-quote",
      params: { planName, paymentType: paymentType, core, extraFeatures },
    });
  }

  async getAllRatePlanTiers() {
    let ratePlanIds: Array<string> = [];
    if (this.quickPurchaseData && this.quickPurchaseData.subscriptionTypes) {
      ratePlanIds = this.quickPurchaseData.subscriptionTypes
        .filter(s => ["ENTERPRISE_X_MONTHLY", "PRO_TEAM_MONTHLY"].includes(s.paymentType as string))
        .map(s => s.ratePlanId);
    }
    this.ratePlanTiersAll = await this.$jfSubscriptions.getRatePlanTiers(ratePlanIds);

    this.ratePlanLoaded = true;
  }
}
