
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "change-password",
  $_veeValidate: { validator: "new" },
  components: {
    JFTextField,
    JFFormControl,
    LoadingMask,
  },
})
export default class ChangePassword extends Vue {
  hiddenUsername = "";
  oldPassword = "";
  password = "";
  validatePassword = "";
  isLoading = false;
  tooltip =
    "&#x2022; Password should be 8 - 32 characters long<br>" +
    "&#x2022; Password should contain at least one uppercase and one lowercase character<br>" +
    "&#x2022; Password should contain at least one number<br>" +
    "&#x2022; Password should contain at least one special character";

  newPasswordLabelIcon = {
    iconClass: "icon-info",
    tooltipText: this.tooltip,
  };
  mounted() {
    this.$jfModal.childComponentIsSet();
    ((this.$refs["old-password-input"] as Vue).$el.querySelector("input") as HTMLInputElement).focus();
  }

  get newPasswordInputName() {
    return this.$utils.generateUniqInputName("newPassword");
  }
  get confirmPasswordInputName() {
    return this.$utils.generateUniqInputName("confirmPassword");
  }

  onKeyDown(e: any) {
    if (["shift", "capslock"].includes(e.key.toLowerCase())) {
      return;
    }
    const elementName = e.target.name;
    if (this.errors.items.find(item => item.field === elementName)) {
      this.errors.remove(elementName);
    }
  }

  onBlur(e: any) {
    this.$validator.validate(e.target.name);
  }

  get shouldDisableSubmitBtn() {
    return this.errors.any() || !this.oldPassword || !this.password || !this.validatePassword;
  }

  dismiss() {
    this.$jfModal.dismiss();
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  async changePassword() {
    try {
      if (!(await this.$validator.validateAll())) {
        return;
      }
      this.isLoading = true;
      await this.$jfUsers.changePassword({ oldPassword: this.oldPassword, newPassword: this.password });
      this.isLoading = false;
      this.$jfNotification.success({ text: this.$jfMessages.password_update_success });
      this.$jfWebeyez.send({ goal_key: "change_password", isSuccess: true });
      this.dismiss();
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      let notificationClasses = [];
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpBody.result === storeErrorsMapping.changePassword.badPolicy) {
          // when receiving bad policy errors the RestClientError's httpBody Object will contain a errors array that we need to display in list
          errorMessage = this.errorsToHTMLList(e.httpBody.errors) || e.httpBody.message;
          notificationClasses.push("text-left");
        } else {
          // can be 'invalid credentials' or other rest client error
          errorMessage = this.$jfMessages.generateMailLink(e.httpBody.message || (e.httpStatusText as string));
        }
      }
      this.$jfNotification.error({
        title: "Error",
        text: errorMessage || e,
        data: { textWrapperClasses: notificationClasses },
      });
      this.$jfWebeyez.send({ goal_key: "change_password", isSuccess: false, errorMessage: errorMessage });
    }
  }

  errorsToHTMLList(errors?: string[]): string | null {
    return errors ? errors.map(error => `<div mb-2>&#x2022; ${error}.</div>`).join("") : null;
  }
}
