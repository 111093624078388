
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "DynamicImage",
  components: {},
})
export default class DynamicImage extends Vue {
  @Prop({ default: "" })
  private icon!: string;
  @Prop({ default: undefined })
  private click!: () => any;
  @Prop({ default: 24 })
  private height!: number;
  @Prop({ default: 0 })
  private width!: number;

  get iconSrc(): string | null {
    //Async components could set their icons on after load
    if (this.icon) {
      try {
        return this.$jfImages.get(this.icon);
      } catch (e) {
        this.$log.error("Failed to retrieve icon : " + this.icon);
      }
    }
    return null;
  }

  get getStyle(): string {
    let style: any = { height: this.height + "px" };
    if (this.width > 0) {
      style.width = this.width + "px";
    }
    return style;
  }

  imageClick() {
    this.click && this.click();
  }
}
