
import { Component, Vue } from "vue-property-decorator";
import DynamicImage from "@/components/common/DynamicImage.vue";
import { registrationsService } from "@/services/registrations";
@Component({
  name: "MPUnifiedError",
  components: { DynamicImage },
})
export default class MPUnifiedError extends Vue {
  marketplace = "";
  title = "";
  icon = "";

  mounted() {
    this.getMarketplace();
  }

  get ownerEmail() {
    const email = sessionStorage.getItem("ownerEmail") as string;
    return email ? `(${email})` : "";
  }
  getMarketplace() {
    switch (sessionStorage.getItem("mpOrigin") as string) {
      case "AWS_MP":
        this.marketplace = "AWS";
        break;
      case "AZURE_MP":
        this.marketplace = "Azure";
        break;
      case "GCP_MP":
        this.marketplace = "GCP";
        break;
    }
  }

  get errorMessage() {
    const errCode = Number(this.$route.query.result);
    const requestId = this.$route.query.requestId;
    this.title = "Error";
    this.icon = "danger.svg";
    switch (errCode) {
      case 1007:
        this.$emit("onError");
        return `Your Marketplace token has expired. Return to your Marketplace account and restart your registration process. <p>requestId: ${requestId}</p>`;
      case 1010:
        this.$emit("onError");
        return `Your Marketplace token is invalid. Return to your Marketplace account and restart your registration process. <p>requestId: ${requestId}</p>`;
      case 1111111:
        this.title = "";
        this.icon = "rocket-loader.gif";
        this.sendToMarketo();
        this.$emit("completed");
        return `<h1>Your environment will be ready soon!</h1>
<p>We are working on setting up your environment.  You will receive an email once we are done.</p>
`;
      case 22222222:
        this.title = "";
        this.icon = "rocket-loader.gif";
        this.sendToMarketo();
        return `<h1>Congratulations!</h1>
<p>We successfully generated your license/s. The license owner ${this.ownerEmail} will receive an email shortly</p>
`;
      default:
        this.$emit("onError");
        return `Something went wrong while retrieving your data from ${this.marketplace} Marketplace. Please contact
      <a href="mailto:service@jfrog.com">service@jfrog.com</a> <p>requestId: ${requestId}</p>`;
    }
  }

  async sendToMarketo() {
    const data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }

    const allFormsData = JSON.parse(data);
    let cloudProvider: string;
    switch (allFormsData) {
      case "GCP_MP":
        cloudProvider = "Google";
        break;
      case "AZURE_MP":
        cloudProvider = "Azure";
        break;
      default:
        cloudProvider = "Amazon";
    }
    allFormsData.googleClientId = this.$jfMarketo.getCookie();
    allFormsData.cloudProvider = cloudProvider.toLowerCase();
    allFormsData.uiReferralLandingPage = sessionStorage.getItem("uiReferralLandingPage");
    allFormsData.marketoCookie = this.$jfMarketo.getCookie();
    if (!allFormsData.subscriptionType) {
      const verifyTokenResponse: string = sessionStorage.getItem("verifyTokenResponse") as string;
      allFormsData.subscriptionType = JSON.parse(verifyTokenResponse).paymentType;
    }
    this.$log.info("marketo request", allFormsData);

    try {
      await registrationsService.marketplaceMarketo(allFormsData);
    } catch (e) {}
  }
}
