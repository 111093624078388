
import { Component, Prop, Vue } from "vue-property-decorator";
import { ContentNode, Theme } from "@/types/layout/contentNode";

@Component({
  name: "SimpleIconTitle",
  components: {},
})
export default class SimpleIconTitle extends Vue {
  @Prop({ default: [] }) private data!: ContentNode;
  @Prop({ default: "" }) private theme!: Theme;
  @Prop({ default: "" }) private cssClass!: string;

  iconSrc(icon: string): string | null {
    //Async components could set their icons on after load
    if (icon) {
      try {
        return this.$jfImages.get(icon);
      } catch (e) {
        this.$log.error("Failed to retrieve icon : " + icon);
      }
    }
    return null;
  }

  get wrapperClasses(): string[] {
    let classes = ["wrapper"];
    classes.push(this.$mq);
    classes.push(this.theme);
    classes.push(this.cssClass);
    return classes;
  }
}
