
import { Component, Prop, Vue } from "vue-property-decorator";
import { FeatureGroup, FeatureName } from "@/types/localtypes/quickPurchase";
import QuickPurchaseFeature from "@/components/landingPages/quickPurchaseNew/QuickPurchaseFeature.vue";

@Component({
  name: "QuickPurchaseFeatureGroup",
  components: { QuickPurchaseFeature },
})
export default class QuickPurchaseFeatureGroup extends Vue {
  @Prop() data!: FeatureGroup;
  @Prop() private eventBus!: Vue;
  @Prop() currentPaymentType!: string;

  created() {}
}
