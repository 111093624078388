
import { Component, Vue, Watch } from "vue-property-decorator";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { marketplaceRouteMeta } from "@/types/localtypes";

@Component({
  name: "RegistrationCredentials",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFTextField },
})
export default class RegistrationCredentials extends Vue {
  passwordConfirm = "";
  routeParentChildMeta: marketplaceRouteMeta = {};
  ctrlDown = false;
  deleteConformPasswordOnBlur = false;
  email = "";
  data = {
    regionCode: "",
    regionName: "",
    password: "",
    serverName: "",
    subscriptionType: "",
    sfToken: "",
    cloudProvider: "",
  };

  created() {
    this.getRouteParentChildMeta();
  }

  mounted() {
    this.email = (this.$route.query.email as string).replace(" ", "+");
  }

  getRouteParentChildMeta() {
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });
  }

  tooltip = {
    email: `This is the email address you used to register for the JFrog trial. To use a different email address,<br/>
      please contact your account manager or service@jfrog.com`,
    password: `<ul>
<li>Password should be 8-32 characters long</li>
      <li>Password should contain at least one uppercase and one lowercase character</li>
      <li>Password should contain at least one number</li>
      <li>Password should contain at least one special character</li>
</ul>`,
  };

  onConfirmPasswordKeydown(event: any) {
    if ([17, 91].includes(event.keyCode)) {
      this.ctrlDown = true;
    }
    if (this.ctrlDown && (event.key === "v" || event.key === "V")) {
      setTimeout(() => {
        this.passwordConfirm = "";
      }, 100);
    }
  }

  onConfirmPasswordKeyup(event: any) {
    if ([17, 91].includes(event.keyCode)) {
      this.ctrlDown = false;
    } else {
      this.deleteConformPasswordOnBlur = false;
    }
    if (this.ctrlDown && (event.key === "v" || event.key === "V")) {
      setTimeout(() => {
        this.passwordConfirm = "";
      }, 100);
    }
    this.handleOnDataChange();
  }

  onRightClick(event: any) {
    this.deleteConformPasswordOnBlur = true;
  }

  onConfirmPasswordBlur() {
    if (this.deleteConformPasswordOnBlur) {
      this.passwordConfirm = "";
    }
    this.handleOnDataChange();
  }

  @Watch("data", { deep: true })
  onDataChange() {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 300);
  }

  handleOnDataChange() {
    if (this.errors.any() || !this.data?.password || !this.passwordConfirm) {
      this.$emit("credentialsFilled", false);
      return;
    }
    let token = this.$route.query.token as string;

    if (token && this.data.password && this.data.password === this.passwordConfirm) {
      token = decodeURIComponent(token).replaceAll(" ", "+");
      this.data.sfToken = token;
      this.data.subscriptionType = this.routeParentChildMeta.subscriptionType;
      sessionStorage.setItem("registrationFormData", JSON.stringify(this.data));
      sessionStorage.setItem("pass", this.data.password);

      this.$emit("credentialsFilled", true);
    } else {
      this.$emit("credentialsFilled", false);
    }
  }
}
