import { routerOptions } from "@/router";
import { messagesService } from "@/services/messages";
import { usersService } from "@/services/user";
import { SubscriptionExplanation } from "@/types/localtypes";
import {
  MenuConfiguration,
  MenuConfigurationParameters,
  MenuItemProps,
  MenusService,
  MenuTooltipConfigurationItem,
} from "@/types/services/menus";
import logger from "@/util/logger";
import { JpuDTO, SubscriptionMeta } from "@jfrog-ba/myjfrog-common";
import { RouteConfig } from "vue-router";
import { subscriptionsService } from "./subscriptions";

const getDeepFlattenRoutes = (routes: RouteConfig[]): RouteConfig[] => {
  return routes.reduce((flatten, currentRoute) => {
    const childrenRoutes = currentRoute.children ? getDeepFlattenRoutes(currentRoute.children) : [];
    return [...flatten, currentRoute, ...childrenRoutes];
  }, [] as RouteConfig[]);
};

/* find the route 'meta.deep' route parameter value of the provided routeName */
const deepOfRoute = (routeName: string): number => {
  const flattenRoutes = getDeepFlattenRoutes(routerOptions.routes as RouteConfig[]);
  const matchedRoute = flattenRoutes.find(route => !!route.name && route.name === routeName);
  return matchedRoute && matchedRoute.meta && matchedRoute.meta.deep ? matchedRoute.meta.deep : 0;
};

export const menusService: MenusService = {
  getMainMenu: async (menuConfiguration: MenuConfiguration) => {
    const menuItems: MenuItemProps[] = [
      {
        id: "subscriptions",
        label: "Subscriptions",
        icon: "icon-mjf-manage-subscriptions",
        path: "/subscriptions",
        dataGtmEventLabel: "subscriptions",
        disabled: false,
        forceDisplay: true,
        deep: deepOfRoute("subscriptions.list"),
      },
      {
        id: "billing_accounts",
        label: "Billing Accounts",
        icon: "icon-mjf-manage-billing",
        path: "/billing-accounts",
        dataGtmEventLabel: "billing-accounts",
        disabled: !(await usersService.hasBillingAccounts()),
        forceDisplay: true,
      },
      {
        id: "sandbox",
        label: "Sandbox",
        icon: "icon-builds",
        path: "/sandbox",
        dataGtmEventLabel: "sandbox",
        disabled: false,
        forceDisplay: true,
      },
      {
        id: "upgrade",
        label: "Upgrade",
        icon: "icon-mjf-upgrade",
        path: "upgrade",
        dynamicPathFn: menuConfiguration => {
          const subscriptions = menuConfiguration.subscriptions;
          if (menuConfiguration.fromContextualConfiguration || !subscriptions || subscriptions.length > 1) {
            // this code should never be reached. Security purpose.
            logger.warn("unable to calculate dynamic path for route 'upgrade'. Falling back to default behavior.");
            return "upgrade";
          }
          // calling "upgrade" route from a non-contextual view (like billing account) and where there is only 1 subscription.
          // So we can calculate the final Path dynamically without any doubt
          return `/subscriptions/${subscriptions[0].accountNumber}/upgrade`;
        },
        dataGtmEventLabel: "upgrade",
        disabled: false,
        deep: deepOfRoute("subscriptions.upgrade"),
      },
      {
        id: "buy",
        label: "Buy",
        icon: "icon-mjf-buy",
        path: "buy",
        dataGtmEventLabel: "buy",
        disabled: false,
        deep: deepOfRoute("subscriptions.buy"),
      },
    ];
    return generateContextualMenu(
      menuItems.filter(menuItemProps => {
        if (menuItemProps.id === "sandbox") {
          return process.env.VUE_APP_DISPLAY_SANDBOX_MENU && process.env.VUE_APP_DISPLAY_SANDBOX_MENU === "true";
        }
        return true;
      }),
      menuConfiguration,
    );
  },
  getActionMenu: (menuConfiguration: MenuConfiguration) => {
    const menuItems: MenuItemProps[] = [
      {
        id: "add-pipelines",
        label: "Add <br>CI/CD Pipelines",
        icon: "icon-mjf-pipelines",
        path: "add-pipelines",
        dynamicPathFn: menuConfiguration => {
          const subscriptions = menuConfiguration.subscriptions;
          if (menuConfiguration.fromContextualConfiguration || !subscriptions || subscriptions.length > 1) {
            // this code should never be reached. Security purpose.
            logger.warn(
              "unable to calculate dynamic path for route 'add-pipelines'. Falling back to default behavior.",
            );
            return "add-pipelines";
          }
          // calling "add-pipelines" route from a non-contextual view (like billing account) and where there is only 1 subscription.
          // So we can calculate the final Path dynamically without any doubt
          return `/subscriptions/${subscriptions[0].accountNumber}/add-pipelines`;
        },
        dataGtmEventLabel: "add-pipelines",
        disabled: false,
        deep: deepOfRoute("subscriptions.add.pipelines"),
      },
      {
        id: "add-security",
        label: "Add Security <br>& Compliance",
        icon: "icon-art-xray",
        path: "add-security",
        dynamicPathFn: menuConfiguration => {
          const subscriptions = menuConfiguration.subscriptions;
          if (menuConfiguration.fromContextualConfiguration || !subscriptions || subscriptions.length > 1) {
            // this code should never be reached. Security purpose.
            logger.warn("unable to calculate dynamic path for route 'add-security'. Falling back to default behavior.");
            return "add-security";
          }
          // calling "add-security" route from a non-contextual view (like billing account) and where there is only 1 subscription.
          // So we can calculate the final Path dynamically without any doubt
          return `/subscriptions/${subscriptions[0].accountNumber}/add-security`;
        },
        dataGtmEventLabel: "add-security",
        disabled: false,
        deep: deepOfRoute("subscriptions.add.security"),
      },
      {
        id: "add-security-premium",
        label: "Add Premium <br>Security Pack",
        icon: "icon-art-xray",
        path: "add-security-premium",
        dynamicPathFn: menuConfiguration => {
          const subscriptions = menuConfiguration.subscriptions;
          if (menuConfiguration.fromContextualConfiguration || !subscriptions || subscriptions.length > 1) {
            // this code should never be reached. Security purpose.
            logger.warn(
              "unable to calculate dynamic path for route 'add-security-premium'. Falling back to default behavior.",
            );
            return "add-security-premium";
          }
          // calling "add-security-premium" route from a non-contextual view (like billing account) and where there is only 1 subscription.
          // So we can calculate the final Path dynamically without any doubt
          return `/subscriptions/${subscriptions[0].accountNumber}/add-security-premium`;
        },
        dataGtmEventLabel: "add-security-premium",
        disabled: false,
        deep: deepOfRoute("subscriptions.add.security.premium"),
      },
    ];
    return generateContextualMenu(menuItems, menuConfiguration);
  },

  defineContextualMenuConfiguration: async (
    serverName,
    subscriptionMeta,
    onTooltipShow,
    fromContextualConfiguration,
  ) => {
    // We are rendering the menu for a page which manage a single subscription (SubscriptionDetail, BuyPage, ...)
    // so we can define the variables using the context inside the subscription Meta
    const subscriptions = await subscriptionsService.getSubscriptions();
    const subscriptionsMetas = subscriptions.map(s => s.meta);

    const menuConfigParameters: MenuConfigurationParameters = {
      subscriptions,
      // if the flag "fromContextualConfiguration" is set to false some path will be dynamicaly calculated (like for the upgrade page).
      // this will occurs when we will be on a single Subscription mode but coming from a non contextual page like billing account
      fromContextualConfiguration: fromContextualConfiguration !== undefined ? fromContextualConfiguration : true,
      isBuyMenuVisible: subscriptionsService.isBuyActionVisible(subscriptionMeta),
      isBuyMenuDisabled: false,
      isBuyPageReachable: subscriptionsService.isBuyPageReachable(subscriptionMeta),
      isUpgradeMenuVisible: subscriptionsService.isUpgradeActionVisible(subscriptionMeta),
      isUpgradeMenuDisabled: subscriptionsService.isUpgradePageDisabled(subscriptionMeta),
      isUpgradePageReachable: subscriptionsService.isUpgradePageReachable(subscriptionMeta),
      isAddSecurityMenuVisible: subscriptionsService.isXrayNewForCustomer(subscriptionsMetas),
      isAddSecurityMenuDisabled: !subscriptionsService.isAddXrayPageReachable(subscriptionMeta),
      isAddPipelinesMenuVisible: subscriptionsService.isAddPipelinesPageReachable(subscriptionMeta),
      isAddPipelinesMenuDisabled: false,
      isAddSecurityPremiumMenuVisible: subscriptionsService.isAddSecurityPremiumPageReachable(subscriptionMeta),
      isAddSecurityPremiumMenuDisabled: false,
      serverName: serverName,
      subscriptionMeta: subscriptionMeta,
      onTooltipShow: onTooltipShow,
    };
    return buildMenuConfiguration(menuConfigParameters);
  },

  defineNotContextualMenuConfiguration: async (subscriptions, onTooltipShow) => {
    // We are rendering the menu for a page which manage multiple subscriptions (SubscriptionList, BillingAccount, ...)
    // so we need to define the variables relative to all subscriptions.

    const isMultiSubscriptions = subscriptions.length > 1;

    // if we are in the case of a unique subscription we can handle the flow easier
    if (!isMultiSubscriptions) {
      const uniqSubscription = subscriptions[0];
      const serverName = subscriptionsService.extractMainServer(uniqSubscription.jpus, uniqSubscription.paymentType)
        .serverName;
      return menusService.defineContextualMenuConfiguration(serverName, uniqSubscription.meta, onTooltipShow, false);
    }

    const metas = subscriptions.map(s => s.meta);

    const menuConfigParameters: MenuConfigurationParameters = {
      subscriptions,
      fromContextualConfiguration: false,
      isBuyMenuVisible: false,
      isBuyMenuDisabled: true,
      isBuyPageReachable: false,
      isUpgradeMenuVisible: false,
      isUpgradeMenuDisabled: true,
      isUpgradePageReachable: false,
      isAddSecurityMenuVisible: subscriptionsService.isXrayNewForCustomer(metas),
      isAddPipelinesMenuVisible: false,
      isAddSecurityMenuDisabled: true,
      isAddPipelinesMenuDisabled: true,
      isAddSecurityPremiumMenuVisible: false,
      isAddSecurityPremiumMenuDisabled: true,
    };
    return buildMenuConfiguration(menuConfigParameters);
  },
  getTooltipConfig: (id: MenuItemProps["id"], meta: SubscriptionMeta) => {
    let subscriptionExplanation: SubscriptionExplanation | null;
    switch (id) {
      case "upgrade":
        subscriptionExplanation = subscriptionsService.getUpgradePageNotReachableExplanation(meta);
        if (subscriptionExplanation) {
          return {
            content: subscriptionExplanation.message,
            hasAction: hasTooltipAction(subscriptionExplanation.reason),
          };
        }
        break;
      case "buy":
        subscriptionExplanation = subscriptionsService.getBuyPageNotReachableExplanation(meta);
        if (subscriptionExplanation) {
          return {
            content: subscriptionExplanation.message,
            hasAction: hasTooltipAction(subscriptionExplanation.reason),
          };
        }
        break;
      case "add-pipelines":
        subscriptionExplanation = subscriptionsService.getBuyPageNotReachableExplanation(meta);
        if (subscriptionExplanation) {
          return {
            content: subscriptionExplanation.message,
            hasAction: hasTooltipAction(subscriptionExplanation.reason),
          };
        }
        break;
    }
    return {
      content: `Action is unprocessable. Please contact <a href="mailto:support@jfrog.com" class="blue-link">support@jfrog.com</a>.`,
      hasAction: false,
    };
  },
};

const hasTooltipAction = (reason: SubscriptionExplanation["reason"]): boolean => {
  switch (reason) {
    case "is_OSS":
      return false;
  }
  return true;
};

const buildMenuConfiguration = (parameters: MenuConfigurationParameters): MenuConfiguration => {
  const {
    fromContextualConfiguration,
    subscriptions,
    isBuyMenuVisible,
    isBuyMenuDisabled,
    isBuyPageReachable,
    isUpgradeMenuVisible,
    isUpgradeMenuDisabled,
    isUpgradePageReachable,
    isAddSecurityMenuVisible,
    isAddSecurityMenuDisabled,
    isAddPipelinesMenuVisible,
    isAddPipelinesMenuDisabled,
    isAddSecurityPremiumMenuVisible,
    isAddSecurityPremiumMenuDisabled,
    subscriptionMeta,
    onTooltipShow,
    serverName,
  } = parameters;
  const menuConfigDisplays: MenuConfiguration["displays"] = [];
  const menuConfigDisabled: MenuConfiguration["disabled"] = [];
  const menuConfigTooltips: MenuConfiguration["tooltips"] = [];
  if (isBuyMenuVisible) {
    menuConfigDisplays.push("buy");
    if (isBuyMenuDisabled) {
      menuConfigDisabled.push("buy");
    } else if (!isBuyPageReachable && serverName && subscriptionMeta) {
      menuConfigTooltips.push(getMenuTooltipConfigurationItem("buy", serverName, subscriptionMeta, onTooltipShow));
    }
  }
  if (isUpgradeMenuVisible) {
    menuConfigDisplays.push("upgrade");
    if (isUpgradeMenuDisabled) {
      menuConfigDisabled.push("upgrade");
    } else if (!isUpgradePageReachable && serverName && subscriptionMeta) {
      menuConfigTooltips.push(getMenuTooltipConfigurationItem("upgrade", serverName, subscriptionMeta, onTooltipShow));
    }
  }

  if (isAddSecurityMenuVisible) {
    menuConfigDisplays.push("add-security");
    if (isAddSecurityMenuDisabled) {
      menuConfigDisabled.push("add-security");
    }
  }
  if (isAddSecurityPremiumMenuVisible) {
    menuConfigDisplays.push("add-security-premium");
    if (isAddSecurityPremiumMenuDisabled) {
      menuConfigDisabled.push("add-security-premium");
    }
  }
  if (isAddPipelinesMenuVisible) {
    menuConfigDisplays.push("add-pipelines");
    if (isAddPipelinesMenuDisabled) {
      menuConfigDisabled.push("add-pipelines");
    }
  }

  return {
    displays: menuConfigDisplays,
    disabled: menuConfigDisabled,
    tooltips: menuConfigTooltips,
    fromContextualConfiguration,
    subscriptions,
  };
};

const generateContextualMenu = (menu: MenuItemProps[], menuConfiguration: MenuConfiguration) => {
  return menu
    .filter(menuItemProps => {
      return (
        menuItemProps.forceDisplay ||
        (menuConfiguration.displays && menuConfiguration.displays.indexOf(menuItemProps.id) !== -1)
      );
    })
    .map(menuItemProps => {
      const shouldDisabled =
        menuItemProps.disabled ||
        (menuConfiguration.disabled && menuConfiguration.disabled.indexOf(menuItemProps.id) !== -1);
      if (shouldDisabled) {
        return { ...menuItemProps, disabled: true };
      }
      const tooltipConfig =
        menuConfiguration.tooltips &&
        menuConfiguration.tooltips.find(tooltipConfig => tooltipConfig.id === menuItemProps.id);

      if (tooltipConfig) {
        return {
          ...menuItemProps,
          isTooltip: true,
          tooltipContent: tooltipConfig.tooltipContent,
          tooltipOnShow: tooltipConfig.tooltipOnShow,
        };
      }

      // if we are generating the menu from a non-contextual route (like billing-accounts) we should check if the menu item has a "dynamicPathFn" function defined.
      // If yes that means the path can be dynamicaly created. If no we simply return the item's default path.
      const finalPath =
        !menuConfiguration.fromContextualConfiguration && !!menuItemProps.dynamicPathFn
          ? menuItemProps.dynamicPathFn(menuConfiguration)
          : menuItemProps.path;

      return { ...menuItemProps, path: finalPath };
    });
};

const getMenuTooltipConfigurationItem = (
  id: MenuItemProps["id"],
  serverName: JpuDTO["serverName"],
  meta: SubscriptionMeta,
  onTooltipShow: MenuTooltipConfigurationItem["tooltipOnShow"],
): MenuTooltipConfigurationItem => {
  const tooltipConfig = menusService.getTooltipConfig(id, meta);

  let content = getTooltipCloseHTMLElement() + tooltipConfig.content;
  content += tooltipConfig.hasAction ? getTooltipFooterHTMLElement(id) : "";
  return {
    id: id,
    tooltipContent: content,
    tooltipHasAction: tooltipConfig.hasAction,
    tooltipOnShow: () =>
      onTooltipShow && onTooltipShow(id, serverName, tooltipConfig.content, tooltipConfig.hasAction, meta),
  };
};

const getTooltipCloseHTMLElement = () => {
  return `<span class="tooltip-close-icon" >&times;</span>`;
};

const getTooltipFooterHTMLElement = (id: MenuItemProps["id"]) => {
  return `<div class="tooltip-footer-wrapper" mt-3 fx fxsb > 

<div class="tooltip-footer-message-wrapper hidden mjf-green">
${messagesService.menu_tooltip_action_success}
</div>
<div class="tooltip-footer-buttons-wrapper">
      <button
        class="btn btn-xs btn-mjf-green tooltip-footer-button-valid" ml-3 data-gtm-event="menu-tooltip-${id}|contact-me-button"
      >
        Contact me
      </button></div></div>`;
};
