
import { Component, Vue } from "vue-property-decorator";
import { SsoOAuthRequest } from "@jfrog-ba/myjfrog-common";

declare var GITHUB_CLIENT_ID: any;
declare var GITHUB_SSO_REDIRECT_URL: any;

@Component({
  name: "SSOResponse",
  components: {},
})
export default class SSOResponse extends Vue {
  created() {
    this.getGithubUserInfo();
  }

  async getGithubUserInfo() {
    const error = this.$route.query.error_description as string;
    if (error) {
      this.$jfNotification.error({ text: error });
      return;
    }
    const request: SsoOAuthRequest = {
      clientId: GITHUB_CLIENT_ID,
      clientSecret: "",
      code: this.$route.query.code as string,
      redirectUri: GITHUB_SSO_REDIRECT_URL,
    };
    const ssoLoginInfo = await this.$jfRegistrations.getOAuthAccessToken(request);
    sessionStorage.setItem("ssoLoginInfo", JSON.stringify(ssoLoginInfo));
    window.location.href = this.landingRoute;
  }

  get landingRoute() {
    return (sessionStorage.getItem("ssoRedirectAfterSuccess") as string) + `&returnedFromSso=true`;
  }
}
