
import { Component, Vue } from "vue-property-decorator";
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";
import { CloudReactivationCheckEmailResponse, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { registrationsService } from "@/services/registrations";

declare var window: any;

@Component({
  name: "ServerReactivation",
  components: { CloudActivationBackground },
})
export default class ServerReactivation extends Vue {
  email = "";
  serverName = "";
  emptyCallSent = false;
  sent = false;
  failed = false;
  icon = "activation_repo.svg";
  freeReativationDisabled = false;
  data = {
    title: "Welcome Back!",
    description: "",
    contact: 'For further inquiries please contact <a href="mailto:service@jfrog.com">service@jfrog.com</a>.<br/>',
  };
  isAccountTrialEnterprise = false;
  paymentType = "";
  sentToMarketo = false;

  created() {
    this.serverName = this.$route.params.serverName;
    this.sendEmail();
  }

  get isButtonEnabled() {
    return this.email && this.serverName;
  }

  get showEmailForm() {
    return !this.sent && this.emptyCallSent && (this.isAccountTrialEnterprise || this.isFree);
  }
  async sendEmail() {
    if (this.isAccountTrialEnterprise) {
      await this.sendToMarketoTrialFlow();
      this.sentToMarketo = true;
      return;
    }
    try {
      await this.$jfCloudActivations.checkEmailForReactivation({
        serverName: this.serverName,
        email: this.email,
      });
    } catch (e) {}
    const resp = sessionStorage.getItem("cloudReactivationResponse");
    const response =
      resp == null ? { status: -1 } : JSON.parse(sessionStorage.getItem("cloudReactivationResponse") as string);
    this.handleResponse(response);
  }

  handleResponse(response: CloudReactivationCheckEmailResponse) {
    if (response.status === storeErrorsMapping.httpStatus.tooManyRequests) {
      this.$jfNotification.error({ text: "Invalid email", duration: 20000 });
      return;
    }
    this.paymentType = response.paymentType;
    this.email = response.email;
    switch (response.result) {
      case 0:
      case storeErrorsMapping.cloudReactivation.serverNotRelatedToEmail:
        this.data = {
          title: "Check Your Inbox!",
          description: "Reactivation instructions  were sent.",
          contact: "Didn't receive an email? Contact: <a href='service@jfrog.com'>service@jfrog.com</a>",
        };
        this.sent = true;
        this.emptyCallSent = true;
        break;
      case storeErrorsMapping.cloudReactivation.invalidField:
        this.emptyCallSent = true;
        if (this.email) {
          this.$jfNotification.error({ text: "Invalid email", duration: 20000 });
        }
        break;
      case storeErrorsMapping.cloudReactivation.serverNotYetReady:
        this.$router.push({
          name: "/reactivate-server-result",
          query: { token: response.token },
        });
        break;
      case storeErrorsMapping.cloudReactivation.serverAlreadyActive:
        window.location.replace(response.instanceUrl);
        break;
      case storeErrorsMapping.cloudReactivation.paymentTypeNotAllowedForReactivation:
        this.emptyCallSent = true;
        this.failed = true;
        this.data = {
          title: "Your server is no longer active",
          description: "",
          contact: `For further inquiries please contact <a href="mailto:service@jfrog.com">service@jfrog.com</a>.<br/>`,
        };
        break;
      case storeErrorsMapping.cloudReactivation.accountNotFree:
        this.isAccountTrialEnterprise = true;
        this.emptyCallSent = true;
        this.data = {
          title: "Time flies when you’re having fun.",
          description: "Your 14-day trial may be over, but you have options!",
          contact: `Need help choosing the right subscription or additional time on your trial? Just let us know and a DevOps expert will reach out to assist you.<br/>`,
        };
        break;
      case storeErrorsMapping.cloudReactivation.reactivationNotAllowedForFree:
        this.freeReativationDisabled = true;
        break;
      default:
        this.sent = true;
        this.emptyCallSent = true;
        this.failed = true;
        this.icon = "reactivation_failed.svg";
        this.data = {
          title: "Reactivation failed",
          description: "Please try again. ",
          contact: "If the issue persists, contact <a href='service@jfrog.com'>service@jfrog.com</a>",
        };
    }
  }

  get iconSrc() {
    try {
      return this.$jfImages.get(this.icon);
    } catch (e) {
      this.$log.error("Failed to retrieve icon : activation_repo.svg");
    }
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get isFree() {
    return this.paymentType === "FREE";
  }
  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("fxCol");
    }
    return classes;
  }

  async sendToMarketoTrialFlow() {
    const marketoType = "evaluateCloudEnterpriseReActivate";
    const comments = "Request to extend an expired Ent Trial";
    try {
      await registrationsService.enterpriseTrialMarketo({
        args: {
          email: this.email,
          type: marketoType,
          cookie: this.$jfMarketo.getCookie(),
          serverName: this.serverName,
          skipServerOwnerVerification: true,
        },
        data: { comments },
      });
      this.sentToMarketo = true;
    } catch (e) {
      this.$log.error(`error on calling marketo from trial flow : ${e.message}`);
    }
  }

  getGoogleClientId() {
    try {
      const tracker = window.ga.getAll()[0];
      return tracker.get("clientId");
    } catch (e) {
      return "n/a";
    }
  }
}
