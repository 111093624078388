import { MessagesService } from "@/types/services/messages";

export const messagesService: MessagesService = {
  app_something_went_wrong: `Something went wrong. Please try again.`,
  app_something_went_wrong_login: `Something went wrong. Please login again.`,
  app_session_expired: `Expired session. Please login again.`,
  login_user_not_found: `Invalid username, email or password. Please try again.`,
  too_many_requests: `Too many failed attempts, please try again in a few minutes`,
  menu_tooltip_action_success: `Thank you, we will contact you shortly.`,
  profile_update_success: `Profile successfully updated.`,
  password_update_success: `Password successfully updated.`,
  password_forgot_success: `<div class="text-left">Password reset instructions were sent.<br><span class="bold">Didn't receive an email?</span><br>Contact <a href="mailto:service@jfrog.com" class="ess-green-link underlined-link">service@jfrog.com</a></div>`,
  register_now_success: `<div class="text-left">Registrations instructions were sent.<br><span class="bold">Didn't receive an email?</span><br>Contact <a href="mailto:service@jfrog.com" class="ess-green-link underlined-link">service@jfrog.com</a></div>`,
  password_reset_success: `Password successfully reset.`,
  reset_password_failed_link_not_valid: `The link you followed has expired, please try again.`,
  registration_error: `An error occurred during the registration process. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  quick_purchase_should_login_to_upgrade: `Sign in to complete the upgrade.`,
  billing_list_fetch_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  billing_fetch_credit_card_fingerprint_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  billing_fetch_details_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  billing_fetch_estimated_payment_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  billing_disclaimer_next_expected_payment: `* The above account payment estimation is calculated up until yesterday end of day.<br/>Final payment details will be calculated according to the applicable billing document set forth by the JFrog accounting team.`,
  billing_disclaimer_next_expected_balance: `* The above balance is based on the last sent billing document.`,
  billing_disclaimer_aws_management: `Your billing information and settings are managed by AWS Marketplace.`,
  billing_disclaimer_gcp_management: `Your billing information and settings are managed by Google Cloud Platform Marketplace.`,
  billing_disclaimer_azure_management: `Your billing information and settings are managed by Microsoft Azure Marketplace.`,
  billing_payments_details_update_success: `Billing details successfully updated.`,
  billing_payments_credit_card_details_update_success: `Credit Card details successfully updated.`,
  billing_payments_debt_credit_card_details_update_success_active_account: `Your customer details have been updated successfully. You will receive an email once your credit card has been billed.`,
  billing_payments_debt_credit_card_details_update_success_blocked_account: `Your customer details have been updated successfully. You will receive an email once your credit card has been billed. A separate email will be sent once your JFrog service has been reactivated.`,
  billing_payments_customer_details_update_success: `Customer details successfully updated.`,
  subscriptions_list_fetch_failed: `An error occurred while loading the subscription information. Please try again, or contact  <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_buy_success: `Success! Your purchase is complete.<br />You can manage your account details at the <span class="span-router-link ess-green-link bold" to-router-path="/billing-accounts">Billing Accounts</span> section.`,
  subscriptions_buy_from_expired_success: `Success! Your purchase is complete and your Artifactory server was reactivated.<br /><br />You can manage your account details at the '<span class="span-router-link ess-green-link" to-router-path="/billing-accounts">Billing Accounts</span>' section.`,
  subscriptions_buy_error_calculator_error: `An error occurred while loading the pricing calculator. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_buy_error_unknown: `An error occurred during your purchase process. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_buy_error_missing_terms_and_conditions_agreement: `Please read and agree to the "Terms and Conditions" and "Privacy Policy".`,
  subscriptions_buy_error_is_enterprise_NEW: `Thank you for your interest in purchasing.<br/>To make this easy for you, and upon your confirmation, your account manager will contact you to complete the process.`,
  subscriptions_upgrade_proX_pro_success: `Your account has been successfully upgraded! An email will be sent shortly with your new cloud updates`,
  subscriptions_add_pipelines_success: `Your account has been successfully upgraded! An email will be sent shortly with your new cloud updates.`,
  subscriptions_add_premium_xray_success: `Your account has been successfully upgraded!`,
  subscriptions_add_pipelines_error_unknown: `An error occurred while adding CI/CD Pipelines. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_add_pipelines_error_insufficient_balance: `Unfortunately your account has insufficient balance to complete the upgrade, please contact <a href="mailto:ar.il@jfrog.com" class="red-link">ar.il@jfrog.com</a>.`,
  subscriptions_upgrade_proX_from_JCR_success: `Your subscription has been upgraded to Cloud Pro X. Check your email for additional information.`,
  subscriptions_upgrade_pro_from_JCR_success: `Your subscription has been upgraded to Cloud Pro. Check your email for additional information.`,
  subscriptions_upgrade_error_unknown: `An error occurred during the upgrade process. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_upgrade_error_is_prepaid: `Thank you for your interest.<br />Please <a href="https://jfrog.com/contact-us/" class="blue-link" target="_blank">Contact Us</a> to upgrade your subscription and add continuous security scanning and 24/7 R&D-level SLA support for both Artifactory and Xray Cloud.`,
  subscriptions_upgrade_error_is_prepaid_NEW: `Thank you for your interest in upgrading. To make this easy for you, your account manager will contact you to finalize the process.`,
  subscriptions_upgrade_error_xray_cant_upgrade: `Thanks you for your interest, your account manager will contact you.`,
  subscriptions_upgrade_error_is_enterprise_plus_NEW: `Thank you for your interest in upgrading.<br/>To make this easy for you, your account manager will contact you to complete the process.`,
  subscriptions_upgrade_error_is_OSS_NEW: `Thank you for your interest in upgrading.<br/>To keep the integrity of your existing open source license, we recommend opening a new account for your new commercial license. <a href="https://jfrog.com/artifactory/buy-now/?type=cloud" class="blue-link" target="_blank">Create a new account and purchase now!</a>`,
  subscriptions_set_notifications_success: `Notifications have been set.`,
  subscriptions_set_notifications_error_unknown: `An error occurred while loading your notifications. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_set_notifications_error_confirm: `An error occurred while saving your notifications. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_change_provider_success: `Your request has been sent successfully.`,
  subscriptions_change_provider_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_change_region_success: `Your request has been sent successfully.`,
  subscriptions_change_region_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_whitelist_ips_set_success: `Whitelist addresses have been updated successfully.`,
  subscriptions_whitelist_ips_set_error: `An error occurred while updating your Whitelist addresses. Please try again, or contact <a href="mailto:support@jfrog.com" class="red-link">support@jfrog.com</a> in case the problem persists.`,
  subscriptions_whitelist_ips_fetch_error: `An error occurred while fetching your Whitelist addresses. Please try again, or contact <a href="mailto:support@jfrog.com" class="red-link">support@jfrog.com</a> in case the problem persists.`,
  subscriptions_server_ip_meta_data_fetch_error: `An error occurred while fetching your servers IP configurations. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_private_link_meta_data_fetch_error: `An error occurred while fetching your AWS PrivateLink configurations. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_private_link_duplicate_error: `You have already submitted this Endpoint ID. Enter a different Endpoint ID or edit the existing one. For assistance, contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a>.`,
  subscriptions_servers_status_fetch_error: `An error occurred while fetching your servers status. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_whitelist_ips_deactivate_confirmation: `Please notice your cloud server will be available to any IP/CIDR address. Click Ok to confirm.`,
  subscriptions_whitelist_disclaimer_proX_trial: `Notice: The IP/CIDR addresses you have configured will be deleted once your JFrog Cloud Pro X trial ends.`,
  subscriptions_request_cancel_success: `Your cancel request has been successfully sent.`,
  subscriptions_request_cancel_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_request_downgrade_success: `Your downgrade request has been successfully sent.`,
  subscriptions_request_downgrade_xray_premium_success: `Your downgrade process has successfully started. A confirmation email has been sent.`,
  subscriptions_request_self_downgrade_success: `Your downgrade process has successfully started. A confirmation email has been sent.`,
  subscriptions_request_downgrade_error: `An error occurred while sending request. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_narcissus_task_in_progress: `Your previous request is still being processed. Try again in a few minutes and if the problem persists, please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a>.`,
  subscriptions_request_self_cancellation_success: `Your cancellation process has successfully started. A confirmation email has been sent.`,
  subscriptions_request_remove_pipelines_success: `Your request has been successfully submitted. A confirmation email has been sent.`,
  subscriptions_request_self_cancellation_error: `An error occurred while cancelling your subscription. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_Geo_Restriction_fetch_error: `An error occurred while loading Geolocation restriction settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="red-link">support@jfrog.com</a> in case the problem persists.`,
  subscriptions_Geo_Restriction_set_success: `Geolocation restrictions were successfully received and will be enforced in a few minutes.`,
  subscriptions_Geo_Restriction_set_error: `An error occurred while updating Geolocation restriction settings. Please try again, or contact <a href="mailto:support@jfrog.com" class="red-link">support@jfrog.com</a> in case the problem persists.`,
  subscriptions_request_remove_pipelines_error: `An error occurred while removing pipelines from your subscription. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_request_getaquote_success: `Your request was successfully sent!<br/>We will contact you.`,
  subscriptions_request_getaquote_error: `An error occurred while trying to send your request. Please contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a>.`,
  subscription_usage_graph_loading_failed: `An error occurred while loading the subscription usage information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscription_usage_graph_disclaimer: `Your usage data is updated on an ongoing basis. However, your final billing will be according to your actual usage.`,
  subscription_cdn_graph_disclaimer: `The Data Transfer via CDN is part of the total Data Transfer and is for informative purposes only.`,
  subscription_regions_list_fetch_failed: `An error occurred while loading the regions information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscription_provider_not_supported_by_xray: `Thank you for your interest. JFrog Xray Cloud is unavailable for this provider. Xray Cloud uses Kubernetes technology which is currently not supported by this provider. Select a different provider by submitting a request using the "Actions Menu".`,
  subscription_buy_provider_not_supported_by_xray_NEW: `Thank you for your interest in purchasing. The cloud provider, region and product combination you have chosen will be coming soon. Our cloud team will contact you to give you more details.`,
  subscription_upgrade_provider_not_supported_by_xray_NEW: `Thank you for your interest in upgrading. The cloud provider, region and product combination you have chosen will be coming soon. Our cloud team will contact you to give you more details.`,
  subscription_is_oss_message_upgrade: `Thank you for your interest. Please <a href="https://jfrog.com/contact-us/" class="blue-link" target="_blank">Contact Us</a> to upgrade your subscription`,
  jpay_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_buy_form_loading_failed: `Unfortunately we have encountered an error. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_buy_form_submit_failed: `Unfortunately we have encountered an error. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_billing_details_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_billing_details_form_submit_failed: `An error occurred while submitting the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_credit_card_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_credit_card_update_default_failed: `An error occurred while updating the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_customer_details_form_loading_failed: `An error occurred while loading the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jpay_customer_details_form_submit_failed: `An error occurred while submitting the billing information. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  enable_cdn_success: `success TBD`,
  enable_cdn_error: `TBD ------- An error occurred while enabling the CDN feature. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  root_certificate_Fetch_error: `An error occurred while fetching your public root certificate. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,

  mfa_enrollment_activation_error: `Activation failed. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  mfa_error_otp_not_match: `The code entered is invalid. Please verify the code.`,
  mfa_enrollment_activation_success: `Activated`,
  mfa_enrollment_cancellation_error: `Cancellation failed. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  mfa_enrollment_cancellation_error_already_disabled: `Multi-Factor Authentication was disabled, please refresh the page.`,
  mfa_enrollment_cancellation_success: `Cancelled`,
  subscription_activation_in_progress: `This action will be available once your subscription is activated. You will receive an email notification that the activation is complete.`,
  subscriptions_eplus_instances_configuration_success: `The configured instance(s) are being added to your topology.<br/> This process may take a while. We will notify you by email when the process is completed.`,
  subscriptions_eplus_instances_configuration_state_management_error: `Unfortunately we have encountered an error while managing the internal state. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  subscriptions_eplus_instances_configuration_unexpected_error: `Unfortunately we have encountered an error while configuring your instances. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  registration_servername_validation_error_unexpected: `Internal validation error.`,
  registration_servername_validation_error_servername_already_taken: `Server name is already in use.`,
  registration_servername_validation_error_servername_reserved: `Server name is already in use.`,
  registration_servername_validation_error_servername_invalid: `Bad server name policy.`,
  registration_servername_validation_error_servernames_not_distinct: `Server names must be unique.`,
  quick_purchase_link_send_by_mail_success: `A new link has been sent to your email`,
  billing_download_failed: `An error occurred while downloading billing Document. Please try again, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jmis_no_candidate_migration_exists: `None of your JFrog Cloud instances have been set for migration.<br/> Please contact JFrog support for more information at <a href="mailto:support@jfrog.com" class="ess-green-link">support@jfrog.com</a>.`,
  jmis_migration_install_plugin_error: `An error occurred while sending install plugin request. Please try again in a few minutes, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,
  jmis_migration_uninstall_plugin_error: `An error occurred while sending uninstall plugin request. Please try again in a few minutes, or contact <a href="mailto:service@jfrog.com" class="red-link">service@jfrog.com</a> in case the problem persists.`,

  extractPaymentsError: paymentsError => {
    const containsJson = paymentsError.includes("{") || paymentsError.includes("}");
    if (containsJson) {
      return paymentsError;
    }
    const mainError: string | string[] = paymentsError.toString().split("]");
    return mainError.length > 1 ? mainError[1] : mainError[0];
  },

  generateMailLink: (message, linkClassName = "red-link") => {
    const serviceLinkHTML = `<a href="mailto:service@jfrog.com" class="${linkClassName}">service@jfrog.com</a>`;
    message = message.replace(new RegExp("support@jfrog.com", "g"), serviceLinkHTML);

    return message.replace(new RegExp("service@jfrog.com", "g"), serviceLinkHTML);
  },
};
