import essentialsCustomValidationDictionary from "@/util/essentialsCustomValidationDictionary";
import essentialsCustomValidations from "@/util/essentialsCustomValidations";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { default as JFrogEssentials } from "jfrog-ui-vue-essentials";
import "vee-validate";

import Vue from "vue";
import VueMq from "vue-mq";
import vSelect from "vue-select";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import { myJFrogServicesPlugin } from "./plugins/myJfrogServicesPlugin";
import { myJFrogUtilsPlugin } from "./plugins/myJfrogUtilsPlugin";
import router from "./router";
import store from "./store/store";
import "./types/vueAugentations";
import navigationUtil from "./util/navigationUtil";

// Global configuration
Vue.config.productionTip = false;

// import { JFrogUI } from "jfrog-ui-essentials";
// import JfrogVueEssentials from "jfrog-ui-essentials";

//Syncing router between Main router and Vuex
sync(store, router);

//Using Official JFrog Ui lib
// JFrogUI.mockAngularJS();
// Vue.use(JfrogVueEssentials);
// Vue.use(JFrogUI, { router: router });

//Using JFrog Ui lib
Vue.use(JFrogEssentials, {
  store,
  router,
  veeValidateCustomDictonary: essentialsCustomValidationDictionary,
  customValidations: essentialsCustomValidations,
});

// Binding router to navigationUtil
navigationUtil.install(router);

// Using our Utils
Vue.use(myJFrogUtilsPlugin);

// Using our Services
Vue.use(myJFrogServicesPlugin);

// Using Vue MQ for responsivity helpers
Vue.use(VueMq, {
  breakpoints: {
    mobile: 760,
    tablet: 900,
    laptop: 1250,
    laptopXL: 1920,
    desktop: Infinity,
  },
});

// Using vSelect component
Vue.component("v-select", vSelect);

const v = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
