import { SaasPaymentType } from "@jfrog-ba/myjfrog-common";

export enum PaymentTypeBaseName {
  JCR = "JFrog Container Registry",
  CLOUD_PRO = "Cloud Pro",
  CLOUD_ENTERPRISE = "Cloud Enterprise",
  CLOUD_EPLUS = "Cloud Enterprise+",
  CLOUD_EPLUS_HYBRID = "Enterprise+ Distribution Edges",
  FREE = "Free",
  PRO_TEAM = "Pro Team",
  ENT_TEAM = "Enterprise",
}

export interface PaymentTypesMetasItem {
  paymentTypeName: string;
  baseName: PaymentTypeBaseName;
  isFree: boolean;
  isProTeam: boolean;
  isEnterpriseTeam: boolean;
  isMonthlyPayment: boolean;
  isPrepaidPayment: boolean;
  isGCPMP: boolean;
  isAWSMP: boolean;
  isAzureMP: boolean;
  isMP: boolean;
  isInternal: boolean;
  isOSS: boolean;
  isTrial: boolean;
  isEnterprise: boolean;
  isEnterpriseX: boolean;
  isEnterprisePlus: boolean;
  isCloudPro: boolean;
  isJCR: boolean;
  isCloudProX: boolean;
  hasXray: boolean;
  hasXrayPremium: boolean;
  hasPrivateLink: boolean;
}

export const paymentTypesMetas: { [key in SaasPaymentType]: PaymentTypesMetasItem } = {
  FREE: {
    paymentTypeName: "FREE",
    baseName: PaymentTypeBaseName.FREE,
    isFree: true,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  ENTERPRISE_PLUS_HYBRID: {
    paymentTypeName: "E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS_HYBRID,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: true,
  },
  PRO_TEAM_MONTHLY: {
    paymentTypeName: "PRO_TEAM_MONTHLY",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  PRO_TEAM_PREPAID: {
    paymentTypeName: "PRO_TEAM_PREPAID",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  ENTERPRISE_MONTHLY: {
    paymentTypeName: "ENTERPRISE_MONTHLY",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  ENTERPRISE_PREPAID: {
    paymentTypeName: "ENTERPRISE_PREPAID",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  ENTERPRISE_X_MONTHLY: {
    paymentTypeName: "ENTERPRISE_X_MONTHLY",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  ENTERPRISE_X_PREPAID: {
    paymentTypeName: "ENTERPRISE_X_PREPAID",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  MONTHLY: {
    paymentTypeName: "MONTHLY",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  JCR: {
    paymentTypeName: "JCR",
    baseName: PaymentTypeBaseName.JCR,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: true,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  MONTHLY_PROX: {
    paymentTypeName: "MONTHLY_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  PRE_PAID: {
    paymentTypeName: "PRE_PAID",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  PRE_PAID_PROX: {
    paymentTypeName: "PRE_PAID_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  DEDICATED: {
    paymentTypeName: "DEDICATED",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: true,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  DEDICATED_X: {
    paymentTypeName: "DEDICATED_X",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: true,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  INTERNAL: {
    paymentTypeName: "INTERNAL",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  INTERNAL_PROX: {
    paymentTypeName: "INTERNAL_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: true,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  OSS_PROJECT: {
    paymentTypeName: "OSS",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: true,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  TRIAL_OSS_PROJECT: {
    paymentTypeName: "TRIAL_OSS",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: true,
    isPrepaidPayment: false,
    isTrial: true,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  TRIAL: {
    paymentTypeName: "TRIAL",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: true,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AWS: {
    paymentTypeName: "AWS",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: true,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AWS_PRO_ENT: {
    paymentTypeName: "AWS_PRO_ENT",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: true,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AWS_PROX: {
    paymentTypeName: "AWS_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AWS_PROX_ENTX: {
    paymentTypeName: "AWS_PROX_ENTX",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AWS_PRO_TEAM: {
    paymentTypeName: "AWS_PRO_TEAM",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  TRIAL_AWS_PRO_TEAM: {
    paymentTypeName: "TRIAL_AWS_PRO_TEAM",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false, // Intended to be "false" - please don't change
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AWS_JCR: {
    paymentTypeName: "AWS_JCR",
    baseName: PaymentTypeBaseName.JCR,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: true,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  GCP_PROX: {
    paymentTypeName: "GCP_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isJCR: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  GCP_PROX_ENTX: {
    paymentTypeName: "GCP_PROX_ENTX",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  GCP_PRO_TEAM: {
    paymentTypeName: "GCP_PRO_TEAM",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  TRIAL_GCP_PRO_TEAM: {
    paymentTypeName: "TRIAL_GCP_PRO_TEAM",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false, // Intended to be "false" - please don't change
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  GCP_ENTERPRISE: {
    paymentTypeName: "GCP_ENTERPRISE",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: true,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  GCP_ENTERPRISE_X: {
    paymentTypeName: "GCP_ENTERPRISE_X",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  GCP_ENTERPRISE_PLUS: {
    paymentTypeName: "GCP_E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: true,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: false,
    isPrepaidPayment: true,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AZURE_PROX: {
    paymentTypeName: "AZURE_PROX",
    baseName: PaymentTypeBaseName.CLOUD_PRO,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: true,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AZURE_PROX_ENTX: {
    paymentTypeName: "AZURE_PROX_ENTX",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AZURE_PRO_TEAM: {
    paymentTypeName: "AZURE_PRO_TEAM",
    baseName: PaymentTypeBaseName.PRO_TEAM,
    isFree: false,
    isProTeam: true,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isOSS: false,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AZURE_ENTERPRISE: {
    paymentTypeName: "AZURE_ENTERPRISE",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
  AZURE_ENTERPRISE_X: {
    paymentTypeName: "AZURE_ENTERPRISE_X",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isMonthlyPayment: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isPrepaidPayment: true,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AZURE_ENTERPRISE_PLUS: {
    paymentTypeName: "AZURE_E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: true,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: false,
    isPrepaidPayment: true,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  ENTERPRISE_PLUS: {
    paymentTypeName: "E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: false,
    isPrepaidPayment: true,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  TRIAL_ENTERPRISE_PLUS: {
    paymentTypeName: "TRIAL_E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: false,
    isPrepaidPayment: true,
    isOSS: false,
    isTrial: true,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  TRIAL_ENTERPRISE: {
    paymentTypeName: "TRIAL_ENTERPRISE",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: false,
    isGCPMP: false,
    isAzureMP: false,
    isMP: false,
    isInternal: false,
    isEnterprise: true,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: true,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: false,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  TRIAL_AWS_ENTERPRISE_X: {
    paymentTypeName: "TRIAL_AWS_ENT_X",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: false,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: true,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AWS_ENTERPRISE_PLUS: {
    paymentTypeName: "AWS_E+",
    baseName: PaymentTypeBaseName.CLOUD_EPLUS,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: true,
    isMonthlyPayment: false,
    isPrepaidPayment: true,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AWS_ENTERPRISE_X: {
    paymentTypeName: "AWS_ENT_X",
    baseName: PaymentTypeBaseName.CLOUD_ENTERPRISE,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: false,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: true,
    isEnterprisePlus: false,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isOSS: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: true,
    hasPrivateLink: true,
  },
  AWS_ENTERPRISE: {
    paymentTypeName: "AWS_ENTERPRISE",
    baseName: PaymentTypeBaseName.ENT_TEAM,
    isFree: false,
    isProTeam: false,
    isEnterpriseTeam: true,
    isAWSMP: true,
    isGCPMP: false,
    isAzureMP: false,
    isMP: true,
    isInternal: false,
    isEnterprise: false,
    isEnterpriseX: false,
    isEnterprisePlus: false,
    isOSS: false,
    isMonthlyPayment: true,
    isPrepaidPayment: false,
    isTrial: false,
    isJCR: false,
    isCloudPro: false,
    isCloudProX: false,
    hasXray: true,
    hasXrayPremium: false,
    hasPrivateLink: false,
  },
};
