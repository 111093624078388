
import { Component, Vue } from "vue-property-decorator";
import { JFGrid } from "jfrog-ui-vue-essentials";
import LoadingMask from "@/components/common/LoadingMask.vue";

@Component({ name: "ComparisonTableNew", components: { LoadingMask, JFGrid } })
export default class ComparisonTableNew extends Vue {
  isExpanded = false;

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
