
import { Component, Prop, Vue } from "vue-property-decorator";
import InlineSvg from "vue-inline-svg";

@Component({
  name: "SvgInline",
  components: { InlineSvg },
})
export default class SvgInline extends Vue {
  @Prop({ default: "" }) private src!: string;

  imageSrc() {
    return this.$jfImages.get(this.src);
  }
}
