import { AnyObject } from "@/types/localtypes";

const essentialsCustomValidationDictionary: AnyObject = {
  en: {
    messages: {
      required: (name: string) => {
        return getCustomMessage(name, "_customRequired") || `The ${name} field is required.`;
      },
      is: (name: string) => {
        return getCustomMessage(name, "_customIs") || `The ${name} value is not valid.`;
      },
      min: (name: string, attributes: any[]) => {
        return getCustomMessage(name, "_customMin") || `The ${name} field must be at least ${attributes[0]} characters`;
      },
      max: (name: string, attributes: any[]) => {
        return (
          getCustomMessage(name, "_customMax") ||
          `The ${name} field may not be greater than ${attributes[0]} characters`
        );
      },
    },
  },
};

const getCustomMessage = (dataVvAs: string, customRuleName: string) => {
  const rulesParts = dataVvAs.split("|");

  for (let i = 0; i < rulesParts.length; i++) {
    const rulePart = rulesParts[i];
    const prefix = rulePart.split("-")[0];
    if (prefix === "_custom" || prefix === customRuleName) {
      return rulePart.substr(rulePart.indexOf("-") + 1);
    }
  }
  rulesParts.forEach(rulePart => {});

  return null;
};

export default essentialsCustomValidationDictionary;
