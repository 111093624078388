import { MyJFrogUtils } from "@/types/MyJFrogUtils";

const myJFrogUtils: MyJFrogUtils = {
  generateUniqInputName(name: string) {
    return `${name}-` + new Date().getTime();
  },
  formatPrice(number: number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },
  formatBoolean: (b: boolean) => (b ? "Yes" : "No"),

  formatBytes: (bytes: number, decimals: number = 2): { num: number; size: string; str: string } => {
    if (bytes === 0 || !isFinite(bytes)) return { num: 0, size: "Bytes", str: "0 Bytes" };

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const num = (bytes / Math.pow(k, i)).toFixed(dm);

    return { num: +num, size: sizes[i], str: num + " " + sizes[i] };
  },
  byteToGigaByte: (n: number): number => {
    const gigaBytes = +(n / Math.pow(1024, 3)).toFixed(2);
    return isFinite(gigaBytes) ? gigaBytes : 0;
  },
  capitalize: (str: string): string => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  round: (n: number, nbDecimals: number = 0): string => {
    // @ts-ignore
    return parseFloat(+(Math.round(n + `e+${nbDecimals}`) + `e-${nbDecimals}`)).toFixed(nbDecimals);
  },
  toKebabCase: (s, convertPlusToLitteral) => {
    s = convertPlusToLitteral ? s.replace(/\+/g, "Plus") : s;
    // @ts-ignore
    return s
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join("-");
  },
  trimAll: s => s.replace(/\s/g, ""),
};

export default myJFrogUtils;
