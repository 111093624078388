import apiProxy from "../util/api-proxy";
import { SubsNotifications } from "@/types/services/subsNotifications";
import { JpuNotification } from "@jfrog-ba/myjfrog-common";

export const subsNotificationsService: SubsNotifications = {
  getNotifications: async (accountNumber: string): Promise<JpuNotification | null> =>
    await apiProxy.get(`/notifications/${accountNumber}`),
  setNotifications: async (accountNumber: string, data: JpuNotification) =>
    await apiProxy.post(`/notifications/${accountNumber}`, data),
  updateNotifications: async (accountNumber: string, data: JpuNotification) =>
    await apiProxy.put(`/notifications/${accountNumber}`, data),
};
