import store from "@/store/store";
import { LoginFlag } from "@/types/localtypes";
import VueRouter from "vue-router";

let router: VueRouter;

export default {
  install: (_router: VueRouter) => {
    router = _router;
  },

  /**
   * Logout, Redirect will save the request path and after successfull login,
   * The user will be redirected
   * @param isExpired
   */
  logout: async (isExpired?: boolean) => {
    if (isExpired) {
      const loginFlag: LoginFlag = "errorSessionExpired";
      await store.dispatch("application/setLastLoginFlag", loginFlag);
    }

    await store.dispatch("dynamicHeader/clearLoginHeaderType");
    await store.dispatch("dynamicHeader/clearRegisterNowHeaderType");
    await store.dispatch("dynamicHeader/clearRegistrationHeaderType");
    router.push({ path: "/login" }, () => {});
  },
};
