
import { JFEssentialsPopupMenuPlacement } from "@/types/3rdPartyLibs";
import { MenuItemProps, MenuTooltipState } from "@/types/services/menus";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "MenuItem",
  components: {},
})
export default class MenuItem extends Vue {
  @Getter("menuTooltipStates", { namespace: "application" })
  menuTooltipStates!: MenuTooltipState[];
  @Prop({ default: false })
  private vertical!: boolean;
  @Prop() private id!: MenuItemProps["id"];
  @Prop({ default: "My Menu" })
  private label!: MenuItemProps["label"];
  @Prop({ default: "/404" })
  private path!: MenuItemProps["path"];
  @Prop({ default: "icon-info" })
  private icon!: MenuItemProps["icon"];
  @Prop() private dataGtmEventLabel!: MenuItemProps["dataGtmEventLabel"];
  @Prop({ default: 0 })
  private deep!: MenuItemProps["deep"];
  @Prop({ default: () => [] })
  private iconClasses!: MenuItemProps["iconClasses"];
  @Prop({ default: () => [] })
  private wrapperClasses!: MenuItemProps["wrapperClasses"];
  @Prop({ default: false })
  private disabled!: MenuItemProps["disabled"];
  @Prop({ default: false })
  private isTooltip!: MenuItemProps["isTooltip"];
  @Prop() private tooltipContent!: MenuItemProps["tooltipContent"];
  @Prop() private tooltipOnShow!: () => void;

  get dataGtmEvent() {
    return `main-menu|${this.dataGtmEventLabel}`;
  }
  get getWrapperClasses(): string[] {
    let classes = ["menu-item-wrapper", this.$mq];
    if (this.vertical) {
      classes.push("vertical");
    } else {
      classes.push("horizontal", "fx", "fxacenter", "ml-2", "pl-2");
    }
    return classes.concat(this.wrapperClasses || []);
  }
  get getIconClasses(): string {
    return ["menu-icon", this.icon].concat(this.iconClasses || []).join(" ");
  }

  customRouterLinkClass(routeDeep: number) {
    const classes: string[] = [];
    const currentRouteDeep = this.$route.meta && this.$route.meta.deep ? this.$route.meta.deep : null;
    if (routeDeep && currentRouteDeep && routeDeep < currentRouteDeep) {
      classes.push("router-not-deepest-link");
    }

    return classes;
  }

  get tooltipProps() {
    if (this.isTooltip) {
      const placement: JFEssentialsPopupMenuPlacement = "bottom-end";
      return {
        html: true,
        content: this.tooltipContent,
        trigger: "manual",
        show: this.tooltipIsOpen,
        placement: placement,
        classes: ["tooltip_light"],
        autoHide: false,
        hideOnTargetClick: false,
      };
    }
    return {};
  }

  get tooltipIsOpen() {
    return this.menuTooltipStates.find(state => state.id === this.id && state.open) || false;
  }

  handleMenuClick() {
    if (this.isTooltip && this.tooltipOnShow) {
      this.tooltipOnShow();
    }
  }
}
