
import { Component, Prop, Vue } from "vue-property-decorator";
import { PricingCard } from "@/types/localtypes/quickPurchase";
import DynamicImage from "@/components/common/DynamicImage.vue";

@Component({
  name: "QuickPurchaseCard",
  components: { DynamicImage },
})
export default class QuickPurchaseCard extends Vue {
  @Prop() data!: PricingCard;
  @Prop() currentPaymentType!: string;

  @Prop() private eventBus!: Vue;
  get isEntPlus() {
    return this.data.paymentType === "ENTERPRISE_PLUS";
  }
  changeBasePlan() {
    if (!this.data.active) return;
    window.scrollTo({ top: 840, behavior: "smooth" });
    this.eventBus.$emit("changeBasePlan", this.data.paymentType);
  }
  get isTrial() {
    return (
      ["TRIAL_ENTERPRISE", "TRIAL"].includes(this.currentPaymentType) &&
      this.data.paymentType === "ENTERPRISE_X_MONTHLY"
    );
  }
}
