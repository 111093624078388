import { FlatSubscription } from "@/types/localtypes";
import { ImagesService } from "@/types/services/images";

const imagesBank = require.context("@/assets/images/", false, /\.*$/);

export const imagesService: ImagesService = {
  get: (path: string) => imagesBank(`./${path}`),

  getProviderLogoFileName: (cloudProviderCode: string) => {
    cloudProviderCode = cloudProviderCode.toLowerCase();
    if (cloudProviderCode.includes("google")) {
      return "logo_gc.png";
    }
    if (cloudProviderCode.includes("amazon")) {
      return "logo_aws_cloud.png";
    }
    if (cloudProviderCode.includes("azure")) {
      return "logo_azure.png";
    }
    return "unknown.png";
  },
};
