import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class featureNotification extends VuexModule {
  _isEPlusConfigNewServerNotificationClosedOnPurpose: boolean = false;
  _subscriptionDetailsToggleTabValue: string | null = null;

  @Mutation
  _ePlusConfigNewServerNotificationClosedOnPurpose(isClosed: boolean) {
    this._isEPlusConfigNewServerNotificationClosedOnPurpose = isClosed;
  }

  @Mutation
  _subscriptionDetailsToggleTab(tabId: string) {
    this._subscriptionDetailsToggleTabValue = tabId;
  }

  @Action({ commit: "_ePlusConfigNewServerNotificationClosedOnPurpose" })
  ePlusConfigNewServerNotificationClosedOnPurpose(isClosed: boolean) {
    return isClosed;
  }

  @Action({ commit: "_subscriptionDetailsToggleTab" })
  subscriptionDetailsToggleTab(tabId: string) {
    return tabId;
  }
  @Action({ commit: "_subscriptionDetailsToggleTab" })
  clearSubscriptionDetailsToggleTab() {
    return null;
  }

  get isEPlusConfigNewServerNotificationClosedOnPurpose() {
    return this._isEPlusConfigNewServerNotificationClosedOnPurpose;
  }

  get subscriptionDetailsToggleTabValue() {
    return this._subscriptionDetailsToggleTabValue;
  }
}
