import { CloudActivationData } from "@/types/localtypes";
import { CloudActivationsService } from "@/types/services/cloudActivation";
import apiProxy from "@/util/api-proxy";
import {
  CloudActivationCheckStatusResponse,
  CloudActivationResponse,
  CloudFreeReactivationResponse,
  CloudReactivationCheckEmailRequest,
  CloudReactivationCheckEmailResponse,
  CloudValidationResponse,
} from "@jfrog-ba/myjfrog-common";
import { emailVerification } from "@/services/routesMiddlewares";
import VueCookies from "vue-cookies";
import Vue from "vue";
import { cookiesService } from "@/services/cookies";

Vue.use(VueCookies);
export const cloudActivationsService: CloudActivationsService = {
  setCloudActivationData: (cloudActivationData: CloudActivationData) => {
    Vue.$cookies.set("cloudActivationData", cloudActivationData, cookiesService.getExpiration());
  },
  getCloudActivationData: (): CloudActivationData | null => {
    const cloudActivationData = Vue.$cookies.get("cloudActivationData");
    if (cloudActivationData) {
      return cloudActivationData;
    }
    return null;
  },
  setCloudFreeReactivationData: (cloudFreeReactivationData: CloudFreeReactivationResponse | {}) => {
    window.sessionStorage.setItem("cloudFreeReactivationData", JSON.stringify(cloudFreeReactivationData));
  },
  reactivate: async request => {
    const response: CloudFreeReactivationResponse | null = await apiProxy.post(`activation/cloud/reactivate/`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    cloudActivationsService.setCloudFreeReactivationData(response);
    return response;
  },
  getCloudFreeReactivationData: (): CloudFreeReactivationResponse | null => {
    const cloudFreeReactivationData = window.sessionStorage.getItem("cloudFreeReactivationData");
    if (cloudFreeReactivationData) {
      const parsedData: CloudFreeReactivationResponse = JSON.parse(cloudFreeReactivationData);
      if (parsedData) {
        return parsedData;
      }
    }
    return null;
  },
  getElapsedTimeForActivationCode: (activationCode: string) => {
    const cloudActivationData = cloudActivationsService.getCloudActivationData();
    if (cloudActivationData && cloudActivationData.data && cloudActivationData.data.activationCode === activationCode) {
      return cloudActivationData.data.elapsedTime;
    }
    return 0;
  },

  incrementElapsedTime: (x: number) => {
    const cloudActivationData = cloudActivationsService.getCloudActivationData();
    if (cloudActivationData && cloudActivationData.data) {
      const newElapsedTime = cloudActivationData.data.elapsedTime + x;
      cloudActivationsService.setCloudActivationData({
        ...cloudActivationData,
        data: { ...cloudActivationData.data, elapsedTime: newElapsedTime },
      });
    }
  },
  activate: async request => {
    const response: CloudActivationResponse | null = await apiProxy.post(`activation/cloud/activate/`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  getStatus: async request => {
    const response: CloudActivationCheckStatusResponse | null = await apiProxy.post(
      `activation/cloud/getStatus/`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  validate: async request => {
    const response: CloudValidationResponse | null = await apiProxy.post(`activation/cloud/validate/`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  setValidationData: (cloudValidationResponse: CloudValidationResponse) => {
    window.sessionStorage.setItem("cloudValidationResponse", JSON.stringify(cloudValidationResponse));
  },
  getValidationData: async (doValidation: boolean) => {
    let token = window.sessionStorage.getItem("cloudValidationToken");
    token = token == null ? "" : token;
    await emailVerification.getResponse(token, doValidation);
    const cloudValidationResponse = window.sessionStorage.getItem("cloudValidationResponse");
    if (cloudValidationResponse) {
      return JSON.parse(cloudValidationResponse);
    }
    return null;
  },

  getProductActivationData: async request =>
    await apiProxy.post(`activation/productActivation/validate`, { ...request }),

  checkEmailForReactivation: async (request: CloudReactivationCheckEmailRequest) => {
    const response: CloudReactivationCheckEmailResponse | null = await apiProxy.post(
      `activation/cloud/checkEmailForReactivation`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    window.sessionStorage.setItem("cloudReactivationResponse", JSON.stringify(response));
    return response;
  },
};
