import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { UserDTO } from "@jfrog-ba/myjfrog-common";

declare let MYJFROG_STATE: any;

@Module({ namespaced: true })
export default class User extends VuexModule {
  _user: UserDTO | undefined = undefined;

  @Mutation
  _setUser(user: UserDTO | undefined) {
    // required for Google Tag Manager / Google Analytics
    if (user) {
      MYJFROG_STATE.user = JSON.parse(JSON.stringify(user));
    } else {
      MYJFROG_STATE.user = null;
    }

    this._user = user;
  }
  @Mutation
  _addPaymentAccountNumber(paymentAccountNumber: string) {
    if (this._user) {
      const paymentAccountNumberExist = this._user.paymentAccountNumbers.find(
        accountNumber => accountNumber === paymentAccountNumber,
      );
      if (!paymentAccountNumberExist) {
        this._user.paymentAccountNumbers.push(paymentAccountNumber);
      }
    }
  }

  @Action({ commit: "_setUser" })
  async setUser(user: UserDTO) {
    return await user;
  }
  @Action({ commit: "_setUser" })
  clearUser() {
    return undefined;
  }
  @Action({ commit: "_addPaymentAccountNumber" })
  addPaymentAccountNumber(paymentAccountNumber: string) {
    return paymentAccountNumber;
  }

  get user() {
    return this._user;
  }
  get paymentAccountNumbers() {
    return this._user && this._user.paymentAccountNumbers;
  }
}
