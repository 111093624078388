
import { Component, Prop, Vue } from "vue-property-decorator";
import { PageHeaderItem } from "@/types/localtypes/quickPurchase";

@Component({
  name: "PageHeader",
  components: {},
})
export default class PageHeader extends Vue {
  @Prop() data!: PageHeaderItem;
}
