import { DateUtils } from "@/types/DateUtils";
import moment from "moment";

const baseFormat: string = "DD MMM YY HH:mm";
const dateUtils: DateUtils = {
  parse: (dateStr: string, format: string) => moment(dateStr, format),
  format: (date: moment.MomentInput, format: string) => {
    //Transform the timestamp to a local date
    const dateLocal = moment(date).toDate();
    dateLocal.setMinutes(dateLocal.getTimezoneOffset() + dateLocal.getMinutes());

    //Format it
    return moment(dateLocal).format(format || baseFormat);
  },
  endOf: (date: moment.MomentInput, unitOfTime: moment.unitOfTime.StartOf) => moment(date).endOf(unitOfTime),
  getUtcValue: (date: moment.MomentInput) => {
    const dateObject = moment(date).toDate();
    return Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());
  },
  formatUTC: (date: moment.MomentInput) =>
    moment(date)
      .utc()
      .format(baseFormat),
  dayDiff: (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
    moment(endDate).diff(moment(startDate), "days"),
  fromNow: (date: moment.MomentInput) => moment(date).fromNow(),
  isBefore: (date: moment.MomentInput, compareTo: moment.MomentInput, granularity: moment.unitOfTime.StartOf) =>
    moment(date).isBefore(compareTo, granularity),
  isAfter: (date: moment.MomentInput, compareTo: moment.MomentInput, granularity: moment.unitOfTime.StartOf) =>
    moment(date).isAfter(compareTo, granularity),
  add: (date: moment.MomentInput, amount: moment.DurationInputArg1, unit: moment.DurationInputArg2) =>
    moment(date).add(amount, unit),
  minus: (date: moment.MomentInput, amount: moment.DurationInputArg1, unit: moment.DurationInputArg2) =>
    moment(date).subtract(amount, unit),
  getFirstDayOfNextMonth: (): Date =>
    moment()
      .utc()
      .add(1, "months")
      .startOf("month")
      .startOf("day")
      .toDate(),

  toSqlFormat: (date: Date): string => {
    return (
      date.getFullYear().toString() +
      "-" +
      ("0" + (date.getMonth() + 1).toString()).slice(-2) +
      "-" +
      ("0" + date.getDate().toString()).slice(-2)
    );
  },
  addDays(date: Date, duration: number) {
    return moment(date)
      .add(duration, "day")
      .toDate();
  },
};

export default dateUtils;
