
import DynamicImage from "@/components/common/DynamicImage.vue";

declare var MAINTENANCE_MODE: any;
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Maintenance",
  components: { CloudActivationBackground, DynamicImage },
})
export default class Maintenance extends Vue {
  created() {}
}
