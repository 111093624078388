import { SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

declare let MYJFROG_STATE: any;

@Module({ namespaced: true })
export default class Subscriptions extends VuexModule {
  _subscriptions: SubscriptionDTO[] | undefined = undefined;

  @Mutation
  _setSubscriptions(subscriptions: SubscriptionDTO[] | undefined) {
    // required for Google Tag Manager / Google Analytics
    if (subscriptions) {
      MYJFROG_STATE.subscriptions = JSON.parse(JSON.stringify(subscriptions));
    } else {
      MYJFROG_STATE.subscriptions = null;
    }

    this._subscriptions = subscriptions;
  }
  @Action({ commit: "_setSubscriptions" })
  async setSubscriptions(subscriptions: SubscriptionDTO[]) {
    return await subscriptions;
  }
  @Action({ commit: "_setSubscriptions" })
  async clearSubscriptions() {
    return undefined;
  }
  @Action({ commit: "_setSubscriptions" })
  async updateSubscription(subscriptionToUpdate: SubscriptionDTO) {
    if (!this.subscriptions) {
      return this.subscriptions;
    }
    return this.subscriptions.map(subscription =>
      subscriptionToUpdate.accountNumber === subscription.accountNumber ? subscriptionToUpdate : subscription,
    );
  }

  get subscriptions() {
    return this._subscriptions;
  }
}
