import { GoalKeysFactory } from "@/types/services/webeyez";
import { upgradeGoalKeyImpl } from "@/services/webeyez/factory/upgradeGoalKeyImpl";
import { changePasswordGoalKeyImpl } from "@/services/webeyez/factory/changePasswordGoalKeyImpl";
import { mfaGoalKeyImpl } from "@/services/webeyez/factory/mfaGoalKeyImpl";
import { editBillingDetailsGoalKeyImpl } from "@/services/webeyez/factory/editBillingDetailsGoalKeyImpl";
import { editCustomerDetailsGoalKeyImpl } from "@/services/webeyez/factory/editCustomerDetailsGoalKeyImpl";
import { geoRestrictionsGoalKeyImpl } from "@/services/webeyez/factory/geoRestrictionsGoalKeyImpl";
import { awsPrivateLinkGoalKeyImpl } from "@/services/webeyez/factory/awsPrivateLinkGoalKeyImpl";
import { configAdditionalInstancesAndEdgesGoalKeyImpl } from "@/services/webeyez/factory/configAdditionalInstancesAndEdgesGoalKeyImpl";
import { downloadRootCertificateGoalKeyImpl } from "@/services/webeyez/factory/downloadRootCertificateGoalKeyImpl";
import { whitelistGoalKeyImpl } from "@/services/webeyez/factory/whitelistGoalKeyImpl";
import { downgradeGoalKeyImpl } from "@/services/webeyez/factory/downgradeGoalKeyImpl";
import { changeRegionGoalKeyImpl } from "@/services/webeyez/factory/changeRegionGoalKeyImpl";
import { changeCloudProviderGoalKeyImpl } from "@/services/webeyez/factory/changeCloudProviderGoalKeyImpl";
import { usageNotificationsGoalKeyImpl } from "@/services/webeyez/factory/usageNotificationsGoalKeyImpl";
import { downloadBillingHistoryGoalKeyImpl } from "@/services/webeyez/factory/downloadBillingHistoryGoalKeyImpl";
import { updateCCDetailsGoalKeyImpl } from "@/services/webeyez/factory/updateCCDetailsGoalKeyImpl";
import { cancelSubscriptionGoalKeyImpl } from "@/services/webeyez/factory/cancelSubscriptionGoalKeyImpl";
import { installPluginGoalKeyImpl } from "@/services/webeyez/factory/installPluginGoalKeyImpl";
import { uninstallPluginGoalKeyImpl } from "@/services/webeyez/factory/uninstallPluginGoalKeyImpl";

export const goalKeysFactory: GoalKeysFactory = {
  getInstance(goalKeyName) {
    switch (goalKeyName) {
      case "upgrade":
        return upgradeGoalKeyImpl;

      case "change_password":
        return changePasswordGoalKeyImpl;

      case "edit_billing_details":
        return editBillingDetailsGoalKeyImpl;

      case "mfa_cancel":
      case "mfa_enrollment":
        return mfaGoalKeyImpl;

      case "edit_customer_details":
        return editCustomerDetailsGoalKeyImpl;

      case "geo_restrictions":
        return geoRestrictionsGoalKeyImpl;

      case "aws_private_link":
        return awsPrivateLinkGoalKeyImpl;

      case "configure_additional_instances_and_edge":
        return configAdditionalInstancesAndEdgesGoalKeyImpl;

      case "download_public_root_certificate":
        return downloadRootCertificateGoalKeyImpl;

      case "whitelist_ips":
        return whitelistGoalKeyImpl;

      case "downgrade":
        return downgradeGoalKeyImpl;

      case "change_region":
        return changeRegionGoalKeyImpl;

      case "change_provider":
        return changeCloudProviderGoalKeyImpl;

      case "usage_notification":
        return usageNotificationsGoalKeyImpl;

      case "download_billing_history":
        return downloadBillingHistoryGoalKeyImpl;

      case "update_credit_card_details":
        return updateCCDetailsGoalKeyImpl;

      case "cancel":
        return cancelSubscriptionGoalKeyImpl;

      case "install_plugin":
        return installPluginGoalKeyImpl;

      case "uninstall_plugin":
        return uninstallPluginGoalKeyImpl;

      default:
        return upgradeGoalKeyImpl;
    }
  },
};
