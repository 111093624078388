
import OrganizationSelect from "@/components/views/loggedOut/OrganizationSelect.vue";
import { OtpAuthenticationData } from "@/types/localtypes";
import { JFFormControl, JFTextField, JFCheckBox } from "jfrog-ui-vue-essentials";
import GoogleLogin from "@/components/common/GoogleLogin.vue";
import { isRestClientError, jumpErrorsMapping } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import { SsoLoginInfo, SsoTokenRequest } from "@jfrog-ba/myjfrog-common";
import GitHubLogin from "@/components/common/GitHubLogin.vue";
import { Route } from "vue-router";
import CustomPassword from "@/components/common/CustomPassword.vue";
import { GoogleSsoUser } from "@/types/localtypes/GoogleSsoUser";
import DynamicImage from "@/components/common/DynamicImage.vue";

declare var window: any;

@Component({
  name: "MPUnifiedLogin",
  $_veeValidate: { validator: "new" },
  components: {
    DynamicImage,
    JFTextField,
    JFFormControl,
    JFCheckBox,
    OrganizationSelect,
    GoogleLogin,
    GitHubLogin,
    CustomPassword,
  },
})
export default class MPUnifiedLogin extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Action("showAppLoadingMask", { namespace: "application" })
  showAppLoadingMask!: () => void;
  @Action("hideAppLoadingMask", { namespace: "application" })
  hideAppLoadingMask!: () => void;
  @Action("setOtpAuthenticationData", { namespace: "authentication" })
  setOtpAuthenticationData!: (otpAuthenticationData: OtpAuthenticationData) => Promise<void>;
  ssoLoginSuccess = false;
  showEmail = true;
  isSignUpView = true;
  isValidServerName = true;
  data = {
    email: "",
    regionCode: "",
    regionName: "",
    country: "",
    password: "",
    serverName: "",
    subscriptionType: "",
    mpToken: "",
    mpOrigin: "",
    cloudProvider: "",
    termsAgree: false,
    firstName: "",
    lastName: "",
    userIdentifier: "",
    ssoType: "",
    ssoId: "",
  };

  created() {
    this.loadFormDataFromSession();
    this.isSignUpView = !(sessionStorage.getItem("isLoginByServerName") === "true");
    this.globalBus.$on("sendJfrogTokenResult", this.markServerName);
  }

  mounted() {
    this.fillQueryData();
    this.handleOnDataChange();
  }

  setSignUpView(value: boolean) {
    this.isSignUpView = value;
    this.$emit("loginByServerName", !value);
  }

  fillQueryData() {
    let mpToken = this.$route.query.token as string;
    if (!mpToken) {
      mpToken = sessionStorage.getItem("mpToken") as string;
    }
    if (!mpToken) return;

    mpToken = mpToken.replaceAll(" ", "+");
    this.data.mpToken = mpToken;
    this.data.mpOrigin = this.$route.query.mpo as string;
    sessionStorage.setItem("mpOrigin", this.data.mpOrigin);
    sessionStorage.setItem("mpToken", this.data.mpToken);
    const landingTime = sessionStorage.getItem("landingTime");
    if (!landingTime) {
      sessionStorage.setItem("landingTime", new Date().getTime().toString());
    }
    this.verifyToken();
  }

  private verifyToken() {
    let popupId = 0;
    if (this.data.mpOrigin === "AWS_MP") {
      popupId = this.$mjfNotification.popup({
        title: "Waiting on AWS confirmation",
        text: `<p>Confirmation may take up to five minutes.<br/>Please wait while we process your request</p>`,
        hideButton: true,
        icon: "rocket-loader.gif",
        iconHeight: 132,
      });
    }
    this.$emit("mpUnifiedVerifyToken", popupId);
  }

  @Watch("$route")
  onRouteChange(to: Route, from: Route) {
    setTimeout(() => {
      this.handleOnDataChange();
    }, 200);
  }
  get agreePolicy() {
    return `I have read and agree to the
<a class="bold" href="${this.$constants.GENERAL_TERMS_URL}" target="_blank">General Terms</a> of Service and the <a class="bold" href="${this.$constants.PRIVACY_POLICY_URL}" target="_blank">Privacy Policy</a>`;
  }

  loadFormDataFromSession() {
    const data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }
    this.data = JSON.parse(data);
  }

  onPasswordChange(value: string) {
    this.data.password = value;
    this.$emit("passwordChange", value);
    this.handleOnDataChange();
  }

  get serverNameCss() {
    let css = "";
    if (!this.isValidServerName) {
      css = "error invalid";
    }
    return css;
  }
  markServerName(errorResult: number) {
    this.isValidServerName = errorResult !== jumpErrorsMapping.sendJfrogToken.invalidServerName;
    this.$validator.validate();
  }
  handleOnDataChange() {
    if (!this.data.termsAgree) {
      this.$emit("credentialsFilled", false);
    } else if (!this.errors.any() && (this.data?.password || this.data?.ssoId) && this.isValidEmail()) {
      this.$emit("credentialsFilled", true);
    } else if (this.ssoLoginSuccess && this.isValidEmail()) {
      this.$emit("credentialsFilled", true);
    } else if (!this.isSignUpView && this.data?.serverName) {
      this.$emit("credentialsFilled", true);
    } else {
      this.$emit("credentialsFilled", false);
    }
    const temp = { ...this.data };
    temp.password = "";
    sessionStorage.setItem("registrationFormData", JSON.stringify(temp));
    this.$emit("allFormsData", temp);
  }

  isValidEmail() {
    const email = this.data?.email;
    if (!email) return false;
    return !(email.includes("+") && !email.toLocaleLowerCase().includes("@jfrog."));
  }

  tooltip = {
    email: `This is the email address will used as your platform username`,
    password: `<ul>
<li>Password should be 8-32 characters long</li>
      <li>Password should contain at least one uppercase and one lowercase character</li>
      <li>Password should contain at least one number</li>
      <li>Password should contain at least one special character</li>
</ul>`,
  };

  async fetchTokenData(ssoLoginRequest: SsoTokenRequest) {
    try {
      this.showEmail = false;

      if (!window.google && !(await this.$validator.validateAll())) {
        this.$log.warn("fetchTokenData not valid");
        return;
      }
      this.showAppLoadingMask();
      const loginResponse: SsoLoginInfo | null = await this.$jfUsers.fetchOAuthToken(ssoLoginRequest);
      this.$log.info("loginResponse", JSON.stringify(loginResponse));
      this.hideAppLoadingMask();
      if (!loginResponse) {
        return;
      }

      this.data.email = loginResponse.email;
      this.data.firstName = loginResponse.firstName;
      this.data.lastName = loginResponse.lastName;
      this.data.userIdentifier = loginResponse.email;
      this.data.ssoType = ssoLoginRequest.ssoOrigin;
      this.data.ssoId = loginResponse.email;
      this.ssoLoginSuccess = true;
      this.showEmail = true;
      this.handleOnDataChange();
      location.reload();
    } catch (e) {
      this.showEmail = true;
      if (window.gapi && window.gapi.auth2) {
        window.gapi.auth2.getAuthInstance().disconnect();
      }

      this.$log.error("fetchTokenData error", e);
      this.hideAppLoadingMask();
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      if (isRestClientError(e) && e.httpBody) {
        if (e.httpStatus === 429) {
          errorMessage = this.$jfMessages.too_many_requests;
        }
      }

      this.$jfNotification.error({ text: errorMessage || e });
    }
  }

  onGoogleSignIn(googleUser: GoogleSsoUser) {
    this.errors.clear();
    const ssoLoginRequest: SsoTokenRequest = {
      ssoToken: googleUser.credential,
      ssoOrigin: "google",
    };
    this.fetchTokenData(ssoLoginRequest);
  }

  onGoogleFailure(error: string) {
    this.$log.error(error);
  }

  onSsoSignIn(loginResponse: SsoLoginInfo) {
    if (loginResponse.email) {
      this.data.email = loginResponse.email;
    }
    this.data.firstName = loginResponse.firstName;
    this.data.lastName = loginResponse.lastName;
    this.data.userIdentifier = loginResponse.userIdentifier;
    this.data.ssoType = loginResponse.ssoOrigin;
    this.data.ssoId = loginResponse.userIdentifier;
    this.ssoLoginSuccess = true;
    this.handleOnDataChange();
    setTimeout(() => {
      this.$router.push(this.$route.fullPath + "#logged");
    }, 150);
  }

  onSsoFailure() {
    this.ssoLoginSuccess = false;
  }
}
