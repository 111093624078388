import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Counter extends VuexModule {
  cpt: number = 0;

  @Mutation
  m_add(delta: number) {
    this.cpt += delta;
  }
  @Mutation
  m_sub(delta: number) {
    this.cpt -= delta;
  }

  @Action({ commit: "m_add" })
  increment(): number {
    return 1;
  }
  @Action({ commit: "m_sub" })
  decrement(): number {
    return 1;
  }
  @Action({ commit: "m_add" })
  add(x: number): number {
    return x;
  }

  get stateCounter(): number {
    return this.cpt;
  }
}
