import router from "@/router";
import { usersService } from "@/services/user";
import axios, { AxiosError } from "axios";
import { restClientCreator } from "@jfrog-ba/myjfrog-common";

declare const API_PROXY_URL_V2: string;
import VueCookies from "vue-cookies";
import Vue from "vue";

Vue.use(VueCookies);
const axiosInstance = axios.create({ baseURL: API_PROXY_URL_V2 });

axiosInstance.interceptors.request.use(config => {
  const sessionData = Vue.$cookies.get("sessionData");

  if (sessionData) {
    config.headers.set("X-Session-ID", sessionData.sessionId);
    if (usersService.hasUser() && !usersService.isUserSynced() && config.url !== usersService.logOutUrl) {
      router.push({ path: "/refresh" });
      throw new Error("Please Refresh Your Browser.");
    }
  }

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    // If we receive a 401 with an existing sessionId that means that we have a token expired
    if (error && error.response && error.response.status === 401 && usersService.getSessionId()) {
      usersService.handleUnauthorized();
    }
    return Promise.reject(error);
  },
);

export default restClientCreator(axiosInstance);
