
import { Component, Prop, Vue } from "vue-property-decorator";
import { Feature, FeatureName, FeatureSelection, QuickPurchasePaymentType } from "@/types/localtypes/quickPurchase";

@Component({
  name: "QuickPurchaseFeature",
  components: {},
})
export default class QuickPurchaseFeature extends Vue {
  @Prop() data!: Feature;
  @Prop() currentPaymentType!: string;
  @Prop() private eventBus!: Vue;
  buttonText = "";
  included = false;
  extra = false;
  isShowButton = false;
  created() {
    this.buttonText = this.extra ? "Remove" : "Add";
    this.eventBus.$on("featureChange", this.onFeaturesChange);
  }

  get isTrial() {
    return (
      ["TRIAL_ENTERPRISE", "TRIAL"].includes(this.currentPaymentType) &&
      ["Artifactory", "ArtifactoryEnt", "Xray", "JAS"].includes(this.data.name)
    );
  }
  onFeaturesChange(selection: FeatureSelection) {
    this.isShowButton = this.data.name && selection.basePlan !== "FREE";
    this.included = selection.baseFeatures.filter(f => f === this.data.name).length > 0;
    this.extra = selection.extraFeatures.includes(this.data.name) || selection.iotFeatures.includes(this.data.name);
    this.buttonText = this.extra ? "Remove" : "Add";
  }
  handleAdd() {
    this.extra = !this.extra;
    this.buttonText = this.extra ? "Remove" : "Add";
    const eventKey = this.extra ? "featureAdded" : "featureRemoved";
    this.eventBus.$emit(eventKey, this.data.name);
  }
}
