import apiProxy from "../util/api-proxy";
import { PaymentsService } from "@/types/services/payments";

export const paymentsService: PaymentsService = {
  fetchPaymentsScripts: async (): Promise<any> => {
    const response: any = await apiProxy.get("payments/script/jf-payment-component.js");

    if (response) {
      return response;
    } else {
      throw new Error("Could not fetch payments buy form");
    }
  },
};
