
import { Component, Vue, Watch } from "vue-property-decorator";
import { JFFormControl, JFTextField, JFCheckBox, JFTextFieldAsync } from "jfrog-ui-vue-essentials";
import { JFEssentialsAsyncFieldConfig } from "@/types/3rdPartyLibs";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { MultiSelectOption } from "@/types/localtypes/multiSelect";
import { marketplaceRouteMeta } from "@/types/localtypes";
import { CloudProviderCode } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "RegistrationEnvironmentData",
  $_veeValidate: { validator: "new" },
  components: { JFFormControl, JFTextField, JFCheckBox, JFTextFieldAsync },
})
export default class RegistrationEnvironmentData extends Vue {
  data = this.loadFormDataFromSession(true);
  globalValidationDelay = 700;
  regions: MultiSelectOption[] = [];
  selectedRegionInput: MultiSelectOption = { code: "", label: "" };
  password = "";
  serverNameValid = true;
  routeParentChildMeta: marketplaceRouteMeta = {};
  selectedCloudProvider: CloudProviderCode = "AWS";

  async created() {
    this.getRouteParentChildMeta();
    await this.fetchRegionsList(true);
  }

  getRouteParentChildMeta() {
    this.$route.matched.forEach(m => {
      for (let key in m.meta) {
        this.routeParentChildMeta[key] = m.meta[key];
      }
    });
  }

  iconSrc(icon: string): string | null {
    try {
      return this.$jfImages.get(icon);
    } catch (e) {
      this.$log.error("Failed to retrieve icon : " + icon);
    }

    return null;
  }

  selectProvider(cloudProvider: CloudProviderCode) {
    this.selectedCloudProvider = cloudProvider;
    this.fetchRegionsList(false);
  }

  getProviderCss(cloudProvider: string) {
    let css = ["provider"];
    if (this.selectedCloudProvider === cloudProvider) {
      css.push("active");
    }
    return css.join(" ");
  }

  loadFormDataFromSession(setRegion: boolean) {
    const data = sessionStorage.getItem("registrationFormData") as string;
    if (!data) {
      return {};
    }
    const obj = JSON.parse(data);
    if (setRegion) {
      if (obj?.cloudProvider) {
        this.selectedCloudProvider = obj?.cloudProvider;
      }
      this.selectedRegionInput = { code: obj?.regionCode, label: obj?.regionName };
    } else {
      this.selectedRegionInput = { code: "", label: "" };
    }
    this.sendFormData();

    return obj;
  }

  get tooltipText() {
    return "This will be the name of your main instance - you’ll be able to configure your Distribution Edges later on using MyJFrog. You’ll receive an email after registration with more details.";
  }

  async onBlur(e: any) {
    await this.$validator.validate(e.target.name);
    this.notifyCanSubmit();
  }

  async validateServerName() {
    const res = await this.$validator.verify(
      this.data.serverName,
      "required|min:3|max:32|verify_servername_store|uniqueServerName:1," + this.data.email,
    );
    this.serverNameValid = res.valid;
  }

  get serverNameCss() {
    const classes = ["text-field"];
    if (!this.serverNameValid) {
      classes.push("not-valid");
    }
    return classes.join(" ");
  }

  @Watch("selectedRegionInput")
  handleRegionChange(newRegionInput: MultiSelectOption) {
    setTimeout(() => {
      this.data.regionCode = newRegionInput ? newRegionInput.code : "";
      this.data.regionName = newRegionInput ? newRegionInput.label : "";
    }, 300);
  }

  @Watch("data", { deep: true })
  handleDataChange() {
    setTimeout(() => {
      this.sendFormData();
    }, 300);
  }

  async fetchRegionsList(setRegion: boolean) {
    try {
      this.loadFormDataFromSession(setRegion);
      const response = await this.$jfRegistrations.getRegionsList(this.selectedCloudProvider);
      this.regions = response.regions.map(region => ({ label: region.name, code: region.code }));
    } catch (error) {
      this.$log.error(error);
      this.$jfNotification.error({ text: this.$jfMessages.subscription_regions_list_fetch_failed });
    }
  }

  get agreePolicy() {
    return `I have read and agree to the
<a class="bold" href="${this.$constants.GENERAL_TERMS_URL}" target="_blank">General Terms of Service</a> and the <a class="bold" href="${this.$constants.PRIVACY_POLICY_URL}" target="_blank">Privacy Policy</a>`;
  }

  sendFormData() {
    if (!this.data) {
      return;
    }
    this.data.password = "";
    sessionStorage.setItem("registrationFormData", JSON.stringify(this.data));
    this.data.password = this.password;
    this.data.cloudProvider = this.selectedCloudProvider;
    this.$emit("allFormsData", this.data);
    setTimeout(() => {
      this.notifyCanSubmit();
    }, 100);
  }

  notifyCanSubmit() {
    if (
      !this.errors.any() &&
      this.data.termsAgree &&
      this.data.regionCode &&
      this.data.serverName &&
      this.serverNameValid
    ) {
      this.$emit("canSubmitForm", true);
    } else {
      this.$emit("canSubmitForm", false);
    }
  }

  get asyncConfig(): JFEssentialsAsyncFieldConfig {
    return {
      showAsyncIndications: true,
      icons: {
        success: {
          name: "icon-mjf-curved-check",
          classes: ["mjf-green"],
        },
        error: {
          name: "icon-mjf-canceled",
          classes: ["mjf-red"],
        },
      },
      loader: {
        component: FadeSpinner,
        wrapper: {
          rightOffset: 5,
          bottomOffset: 27,
        },
        props: {
          color: "#1cbe4f",
          dotHeight: "3",
          dotWidth: "3",
          borderRadius: "100%",
          radius: 10,
          containerSize: "30",
        },
      },
    };
  }
}
