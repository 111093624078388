/**
 * This file shows the 'TypeScript way' to create a plugin for Vue.
 */
import { appService } from "@/services/app";
import { billingAccountsService } from "@/services/billingAccounts";
import { cloudActivationsService } from "@/services/cloudActivation";
import { featureNotificationService } from "@/services/featureNotifications";
import { floatingNotificationsService } from "@/services/floatingNotifications";
import { imagesService } from "@/services/images";
import { loadersService } from "@/services/loaders";
import { marketoService } from "@/services/marketo";
import { menusService } from "@/services/menus";
import { messagesService } from "@/services/messages";
import { paymentsService } from "@/services/payments";
import { pricingService } from "@/services/pricing";
import { registrationsService } from "@/services/registrations";
import { sessionsService } from "@/services/sessions";
import { subscriptionsService } from "@/services/subscriptions";
import { subsNotificationsService } from "@/services/subsNotifications";
import { usersService } from "@/services/user";
import { videosService } from "@/services/videos";
import { jmisService } from "@/services/jmis";
import { webeyezService } from "@/services/webeyez/webeyez";
import { constants } from "@/services/constants";
import logger from "@/util/logger";

import _Vue from "vue";
import { mjfNotificationService } from "@/services/mjfNotificationService";

export function myJFrogServicesPlugin<MyJFrogServicesPluginOptions>(
  Vue: typeof _Vue,
  options?: MyJFrogServicesPluginOptions,
): void {
  // do stuff with options

  // The code above make a 'Vue augmentation' and inject our services inside all our components (accessible via this.X)
  // but TypeScript will NOT reconize it without a extra type definitions.
  // see the augmentation types declaration in src/types/vueAugmentation.ts
  Vue.prototype.$log = logger;
  Vue.prototype.$jfApplication = appService;
  Vue.prototype.$jfSessions = sessionsService;
  Vue.prototype.$jfUsers = usersService;
  Vue.prototype.$jfNotificationsService = subsNotificationsService;
  Vue.prototype.$jfPricing = pricingService;
  Vue.prototype.$jfImages = imagesService;
  Vue.prototype.$jfVideos = videosService;
  Vue.prototype.$jfSubscriptions = subscriptionsService;
  Vue.prototype.$jfRegistrations = registrationsService;
  Vue.prototype.$jfBillingAccounts = billingAccountsService;
  Vue.prototype.$jfPayments = paymentsService;
  Vue.prototype.$jfMenus = menusService;
  Vue.prototype.$jfLoaders = loadersService;
  Vue.prototype.$jfFeatureNotifications = featureNotificationService;
  Vue.prototype.$jfMessages = messagesService;
  Vue.prototype.$jfMarketo = marketoService;
  Vue.prototype.$jfCloudActivations = cloudActivationsService;
  Vue.prototype.$jfFloatingNotifications = floatingNotificationsService;
  Vue.prototype.$jfJmisService = jmisService;
  Vue.prototype.$jfWebeyez = webeyezService;
  Vue.prototype.$constants = constants;
  Vue.prototype.$mjfNotification = mjfNotificationService;
}

export class MyJFrogServicesPluginOptions {
  // add stuff
}
