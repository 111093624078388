import { messagesService } from "@/services/messages";
import { registrationsService } from "@/services/registrations";
import { JFEssentialsCustomValidationMap } from "@/types/3rdPartyLibs";
import { isRestClientError, storeErrorsMapping, InstanceIndex } from "@jfrog-ba/myjfrog-common";

const essentialsCustomValidations: JFEssentialsCustomValidationMap[] = [
  {
    name: "uniqueServerName",
    validator: {
      getMessage: (field, params, data) => {
        return data.errorMessage;
      },
      validate: async (value, params) => {
        try {
          let suffix: InstanceIndex = "1";
          let email = "";
          if (params?.length) {
            const args = params.toString().split(",");
            suffix = args[0];
            if (args.length > 1) {
              email = args[1];
            }
          }
          await registrationsService.checkServerName({ serverName: value, suffix, email });
          return {
            valid: true,
          };
        } catch (e) {
          let errorMessage = messagesService.registration_servername_validation_error_unexpected;
          if (isRestClientError(e) && e.httpBody) {
            if (e.httpBody.result === storeErrorsMapping.registrations.checkServerName.serverNameAlreadyTaken) {
              errorMessage = messagesService.registration_servername_validation_error_servername_already_taken;
            }
            if (e.httpBody.result === storeErrorsMapping.registrations.checkServerName.isReserved) {
              errorMessage = messagesService.registration_servername_validation_error_servername_reserved;
            }
            if (e.httpBody.result === storeErrorsMapping.registrations.checkServerName.badPolicy) {
              errorMessage = messagesService.registration_servername_validation_error_servername_invalid;
            }
            if (e.httpStatus === 429) {
              errorMessage = "Too Many Requests";
            }
          }
          return {
            valid: false,
            data: {
              errorMessage,
            },
          };
        }
      },
    },
  },
];

export default essentialsCustomValidations;
