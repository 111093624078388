import { JmisService } from "@/types/services/jmis";
import apiProxy from "@/util/api-proxy";
import {
  GenerateTokenProxyRequest,
  GenerateTokenResponse,
  GetMigrationCandidatesResponse,
  MigrationPluginRequest,
  MigrationPluginResponse,
} from "@jfrog-ba/myjfrog-common";

export const jmisService: JmisService = {
  generateToken: async (request: GenerateTokenProxyRequest) => {
    const response: GenerateTokenResponse | null = await apiProxy.post(`jmis/generateToken`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getMigrationCandidates: async request => {
    const response: GetMigrationCandidatesResponse | null = await apiProxy.post(`jmis/getMigrationCandidates`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  installPlugin: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(`jmis/installPlugin`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  uninstallPlugin: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(`jmis/uninstallPlugin`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getPluginStatus: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(`jmis/getPluginStatus`, request);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
};
