
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SpinnerSizes } from "@/types/spinners";

@Component({
  name: "DefaultSpinner",
  components: {},
})
export default class DefaultSpinner extends Vue {
  @Prop({ default: true })
  private show!: boolean;
  @Prop({ default: true })
  private clockwise!: boolean;
  @Prop({ default: "l" })
  private size!: keyof SpinnerSizes;
  @Prop({ default: 3 })
  private depth!: number;
  @Prop({ default: 1.0 })
  private speed!: number;
  @Prop({ default: "#6589b6" })
  private color!: string;

  clockwiseAnimations = ["rotateСlockwise", "rotateCounterСlockwise"];
  sizeUnits = "px";
  timeUnits = "s";
  spinnerSizes: SpinnerSizes = {
    xxxs: {
      size: 10,
      depth: 1,
    },
    xxs: {
      size: 15,
      depth: 2,
    },
    xs: {
      size: 30,
      depth: 3,
    },
    s: {
      size: 50,
      depth: 4,
    },
    m: {
      size: 65,
      depth: 5,
    },
    l: {
      size: 80,
      depth: 6,
    },
    xl: {
      size: 95,
      depth: 7,
    },
    huge: {
      size: 125,
      depth: 7,
    },
  };

  get spinnerClockwise(): string {
    return this.clockwise ? this.clockwiseAnimations[0] : this.clockwiseAnimations[1];
  }

  get spinnerSize(): string {
    return this.spinnerSizes[this.size].size + this.sizeUnits;
  }

  get spinnerDepth(): string {
    //Currently, Neglecting depth config by it's own, I wish it will be consistent by size.
    return this.spinnerSizes[this.size].depth + this.sizeUnits;
  }

  get spinnerSpeed(): string {
    return this.speed + this.timeUnits;
  }

  get spinnerStyle(): {} {
    return {
      borderTopColor: this.hexToRGB(this.color, 0.15),
      borderRightColor: this.hexToRGB(this.color, 0.15),
      borderBottomColor: this.hexToRGB(this.color, 0.15),
      borderLeftColor: this.color,
      width: this.spinnerSize,
      height: this.spinnerSize,
      borderWidth: this.spinnerDepth,
      animationName: this.spinnerClockwise,
      animationDuration: this.spinnerSpeed,
    };
  }

  hexToRGB(hex: string, alpha: number): string {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    return `rgb${alpha ? "a" : ""}(${r},${g},${b}${alpha ? "," + alpha : ""})`;
  }
}
