import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class IsSynced extends VuexModule {
  isSyncUser: boolean = true;

  @Mutation
  setIsSynced(isSyncUser: boolean) {
    this.isSyncUser = isSyncUser;
  }

  @Action({ commit: "setIsSynced" })
  SyncUser() {
    return true;
  }

  @Action({ commit: "setIsSynced" })
  UnSyncUser() {
    return false;
  }

  get isSynced() {
    return this.isSyncUser;
  }
}
