
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "CustomPassword",
})
export default class CustomPassword extends Vue {
  passwordVisible = false;
  password = "";
  passwordValidation = {
    visible: false,
    hasError: false,
    length: false,
    case: false,
    number: false,
    special: false,
  };

  iconSrc(icon: string): string | null {
    try {
      return this.$jfImages.get(icon);
    } catch (e) {
      this.$log.error("Failed to retrieve icon : " + icon);
    }
    return null;
  }

  onPasswordChange($event: any) {
    const text = $event.target.value;
    this.password = text;

    if (!text) {
      this.passwordValidation.visible = false;
      this.passwordValidation.hasError = false;
      this.$emit("onPasswordChange", "");
      return;
    }
    const specials = ["!", "@", "#", "$", "%", "^", "&", "+", "=", "~", "*"];
    this.passwordValidation.visible = true;
    this.passwordValidation.length = text.length >= 8 && text.length <= 32;
    this.passwordValidation.case = text !== text.toUpperCase() && text !== text.toLowerCase();
    this.passwordValidation.number = /\d/.test(text);
    this.passwordValidation.special = specials.some(e => text.includes(e));
    const hasError =
      !this.passwordValidation.length ||
      !this.passwordValidation.case ||
      !this.passwordValidation.number ||
      !this.passwordValidation.special;
    this.passwordValidation.hasError = hasError;
    this.$emit("onPasswordChange", hasError ? "" : text);
  }

  get passwordCss() {
    return this.passwordValidation.hasError ? "error" : "";
  }

  get errorsIndicatorCss() {
    let css = ["errors-indicator"];
    if (this.passwordValidation.visible) {
      css.push("visible");
    }
    return css;
  }
}
