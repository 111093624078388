
import PulseSpinner from "@/components/spinners/PulseSpinner.vue";
import { FloatingNotificationItemProps } from "@/types/localtypes";
import { SpinnerProperties } from "@/types/spinners";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "FloatingNotificationItem",
  components: { PulseSpinner },
})
export default class FloatingNotificationItem extends Vue {
  @Inject() readonly floatingNotificationsBus!: Vue;
  @Prop() private tagName!: FloatingNotificationItemProps["tagName"];
  @Prop({ default: "Unknown" })
  private content!: FloatingNotificationItemProps["content"];
  @Prop({ default: "icon-mjf-jfrog" })
  private iconClass!: FloatingNotificationItemProps["iconClass"];
  @Prop({ default: "white" })
  private color!: FloatingNotificationItemProps["color"];
  @Prop({ default: "white" })
  private iconColor!: FloatingNotificationItemProps["iconColor"];
  @Prop({ default: "#40be46" })
  private backgroundColor!: FloatingNotificationItemProps["backgroundColor"];
  @Prop({ default: false })
  private loading!: FloatingNotificationItemProps["loading"];
  @Prop({ default: () => [] })
  private triggerIds!: FloatingNotificationItemProps["triggerIds"];

  isHover: boolean = false;
  idleLeftOffset: number = 55;
  wrapperFitWidth!: number;
  spinnerConfig: SpinnerProperties = {
    size: "xxxs",
    color: this.iconColor,
  };

  get wrapperRefName() {
    return this.tagName + "-wrapper-ref";
  }

  get leftOffset() {
    return this.isHover && this.wrapperFitWidth ? this.wrapperFitWidth : this.idleLeftOffset;
  }

  get containerStyles() {
    return {
      backgroundColor: this.backgroundColor,
    };
  }

  get wrapperStyles() {
    const left = `-${this.leftOffset}px`;
    return {
      color: this.color,
      backgroundColor: this.backgroundColor,
      left,
    };
  }

  get wrapperClasses() {
    const classes = [];
    if (this.wholeItemIsClickable) {
      classes.push("clickable");
    }
    return classes;
  }

  get iconClasses() {
    const classes = [this.iconClass];
    return classes;
  }

  get iconStyles() {
    return {
      color: this.iconColor,
    };
  }

  defineWrapperFitWidth() {
    // the element will be completely rendered on next tick and the clientWidth will then contain padding/margins values
    setTimeout(() => {
      const wrapperEl = this.$refs[this.wrapperRefName] as HTMLDivElement;
      this.wrapperFitWidth = wrapperEl.clientWidth;
    }, 0);
  }

  get onMobileOrTablet() {
    // return true;
    return this.$mq === "mobile" || this.$mq === "tablet";
  }

  onMouseOver() {
    if (this.onMobileOrTablet) {
      // on mobile/tablet the open/close state will be manually triggered
      return;
    }
    this.isHover = true;
  }

  onMouseLeave() {
    if (this.onMobileOrTablet) {
      // on mobile/tablet the open/close state will be manually triggered
      return;
    }
    this.isHover = false;
  }

  get wholeItemIsClickable() {
    // custom trigger are not defined > all the item should be clickable
    return !this.triggerIds || this.triggerIds.length === 0;
  }

  onWrapperClick() {
    if (this.onMobileOrTablet) {
      if (!this.isHover) {
        // user tap on a closed wrapper on mobile > should only open it
        this.isHover = true;
        this.floatingNotificationsBus.$emit("mobileElementOpened", this.tagName);
        return;
      } else if (this.isHover && !this.wholeItemIsClickable) {
        // user tap on a opened wrapper on mobile but the wrapper itself should not trigger the onclick action > should only close it
        this.isHover = false;
        return;
      }
    }

    if (this.wholeItemIsClickable) {
      this.onItemClick();
    }
  }

  onItemClick() {
    this.$emit("onItemClick", this.tagName);
  }

  addClickEvents() {
    // Listening on specific trigger click. Waiting next tick to be sure the element is present on the DOM
    setTimeout(() => {
      this.triggerIds &&
        this.triggerIds.forEach(triggerId => {
          const triggerEl = document.getElementById(triggerId);
          if (triggerEl) {
            triggerEl.addEventListener("click", () => this.onItemClick());
            triggerEl.classList.add("clickable");
          }
        });
    }, 0);
  }

  onMobileElementOpened(tagName: FloatingNotificationItemProps["tagName"]) {
    if (tagName !== this.tagName && this.isHover) {
      this.isHover = false;
    }
  }

  mounted() {
    this.defineWrapperFitWidth();
    this.addClickEvents();

    this.floatingNotificationsBus.$on("mobileElementOpened", this.onMobileElementOpened);
  }
}
