
import LoadingMask from "@/components/common/LoadingMask.vue";
import { LoadingMaskProps } from "@/types/loadingMask";
import { JFFormControl, JFTextField } from "jfrog-ui-vue-essentials";
import { isRestClientError, UserDetailsDto, UserDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "ModalUserInfo",
  $_veeValidate: { validator: "new" },
  components: {
    JFFormControl,
    JFTextField,
    LoadingMask,
  },
})
export default class UserInfo extends Vue {
  @Prop({ default: "" })
  private userName!: string;
  @Getter("user", { namespace: "user" })
  user!: UserDTO | undefined;
  @Watch("user")
  onUser(newValue: UserDTO, oldValue: UserDTO | undefined) {
    if (!oldValue) {
      this.updateUser(newValue);
    }
  }
  firstName = "";
  lastName = "";
  email = "";
  phone = "";
  company = "";
  isLoading = false;

  mounted() {
    this.$jfModal.childComponentIsSet();

    if (this.user) {
      this.updateUser(this.user);
    }
  }

  emailLabelIcon = {
    iconClass: "icon-art-notif-icon pointer reduce-opacity",
    tooltipText: {
      content: `To change your email address<br/>contact <a href="mailto:service@jfrog.com">service@jfrog.com</a>.`,
      html: true,
      trigger: "click",
      placement: "auto",
      classes: ["tooltip_light", "modal-tooltip"],
    },
  };

  updateUser(user: UserDTO) {
    this.firstName = this.$utils.capitalize(user.firstName) || "";
    this.lastName = this.$utils.capitalize(user.lastName) || "";
    this.company = this.$utils.capitalize(user.company) || "";
    this.email = user.email || "";
    this.phone = user.phone || "";
  }

  get userInjected() {
    return this.user;
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
    };
  }

  async updateUserInfo() {
    if (!(await this.$validator.validateAll())) {
      return;
    }
    try {
      this.isLoading = true;
      await this.$jfUsers.updatePersonalInfo({
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        phone: this.phone,
      } as UserDetailsDto);

      this.$jfNotification.success({ text: this.$jfMessages.profile_update_success });
      this.$jfUsers.reloadUser();
      this.dismiss();
    } catch (e) {
      this.$log.error(e);
      this.isLoading = false;
      let errorMessage = e.httpStatus === 500 ? this.$jfMessages.app_something_went_wrong : e.httpStatusText;
      if (isRestClientError(e) && e.httpBody) {
        errorMessage = this.$utils.capitalize(e.httpBody.message || (e.httpStatusText as string));
        errorMessage = this.$jfMessages.generateMailLink(errorMessage);
      }
      this.$jfNotification.error({
        title: "Error",
        text: errorMessage || e,
      });
    }
  }

  dismiss() {
    this.$jfModal.dismiss();
  }
}
