
import FloatingNotificationItem from "@/components/layout/notifications/floating/FloatingNotificationItem.vue";
import { FloatingNotificationTagName } from "@/services/floatingNotifications";
import { FloatingNotificationItemProps } from "@/types/localtypes";
import { ViewContext } from "@/types/services/app";
import { UserDTO } from "@jfrog-ba/myjfrog-common";
import { Component, Provide, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  name: "FloatingNotifications",
  components: { FloatingNotificationItem },
})
export default class FloatingNotifications extends Vue {
  @Provide() floatingNotificationsBus = new Vue();
  @Getter("user", { namespace: "user" })
  user!: UserDTO;
  @Getter("viewContext", { namespace: "application" })
  viewContext!: ViewContext;

  @Watch("viewContext")
  onViewContextChanged() {
    this.loadNotifications(0);
  }
  allNotifications: FloatingNotificationItemProps[] = [];
  get maxNotificationsToDisplay() {
    return 5;
  }

  get displayedNotifications() {
    return this.allNotifications.filter(n => n.displayed).slice(0, this.maxNotificationsToDisplay);
  }

  loadNotifications(delay: number) {
    setTimeout(() => {
      this.allNotifications = this.$jfFloatingNotifications.buildNotificationsProps(
        this.user.uiNotifications,
        this.viewContext,
      );
    }, delay);
  }

  setItemLoading(
    tagName: FloatingNotificationItemProps["tagName"],
    newValue: FloatingNotificationItemProps["loading"],
  ) {
    this.allNotifications = this.allNotifications.map(n => (n.tagName === tagName ? { ...n, loading: newValue } : n));
  }

  setItemDisplayed(
    tagName: FloatingNotificationItemProps["tagName"],
    newValue: FloatingNotificationItemProps["displayed"],
  ) {
    this.allNotifications = this.allNotifications.map(n => (n.tagName === tagName ? { ...n, displayed: newValue } : n));
  }

  async onItemClick(tagName: FloatingNotificationItemProps["tagName"]) {
    const canClose = await this.handleClickAction(tagName);
    if (canClose) {
      this.markItemAsReadAndClose(tagName);
    }
  }
  async markItemAsReadAndClose(tagName: FloatingNotificationItemProps["tagName"]) {
    this.setItemLoading(tagName, true);
    await this.$jfFloatingNotifications.markAsRead(tagName);
    this.setItemLoading(tagName, false);
    this.setItemDisplayed(tagName, false);
  }

  async handleClickAction(tagName: FloatingNotificationItemProps["tagName"]): Promise<boolean> {
    switch (tagName as FloatingNotificationTagName) {
      case "PIPELINES_TEASER":
        this.$router.push({ path: "add-pipelines" });
        return true;
      default:
        this.$log.warn(`Handling click on Unknown floating notification item : ${tagName}`);
        return true;
    }
  }
  mounted() {
    this.loadNotifications(700);
  }
}
