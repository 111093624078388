import { OtpAuthenticationData } from "@/types/localtypes";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
export default class Authentication extends VuexModule {
  _otpAuthenticationData: OtpAuthenticationData | null = null;

  @Mutation
  _setOtpAuthenticationData(otpAuthenticationData: OtpAuthenticationData) {
    this._otpAuthenticationData = otpAuthenticationData;
  }

  @Action({ commit: "_setOtpAuthenticationData" })
  setOtpAuthenticationData(otpAuthenticationData: OtpAuthenticationData) {
    return otpAuthenticationData;
  }

  @Action({ commit: "_setOtpAuthenticationData" })
  clearOtpAuthenticationData() {
    return null;
  }

  get otpAuthenticationData() {
    return this._otpAuthenticationData;
  }
}
