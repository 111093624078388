
import { Component, Provide, Vue } from "vue-property-decorator";
import { registrationsService } from "@/services/registrations";
import PageHeader from "@/components/landingPages/quickPurchaseNew/PageHeader.vue";
import { PageContent } from "@/types/localtypes/quickPurchase";
import { LoadingMaskProps } from "@/types/loadingMask";
import LoadingMask from "@/components/common/LoadingMask.vue";
import QuickPurchaseCard from "@/components/landingPages/quickPurchaseNew/QuickPurchaseCard.vue";
import QuickPurchaseFeatureGroup from "@/components/landingPages/quickPurchaseNew/QuickPurchaseFeatureGroup.vue";
import QuickPurchaseActionBar from "@/components/landingPages/quickPurchaseNew/QuickPurchaseActionBar.vue";
import { Getter } from "vuex-class";
import { QuickPurchaseData } from "@/types/localtypes";

declare var window: any;

@Component({
  name: "QuickPurchaseNewWrapper",
  components: { PageHeader, QuickPurchaseCard, QuickPurchaseFeatureGroup, QuickPurchaseActionBar, LoadingMask },
})
export default class QuickPurchaseNewWrapper extends Vue {
  @Provide() quickPurchaseBus = new Vue();
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  isLoading = true;
  pageContent!: PageContent;
  async created() {
    this.pageContent = await registrationsService.quickPurchasePageContent();
    this.isLoading = false;
    document.getElementById("app")?.removeAttribute("h-100");
    this.quickPurchaseBus.$on("shouldGoToPlatform", this.handleGoToPlatform);
  }
  get loadingMaskProps(): LoadingMaskProps {
    return { loading: this.isLoading };
  }

  handleGoToPlatform(platformUrl: string) {
    const domain = document.location.href.includes("https://landing.jfrog.info") ? "jfrog.info" : "jfrog.io";
    const url = platformUrl || `https://${this.quickPurchaseData.serverName}.${domain}/`;
    window.open(url);
  }
}
