
import { SpinnerSizes } from "@/types/spinners";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "PulseSpinner",
  components: {},
})
export default class PulseSpinner extends Vue {
  @Prop({ default: true })
  private show!: boolean;
  @Prop({ default: "#43a047" })
  private color!: string;
  @Prop({ default: "l" })
  private size!: keyof SpinnerSizes;
  @Prop({ default: 3 })
  private nbBalls!: number;
  @Prop({ default: 0.12 })
  private delay!: number;
  @Prop({ default: 5 })
  private margin!: number;
  @Prop({ default: 50 })
  private radius!: number;

  sizeUnits: string = "px";
  timeUnits: string = "s";
  spinnerSizes: SpinnerSizes = {
    xxxs: {
      size: 8,
    },
    xxs: {
      size: 12,
    },
    xs: {
      size: 25,
    },
    s: {
      size: 50,
    },
    m: {
      size: 65,
    },
    l: {
      size: 80,
    },
    xl: {
      size: 95,
    },
    huge: {
      size: 125,
    },
  };

  get spinnerSize(): string {
    return this.spinnerSizes[this.size].size + this.sizeUnits;
  }
  get wrapperStyle(): {} {
    return {
      height: this.spinnerSize,
    };
  }
  get spinnerStyle(): {} {
    return {
      backgroundColor: this.color,
      marginRight: this.margin + this.sizeUnits,
      borderRadius: this.radius + "%",
      width: this.spinnerSize,
      display: "inline-block",
      animationName: "v-pulseStretchDelay",
      animationDuration: "0.75s",
      animationIterationCount: "infinite",
      animationTimingFunction: "cubic-bezier(.2,.68,.18,1.08)",
      animationFillMode: "both",
    };
  }
  spinnerDelay(index: number): {} {
    return {
      animationDelay: this.delay * index + this.timeUnits,
    };
  }
}
