
import { parseJwt } from "@/util/jwt-parser";
import { GoogleSsoUser } from "@/types/localtypes/GoogleSsoUser";
import { Component, Vue } from "vue-property-decorator";
declare var window: any;
declare var GOOGLE_CLIENT_ID: any;
@Component({
  name: "GoogleLogin",
  components: {},
})
export default class GoogleLogin extends Vue {
  timeOut = undefined as undefined | ReturnType<typeof setTimeout>;

  mounted() {
    this.loadGoogleApi();
  }
  loadGoogleApi() {
    clearTimeout(this.timeOut);
    if (!window.google) {
      this.timeOut = setTimeout(this.loadGoogleApi, 50);
      return;
    }
    this.initGoogleApi();
  }

  initGoogleApi() {
    window.google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: this.onSignIn,
    });
    window.google.accounts.id.renderButton(
      document.getElementById("googleSsoButton"),
      { theme: "outline", size: "large", width: "700px" }, // customization attributes..
    );
  }

  onSignIn(googleUser: GoogleSsoUser) {
    const tokenParsed = parseJwt(googleUser.credential);
    googleUser.email = tokenParsed.email;
    this.$emit("onSignIn", googleUser);
  }
}
